// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.subapp-container {

    width: 1095px;
height: 583px;

border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.10);
    display: flex;
    flex: 1 1;
    gap: 20px; 
 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 575px) {
    .subapp-container {
        width: 355px;
        height: auto;
    }
    .anticon{
        z-index: 1;
    }
  }
  
@media(min-width: 768px) and (max-width: 1023px) {
    .subapp-container {
        width: 750px;
        height: auto;
    }
}
  `, "",{"version":3,"sources":["webpack://./src/components/UserDashboard/Routes/RouteLayout.css"],"names":[],"mappings":";;AAEA;;IAEI,aAAa;AACjB,aAAa;;AAEb,mBAAmB;AACnB,gBAAgB;AAChB,gDAAgD;IAC5C,aAAa;IACb,SAAO;IACP,SAAS;;IAET,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;EAClC;;EAEA;IACE;QACI,YAAY;QACZ,YAAY;IAChB;IACA;QACI,UAAU;IACd;EACF;;AAEF;IACI;QACI,YAAY;QACZ,YAAY;IAChB;AACJ","sourcesContent":["\n\n.subapp-container {\n\n    width: 1095px;\nheight: 583px;\n\nborder-radius: 20px;\nbackground: #FFF;\nbox-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.10);\n    display: flex;\n    flex: 1;\n    gap: 20px; \n \n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n\n  @media (max-width: 575px) {\n    .subapp-container {\n        width: 355px;\n        height: auto;\n    }\n    .anticon{\n        z-index: 1;\n    }\n  }\n  \n@media(min-width: 768px) and (max-width: 1023px) {\n    .subapp-container {\n        width: 750px;\n        height: auto;\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
