// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .SideMenuPagecontent{
display: flex;
flex: 1;
} */

.SideMenu{
    width: 10%;
}
.SideMenu .ant-menu{
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/DashBoard_BK/DashBoard_SideMenu/SideMenu.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;IACI,UAAU;AACd;AACA;IACI,uBAAuB;AAC3B","sourcesContent":["/* .SideMenuPagecontent{\ndisplay: flex;\nflex: 1;\n} */\n\n.SideMenu{\n    width: 10%;\n}\n.SideMenu .ant-menu{\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
