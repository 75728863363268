import React from 'react'
import LandingPage from './Main/LandingPage';
import MainNavBar from '../components/nav/MainNav/MainNavBar';
import AboutBk from './Main/AboutBk';
import SubscriptionBk from './Main/SubscriptionBk';
import FAQs from './Main/FAQs';
import Reviews from './Main/Reviews';
import Footer from '../components/nav/Footer/Footer';
import Last from './Main/Last'
import { useState, useEffect } from 'react';
import { FadeLoader } from 'react-spinners';


function Home() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Change the delay as needed
  }, []);
  
  return (
    <>
     
      
      {loading ? (
        <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <FadeLoader color={'red'} loading={loading} size={150} />
        </div>
      ) : (
        <> 
          <MainNavBar></MainNavBar>
          <LandingPage></LandingPage><br></br>
          <AboutBk></AboutBk>
          <Last></Last><br></br>
          <SubscriptionBk></SubscriptionBk><br></br>
          <Reviews></Reviews><br></br>
          <FAQs></FAQs><br></br>
          <Footer></Footer>
        </>
      )}
    </>
  )
}

export default Home;