import './Last.css';
import avatar from './book1.png';
import { motion } from 'framer-motion';
import { useEffect, useState, useRef } from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getBestSellingBooks } from '../../functions/bestSellers';
import { CloseCircleOutlined } from '@ant-design/icons'


function Last(props) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [width, setWidth] = useState(0);
    const slider_wrapper = useRef();
    const inner_carousel = useRef();
    const [modal, setModal] = useState(false);
    const [data, setData] = useState("");
    const [des, setDes] = useState("");
    const [temp, setTemp] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true);
    const [books, setBooks] = useState([]);
    const [bookPopUp, setBookPopUp] = useState([]);
    const [openpopup, setOpenpopup] = useState(false);
    const [selectedimg, setSelectedimg] = useState();

    const logoUrl = '/bookkulturelogo.png';

    const showDescription = (product, temp) => {
        console.log("Clicked");
        setButtonVisible(true);
        setTemp(true);
        setDes(product);
        if (!temp) {
            // const desData = product.slice(0, 200);

            setData(product);
        } else {
            setData(product);
            setButtonVisible(false);
        }
        setModal(true);
    };

    const openpopupfunction = (book) => {
        // console.log("trueopen")
        setOpenpopup(true);
        setBookPopUp(book);
        // console.log(bookPopUp)
        // console.log("book", book)
    }

    const selectmefunction = (bookImg) => {
        setSelectedimg(bookImg);
    }

    const selectmefunction2 = (bookImg) => {
        setSelectedimg(bookImg);
    }

    const hidepopfunction = () => {
        setSelectedimg();
        setOpenpopup(false);
    }

    useEffect(() => {
        bestSellingBooks();
    }, [])

    useEffect(() => {
        const calculateWidth = () => {
            if (slider_wrapper.current && inner_carousel.current) {
                const wrapperWidth = slider_wrapper.current.offsetWidth;
                const cardWidth = 300; // Set this to the width of your card elements
                const numItems = books.length; // Change 'books' to the appropriate variable name for your data
                const totalWidth = cardWidth * numItems; // Calculate the total width based on the number of items and their width
                setWidth(Math.max(0, totalWidth - wrapperWidth)); // Ensure that width is never negative
            }
        };

        calculateWidth();

        const handleResize = () => {
            calculateWidth();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [books]);

    useEffect(() => {
        const cachedData = localStorage.getItem('cachedProducts');

        if (cachedData) {
            setProducts(JSON.parse(cachedData));
            setLoading(false);
        } else {
            fetchData();
        }
    }, []);


    const fetchData = () => {
        fetch('https://swapi.dev/api/films')
            .then((response) => response.json())
            .then((data) => {
                const data1 = data.results;
                setLoading(false);
                localStorage.setItem('cachedProducts', JSON.stringify(data1));
            })
            .catch((err) => console.log(err));
    };

    const bestSellingBooks = () => {
        getBestSellingBooks(10)
            .then((res) => {
                setBooks(res.data);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <>
            {/* <Modal
                size='lg'
                isOpen={modal}
                toggle={() => setModal(!modal)}
                style={{
                    border: '50px',
                    backgroundColor: 'gray',
                    boxShadow: '15px solid ##00000040',
                    margin: 'auto',
                    backgroundImage: 'url("../../asset/BestSellerImages/Rectangle22.png")',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
                className={`custom-modal ${!temp ? 'className1' : 'className2'}`}
                backdropClassName="custom-modal-overlay"
            >
                <ModalHeader className='modalhead' toggle={() => setModal(!modal)}>
                    <h1>Description</h1>

                </ModalHeader>
                <ModalBody modalClassName={`CustomModal ${temp ? 'className1' : 'className2'}`}>
                    <div>
                        <div className='PopUP raw'>
                            <div className='PopImg'>
                                <img className="Popimage" src={avatar} alt="Logo" />
                            </div>
                            {data && (
                                <div className="Popdes">
                                    <h1>Harry Potter</h1>

                                    <p className='book-description'>{data}</p>
                                    {buttonVisible && <button className="showMore" onClick={() => showDescription(des, true)}>Show More</button>}
                                </div>

                            )}
                        </div>
                    </div>
                </ModalBody>
            </Modal> */}

            {openpopup && (

                <div className="mainbgdiv">
                    <CloseCircleOutlined
                        className="cancel"
                        style={{ fontSize: '60px', color: 'red' }}
                        onClick={hidepopfunction}
                    />

                    <div className='mainbgdiv_content'>
                        <div className='mainbgdiv_productdetails'>
                            <div className='mainbgdiv_productimg'>
                                <img className="mainimg" src={selectedimg || (bookPopUp.images[0] ? (bookPopUp.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + bookPopUp.images[0].public_id + '.jpg' : bookPopUp.images[0].url) : logoUrl)} alt="Product"></img>
                                {bookPopUp.images.length > 1 && (
                                    <div className='imglist'>
                                        <img src={bookPopUp.images[0] ? (bookPopUp.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + bookPopUp.images[0].public_id + '.jpg' : bookPopUp.images[0].url) : logoUrl} onClick={() => { selectmefunction(bookPopUp.images[0] ? bookPopUp.images[0].url : logoUrl) }} alt="Product"></img>
                                        <img src={bookPopUp.images[1] ?(bookPopUp.images[1].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + bookPopUp.images[1].public_id + '.jpg' : bookPopUp.images[1].url) : logoUrl} onClick={() => { selectmefunction2(bookPopUp.images[1] ? bookPopUp.images[1].url : logoUrl) }} alt="Product"></img>
                                    </div>
                                )}

                            </div>
                            <div className="mainbgdiv_productdes">
                                <h1>{bookPopUp.title}</h1>
                                <p>
                                    {bookPopUp.description}
                                </p>
                                {bookPopUp.quantity > 0 ? (
                                    <h2 style={{ color: "green" }}>Available: {bookPopUp.quantity}</h2>
                                ) : (
                                    <h2 style={{ color: "red" }}>Unavailable: {bookPopUp.quantity}</h2>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div >
                <h1 className='headings'>BestSellers</h1>

            </div>
            <div className='main_bestseller'>
                <div className='ShopProductCarousel_container_bestSeller1'>

                    <div className='wrapper1'>
                        {books.map((product) => (
                            <div className='card ' key={product._id}>
                                <div >

                                    <div className='card_img'>
                                        <img src={product.books.images[0] ? (product.books.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + product.books.images[0].public_id + '.jpg' : product.books.images[0].url) : avatar} alt='avatar' onClick={() => openpopupfunction(product.books)} style={{ cursor: 'pointer' }} />

                                    </div>
                                    <div className='card_header'>
                                        <div className='title'>{product.books.title}</div>
                                        <div className='sub_title'>{product.books.author.name}</div>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>

                </div>

            </div>

            {/* <div className='container122' >
                <div className='headings'>
                    <h1>BestSellers</h1>

                </div>


                <motion.div className='slider_wrapper' ref={slider_wrapper}>
                <motion.div
    className='iner_carousel'
    ref={inner_carousel}
    // whileTap={{ cursor: 'grabbing' }}
    drag='x'
    dragConstraints={{ right: 0, left: -width }}
>




                        {books.map((product) => (
                            <div className='card' key={product._id}>
                                <div>

                                    <div className='card_img'>

                                        <img src={product.books.images[0]? product.books.images[0].url : avatar} alt='avatar' onClick={() => showDescription(product.opening_crawl, false)} style={{ cursor: 'pointer' }} />

                                    </div>
                                    <div className='card_header'>
                                        <div className='title'>{product.books.title}</div>
                                        <div className='sub_title'>{product.books.author.name}</div>
                                    </div>

                                </div>
                            </div>
                        ))}
                 


                    </motion.div>
                </motion.div>
            </div> */}



        </>
    );
}

export default Last;
