import React, { useState } from 'react';
import DashBoardSidebar from '../DashBoard_SideMenu/DashBoardSidebar';
import DashBoardHeader from '../DashBoard_header/DashBoardHeader';
import "./Approutes.css"
import DashBooks from '../../../pages/DashBoard/Books/DashBooks'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { searchBooks } from '../../../functions/book';
import { useLocation } from 'react-router-dom';
import OrderBooks from '../../../pages/DashBoard/orderBooks/OrderBooks';

const DashboardLayout = () => {

  const [searchKeyword, setSearchKeyword] = useState("");
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate()
  const [searchedResponse, setSearchedResponse] = useState([]);
  const [userSearch, setUserSearch] = useState([]);
  const navigation = useLocation();

  console.log("cur nav", navigation);

  if (user) {
    console.log("role", user.role)
    if (user.role === "subscriber") {
      navigate('/library');
    }
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return function(...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleSearch = debounce(async (term) => {
    if(term.length > 1){

      try {
        if(navigation.pathname == '/books1' || navigation.pathname == '/admin/dashboard'){
          searchBooks(term)
          .then((res) => {
            setSearchedResponse(res.data)
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          })
        }else{
          //user Search api
          setUserSearch({name: 'nisarg', plan: '3 Months'});
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    
  }, 1000);

  const onHandleSearch = (keyword) => {
    console.log(keyword)
    if(navigation.pathname == '/books1' || navigation.pathname ==  '/admin/dashboard' ){
      setSearchKeyword(keyword);
      handleSearch(keyword);
    }else console.log("user", keyword);
  }

  return (
    <>
      <DashBoardHeader onHandleSearch={onHandleSearch}/>
      <div className="app-container">
        <DashBoardSidebar userSearch={userSearch} />
        <DashBooks searchKeyword={searchedResponse} keyLength={searchKeyword}/>
      </div>
    </>
  );
};

export default DashboardLayout;