import "./UserPlan.css"
import { Space, Typography } from "antd";
import book3 from "../../../asset/BestSellerImages/book1.png"
import { motion } from 'framer-motion'
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { currentSubscriber } from "../../../functions/subscriber";
import { orderHistory } from "../../../functions/order";
import { issuedBooks } from "../../../functions/book";
import { FadeLoader } from 'react-spinners';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';

function UserPlan() {
    const [orders, setOrders] = useState([]);
    const [issuedBooksContainer, setIssuedBooksContainer] = useState([]);
    const [subscriberDetails, setSubscriberDetails] = useState([]);
    const { user } = useSelector((state) => ({ ...state }));
    const [width, setWidth] = useState(0);
    const ShopProductCarousel_SliderWrapper = useRef();
    const inner_carousel = useRef();
    const [loading, setLoading] = useState(true);

    console.log("userPlan", orders);
    useEffect(() => {
        getSubscriberDetails();
    }, [user])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [])


    const getSubscriberDetails = () => {
        if (user) {
            if (user.role === 'subscriber') {
                console.log("userdetails", user)
                currentSubscriber(user.token)
                    .then((res) => {
                        setSubscriberDetails(res.data);
                        console.log(res.data)
                        getOrderHistory();
                        getIssuedBooks();
                    })
                    .catch((err) => {
                        toast.error(err.response.data.err)
                        console.log(err);
                    })
            }
        }
    }

    const getOrderHistory = () => {
        orderHistory(user.token)
            .then((res) => {
                setOrders(res.data)
                console.log(res.data)
            })
            .catch((err) => {
                toast.error(err.response.data.err)
                console.log(err);
            })
    }

    const getIssuedBooks = () => {

        issuedBooks(user.token)
            .then((res) => {
                console.log(res.data);
                setIssuedBooksContainer(res.data)
            })
            .catch((err) => {
                toast.error(err.response.data.err)
                console.log(err);
            })
    }

    const calculateDaysLeft = (startDate) => {
        const dateObject = new Date(startDate);
        const today = new Date();
        const result = today.getDate() - dateObject.getDate();
        if (subscriberDetails.plan === "6 Months") {
            const daysLeft = 120 - result;
            return daysLeft;
        } else if (subscriberDetails.plan === "9 Months") {
            const daysLeft = 180 - result;
            return daysLeft;
        } else if (subscriberDetails.plan === "12 Months") {
            const daysLeft = 360 - result;
            return daysLeft;
        }
    }



    return <>
        {loading ? (
            <>
                <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '400px' }}>
                    <FadeLoader color={'red'} loading={loading} size={150} />
                </div>
            </>
        ) : (
            <>
                {user && user.role === 'viewer' ? (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '300px' }}>
                            <Link to='/'>CILCK HERE TO SUBSCRIBE!!!</Link>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="detailLayout">
                            <Typography.Title level={2} className="ty">
                                Plan and Orders
                            </Typography.Title>

                            <div className="rowsdetails">
                                <Space direction="vertical">
                                    <h1 className="rowmain">Plan</h1>
                                    <h2 className="rowdetail">{subscriberDetails.plan}</h2>
                                </Space>
                                <Space direction="vertical">
                                    <h1 className="rowmain">Start Date</h1>
                                    <h2 className="rowdetail">{subscriberDetails.dateOfSubscription ? (subscriberDetails.dateOfSubscription.substring(0, 10)) : (<p>---</p>)}</h2>
                                </Space>
                                <Space direction="vertical">
                                    <h1 className="rowmain">End Date</h1>
                                    <h2 className="rowdetail">{subscriberDetails.dateOfExpiration ? (subscriberDetails.dateOfExpiration.substring(0, 10)) : (<p>---</p>)}</h2>
                                </Space>

                            </div>
                            <div className="rowsdetails">
                                <Space direction="vertical">
                                    <h1 className="rowmain">Orderd Books</h1>
                                    <h2 className="rowdetail">{subscriberDetails.bookOrdered}</h2>
                                </Space>
                                <Space direction="vertical">
                                    <h1 className="rowmain">Orders Left</h1>
                                    <h2 className="rowdetail">{subscriberDetails.bookLimit - subscriberDetails.bookOrdered}</h2>
                                </Space>
                            </div>

                            <h1 className="issuedbooks">Issued Books</h1>

                            <div className="issue_books_container" style={{display: 'flex'}}>
                                {issuedBooksContainer && issuedBooksContainer.booksIssued && issuedBooksContainer.booksIssued.length > 0 ? (
                                    issuedBooksContainer.booksIssued.map((book) => (
                                        <div key={book._id} className="bookcard" >
                                            <div className="bookcardimg" >
                                                <img src={book.images.length > 0 ? book.images[0].url : book3} alt="Book Cover" />
                                            </div>
                                            <div className="bookcardname">
                                                <h3>{book.title}</h3>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No issued books available.</p>
                                )}
                            </div>


                            {/* <div className='ShopProductCarousel_container userpb'>
                                <motion.div className='ShopProductCarousel_SliderWrapper' ref={ShopProductCarousel_SliderWrapper}>
                                    <motion.div className='ShopProductCarousel_inner_carousel' drag="y"
                                        dragConstraints={{ right: 0, left: -width }}>
                                        <div className='ProductCard proissue'>

                                            {issuedBooksContainer && issuedBooksContainer.booksIssued && issuedBooksContainer.booksIssued.length > 0 ? (
                                                issuedBooksContainer.booksIssued.map((book) => (
                                                    <div key={book._id} className="bookcard">
                                                        <div className="bookcardimg">
                                                            <img src={book.images.length > 0 ? book.images[0].url : book3} alt="Book Cover" />
                                                        </div>
                                                        <div className="bookcardname">
                                                            <h3>{book.title}</h3>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No issued books available.</p>
                                            )}
                                        </div>
                                    </motion.div>
                                </motion.div>
                            </div> */}

                            {/* <h1 className="issuedbooks">Orderd Books</h1>
                            <div className='ShopProductCarousel_container userpbd'>


                                <motion.div className='ShopProductCarousel_SliderWrapper'
                                    ref={ShopProductCarousel_SliderWrapper}
                                >
                                    <motion.div className='ShopProductCarousel_inner_carousel' drag="x"
                                        dragConstraints={{ right: 0, left: -width }}>
                                        <div className='ProductCard proissue'>

                                        
                                        {orders && orders.length > 0 ? orders.map((book) => (
                                            <>
                                            <div className="bookcard">
                                            <div key={book._id} className="bookcard">
                                                <div className="bookcardimg">
                                                    <img src={book.books[0].images.length > 0 ? book.books[0].images[0].url : book3} alt="Book Cover" />
                                                </div>
                                                <div className="bookcardname">
                                                    <h3>{book.books[0].title}</h3>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="userstatus">
                                                <h1>{orders.orderStatus}</h1>
                                            </div>
                                            </>
                                        )) : (
                                            <>
                                                <h2>No orders</h2>
                                            </>
                                        ) }  
                                           
                                        </div>
                                    </motion.div>
                                </motion.div>

                                {orders && (
                                    <div className="userstatus">
                                        <h1>{orders[0].orderStatus}</h1>
                                    </div>
                                )}
                                
                            </div> */}



                        </div>
                    </>
                )}

            </>
        )}


    </>
}

export default UserPlan








// {orders.map((order) => (
//     <div key={order._id} className="bookcard">
//     {/* <h3>Order Id: {orders._id}</h3> */}

//     {order.books.map((book) => (
//         <div key={book._id} className="bookcard">

//             <div className="bookcardimg">
//                 <img src={book.images.length > 0 ? book.images[0].url : book3} alt="Book Cover" />
//             </div>
//             <div className="bookcardname">
//                 <h3>{book.title}</h3>
//             </div>
//         </div>
//     ))}
//     <br></br>
//     <br></br>
//     </div>
// ))}