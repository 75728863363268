import axios from "axios";
import axiosInstance  from './services/interceptor';

export const createOrUpdateUser = async (authtoken) =>{
    return await axiosInstance.post(`${process.env.REACT_APP_API}/create-or-update-user`, 
      {}, {
      headers:{
        authtoken,
      }
    });
  };

export const currentUser = async (authtoken) =>{
    return await axiosInstance.post(`${process.env.REACT_APP_API}/current-user`, 
      {}, {
      headers:{
        authtoken,
      }
    });
  };

export const currentAdmin = async (authtoken) =>{
    return await axiosInstance.post(`${process.env.REACT_APP_API}/current-admin`, 
      {}, {
      headers:{
        authtoken,
      }
    });
  };

export const currentAdminDummy = async (authtoken) =>{
  return await axiosInstance.post(`${process.env.REACT_APP_API}/current-admin/dummy`, 
    {}, {
    headers:{
      authtoken,
    }
  });
};