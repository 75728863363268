import React from 'react'
import {useState, useEffect} from 'react'
import AdminNav from '../../../components/nav/AdminNav';
import  {toast} from 'react-toastify'
import { useSelector } from 'react-redux'
import {getAuthor, updateAuthor} from "../../../functions/author";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CategoryForm from '../../../components/forms/CategoryForm';
  
const AuthorUpdate = () => {
  
    const {user} = useSelector((state) => ({...state}));
  
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    //   const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        loadCategory();
    }, [])
  
    const loadCategory = () => {
      getAuthor(params.slug).then((c) => setName(c.data.name));  
    }
  
      const handleSubmit = (e) => {
          e.preventDefault();
          setLoading(true);
          updateAuthor(params.slug, {name}, user.token)
          .then((res) => {
              setLoading(false);
              setName("");
              toast.success(`"${res.data.name}"is updated`);
              navigate('/admin/author');
            //   loadCategories();
          })
          .catch((err) =>{
              console.log(err);
              setLoading(false);
              if(err.response.status === 400) toast.error(err.response.data);
          });
  
      };

    return (
      <>
          <AdminNav/>
          {loading ? (
              <h1>Loading</h1>
          ) : (
              <h1>UpdateAuthor Page</h1>
          )}

          <CategoryForm
            handleSubmit={handleSubmit}
            name={name}
            setName={setName}
          />


  
          
      </>
    )
}
export default AuthorUpdate;
