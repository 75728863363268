import './ShoppingCart.css'
import pro1 from '../../asset/ShopImages/ShopProduct1.png'
import { DeleteOutlined } from '@ant-design/icons'
import MainNavBar from '../../components/nav/MainNav/MainNavBar'
import { useState } from 'react'
import AuthFooter from '../../components/nav/AuthFooter2/AuthFooter2'
import { getCartList, removeBookCart } from '../../functions/cart'
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { createOrder } from '../../functions/order'
import { toast } from 'react-toastify'
import { FadeLoader } from 'react-spinners';
import { currentSubscriber } from '../../functions/subscriber'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'


function ShoppingCart() {
    const [isClicked, setIsClicked] = useState(false);
    const [isClicked2, setIsClicked2] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedBooks, setSelectedBooks] = useState([]);
    const [address, setAddress] = useState({});
    const [access, setAccess] = useState(true);

    const { user } = useSelector((state) => ({ ...state }));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const totalBooksInCart = cartItems ? cartItems.length : 0;

    useEffect(() => {
        getCurrentSubscriber();
        // loadCart();
    }, [user]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }, []);

    const getCurrentSubscriber = () => {
        if (user) {
            currentSubscriber(user.token)
                .then((res) => {
                    setAddress(res.data.address);
                    loadCart();
                })
                .catch((err) => {
                    console.log(err.response.data.err);
                    if (err.response.data.err === "Subscription expired. Access denied.") {
                        setAccess(false);
                    }
                })
        }
    }

    const loadCart = () => {
        if (user) {
            getCartList(user.token)
                .then((res) => {
                    const cartItemContainer = res.data;
                    setCartItems(cartItemContainer)
                    localStorage.setItem('cartItems', JSON.stringify(res.data.length));
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error(err.response.data.err)
                    toast.error("Error Occured!!!")
                })
        }
    }

    const handleRemove = (id) => {
        removeBookCart(id, user.token)
            .then((res) => {
                const updatedCartItems = cartItems.filter(item => item._id !== id);
                setCartItems(updatedCartItems);
                localStorage.setItem('cartItems', JSON.stringify(updatedCartItems.length));
                localStorage.setItem('cartUpdated', true);
                // dispatch({
                //     type: "REMOVE_FROM_CART",
                //     payload: id,
                // });
            })
            .catch((err) => {
                // toast.error(err.response.data.err)
                console.log(err);
            })
    }


    const handleSelectBook = (bookId) => {
        setSelectedBooks((prevSelectedBooks) => {
            if (prevSelectedBooks.includes(bookId)) {
                console.log("Filter wala chala, remove kiya");
                return prevSelectedBooks.filter((id) => id !== bookId);
            } else {
                console.log('added', bookId);
                return [...prevSelectedBooks, bookId];
            }
        });
    };

    const handleOrder = () => {
        if ((selectedBooks.length > 6) || (selectedBooks.length < 1)) {
            toast.error("PLEASE SELECT LESS THAN 6 BOOKS OR ATLEAST 1");
        } else {
            console.log(selectedBooks)
            createOrder({ selectedBooks }, user.token)
                .then((res) => {
                    navigate('/orderHistory');
                    toast.success("ORDER PLACED SUCCESSFULLY");
                })
                .catch((err) => {
                    toast.error(err.response.data)
                    console.log("Error while creating Order", err);
                    if(err.response.data === 'Some books are unavailable') window.location.reload();
                })
        }
    };

    const handleClick = () => {
        setIsClicked(!isClicked);
    };


    const handleClick2 = () => {
        setIsClicked2(!isClicked2);
    };


    const handeldelete = () => {
        setIsDelete(true)
    }

    return <>

        {loading ? (
            <>
                <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <FadeLoader color={'red'} loading={loading} size={150} />
                </div>
            </>
        ) : (
            <>
                <MainNavBar hideOptions={true}></MainNavBar>


                <div className='Cartmain'>

                    <div className='Cartmain_content'>


                        <div className='Cartmain_content_list'>
                            <div className='Cartmain_count'>
                                <h1>SHOPPING CART</h1>

                                {access ? (
                                    <h2>{(totalBooksInCart)} books</h2>
                                ) : (
                                    <h2 style={{ color: "red" }}>Please Renew your subscription to get your books</h2>
                                )}

                            </div>

                            {cartItems.map((item, index) => (
                                <>

                                    <div key={index} className='Cartmain_content_list_items'>
                                        <h1 className="horizontal-line" />
                                        <div className='Cartmain_items'>
                                            <div className='Cartmain_proimg'>
                                                <img src={item.images[0] ? (item.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + item.images[0].public_id + '.jpg' : item.images[0].url)  : pro1} />
                                            </div>
                                            <div className='Cartmain_item_title'>
                                                <span>Title: {item.title}</span><br />
                                                <span>Author: {item.author.name}</span><br />
                                                {item.quantity > 0 ? (
                                                    <p className="available" style={{ color: "green" }}>Available</p>
                                                ) : (
                                                    <p className="available" style={{ color: "red" }}>Unavailable</p>
                                                )}
                                            </div>
                                            <div className='Cartmain_item_icons'>
                                                <input
                                                    className='inputcheckbox'
                                                    type="checkbox"
                                                    checked={selectedBooks.includes(item._id)} // Check if the book is in the selectedBooks array
                                                    onChange={() => handleSelectBook(item._id)} // Handle the checkbox change
                                                />
                                                <DeleteOutlined onClick={() => { handleRemove(item._id) }} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}


                        </div>

                        <div className="vertical-line"></div>

                        {access && (
                            <div className='Cartmain_content_total'>

                                <div className='cartsubtotal'>

                                    <h1>Hello </h1>

                                    <h2>{user ? user.name : ''}</h2>

                                </div>
                                <h2 className='add_block'>Your Delivery Address is :</h2>
                                <h3>{address.house}, {address.landmark}, {address.area}, {address.city}, {address.pincode}</h3>
                                <button onClick={handleOrder}>Checkout</button>

                            </div>
                        )}

                    </div>


                </div>  <br></br>
                <AuthFooter></AuthFooter>
            </>
        )}


    </>

}

export default ShoppingCart;