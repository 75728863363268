import axios from "axios";
import axiosInstance from './services/interceptor';

export const currentSubscriber = async (authtoken) =>
    await axiosInstance.get(`${process.env.REACT_APP_API}/current-subscriber`, {
        headers: {
            authtoken,
        },
    });

export const createSubscriber = async (subscriber, response, authtoken) =>
    await axiosInstance.post(`${process.env.REACT_APP_API}/payment-create-subscriber`,
        { subscriber, response }, {
        headers: {
            authtoken,
        },
    });

export const downloadSubscribers = async(authtoken)=>
    await axios.get(`${process.env.REACT_APP_API}/admin/download-subscriber`,{
        responseType: 'blob',
        headers: {
            authtoken,
        },
    });

export const downloadmonthPiyush = async(count, authtoken)=>
    await axios.get(`${process.env.REACT_APP_API}/admin/piyush-month-subscriberr/${count}`,{
        responseType: 'blob',
        headers: {
            authtoken,
        },
    });

export const downloadmonthAdmin = async(count, authtoken)=>
    await axios.get(`${process.env.REACT_APP_API}/admin/admin-month-subscriber/${count}`,{
        responseType: 'blob',
        headers: {
            authtoken,
        },
    });

export const downloadtotalMonth = async(count, authtoken)=>
    await axios.get(`${process.env.REACT_APP_API}/admin/total-month-subscriber/${count}`,{
        responseType: 'blob',
        headers: {
            authtoken,
        },
    });

export const getAllSubscribers = async (count, authtoken) =>
    await axiosInstance.get(`${process.env.REACT_APP_API}/all-subscriber/${count}`, {
        headers: {
            authtoken,
        },
    });

export const adminCreateSubscriber = async (subscriber, authtoken) =>
    await axiosInstance.post(`${process.env.REACT_APP_API}/admin-create-subscriber`,
        { subscriber }, {
        headers: {
            authtoken,
        },
    });

export const searchSubscribers = async (searchKey, authtoken) =>
    await axios.get(`${process.env.REACT_APP_API}/search-subscriber/${searchKey}`,
        {
            headers: {
                authtoken,
            },
        }
    );


export const editAdminSubscriber = async (id, data, authtoken) =>
    await axiosInstance.post(`${process.env.REACT_APP_API}/edit-subscriber-admin/${id}`, data, 
        {
            headers : {
                authtoken
            }
        }
    );

export const adminGetSubscriber = async (id, authtoken) =>
    await axiosInstance.get(`${process.env.REACT_APP_API}/admin-get-subscriber/${id}`,
        {
            headers: {
                authtoken,
            },
        }
    );