// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.app-container {
    display: flex;
    flex: 1 1;
    gap: 20px; /* Adjust the spacing as needed */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/DashBoard_BK/Approutes/Approutes.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,SAAO;IACP,SAAS,EAAE,iCAAiC;EAC9C","sourcesContent":["\n.app-container {\n    display: flex;\n    flex: 1;\n    gap: 20px; /* Adjust the spacing as needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
