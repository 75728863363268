import React, { useEffect } from "react";
import { useState } from "react";
import { auth, googleAuthProvider } from "../../firebase"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import './Login.css'
import { createOrUpdateUser } from "../../functions/auth"
import { toast } from "react-toastify";
import AuthFooter from "../../components/nav/AuthFooter2/AuthFooter2";
import MainNavBar from "../../components/nav/MainNav/MainNavBar";
import icons8 from "../../asset/icons8-google.svg";

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  let dispatch = useDispatch();
  let navigate = useNavigate();

  const roleBasedRedirect = (res) => {
    if (res.data.role === "admin") {
      navigate('/admin/dashboard');
    } else {
      navigate('/library')
    }
  }
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    console.log("logged out", user);
    if (user && user.token) {
      navigate('/');
    }
  }, [user, navigate]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await auth.signInWithEmailAndPassword(email, password);
      const { user } = result;
      const idTokenResult = await user.getIdTokenResult();

      console.log("createOrUpdateUser");
      createOrUpdateUser(idTokenResult.token)
        .then((res) => {
          console.log("Login Dispatch---");
          dispatch({
            type: "LOGGED_IN_USER",
            payload: {
              name: res.data.name,
              email: res.data.email,
              token: idTokenResult.token,
              role: res.data.role,
              _id: res.data._id
            },
          });
          roleBasedRedirect(res);
        }).catch(err => console.log(err));
    } catch (error) {
      // toast.error(error.response.data.err)
      // console.log(error);
      // toast.error(error.message);
    }
  };

  const loginWithGoogle = () => {
    auth.signInWithPopup(googleAuthProvider)
      .then(async (result) => {
        const { user } = result;
        const idTokenResult = await user.getIdTokenResult();

        createOrUpdateUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id
              },
            });
            roleBasedRedirect(res);
          }).catch(err => console.log(err));
        navigate('/');
      }).catch((err) => {
        console.log(err);
        toast.error(err.message)
      });
  };

  return (
    <>
      <MainNavBar hideOptions={true}></MainNavBar>

      <div className="container">

        <div className="box_main">

          <h1 className="heading">Login</h1>
          <form onSubmit={handleSubmit}>
            {/* <input 
                className="emailinput"
                type="email"
                value={email}
                placeholder=" Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <br />
              <input
                className="password"
                type="password"
                value={password}
                placeholder=" Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <br />
              <Link className="forgotPassword" to="/forgot/password">Forget Password</Link>
              
              <button className="submitinput" type="submit">Sign In</button> */}


            <button className="loginWithGoogle" onClick={loginWithGoogle}>
            <img className="google-icon" src={icons8} />

            SignIn WITH GOOGLE
            </button>

            <p className="para">--------------------Or--------------------</p>

            <button className="loginWithGoogle" onClick={loginWithGoogle}>
            <img className="google-icon" src={icons8} />
            SignUp WITH GOOGLE
            </button>

            {/* <h1 className="notyetregistered">Haven’t registered yet? <Link className="signup" to="/register">Sign Up</Link> </h1> */}

          </form>
        </div>
      </div>

      <AuthFooter></AuthFooter>
    </>
  );
};

export default Login;




