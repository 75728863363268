// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppHeader{
   
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    border-radius: 1px solid;
    padding: 10px 40px 4px 12px;
    /* margin: 0px 50px 0 50px; */
}
.AppHeader_logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AppHeader_heading h1{
    color: rgba(0, 0, 0, 0.70);
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 104.1%; /* 20.82px */
}

.dash{
    color: rgba(0, 0, 0, 0.80);
font-family: Montserrat;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 104.1%; /* 33.312px */
}`, "",{"version":3,"sources":["webpack://./src/components/DashBoard_BK/DashBoard_header/DashBoard_header.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,wBAAwB;IACxB,2BAA2B;IAC3B,6BAA6B;AACjC;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B,uBAAuB;AACvB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB,EAAE,YAAY;AACjC;;AAEA;IACI,0BAA0B;AAC9B,uBAAuB;AACvB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB,EAAE,aAAa;AAClC","sourcesContent":[".AppHeader{\n   \n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 4px;\n    border-radius: 1px solid;\n    padding: 10px 40px 4px 12px;\n    /* margin: 0px 50px 0 50px; */\n}\n.AppHeader_logo{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.AppHeader_heading h1{\n    color: rgba(0, 0, 0, 0.70);\nfont-family: Montserrat;\nfont-size: 20px;\nfont-style: normal;\nfont-weight: 600;\nline-height: 104.1%; /* 20.82px */\n}\n\n.dash{\n    color: rgba(0, 0, 0, 0.80);\nfont-family: Montserrat;\nfont-size: 32px;\nfont-style: normal;\nfont-weight: 600;\nline-height: 104.1%; /* 33.312px */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
