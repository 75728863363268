import "./Terms.css"
import MainNavBar from "../../components/nav/MainNav/MainNavBar"
function TermsCondition() {

    return <>
        <MainNavBar hideOptions={true}></MainNavBar>
        <div className="mainrefunddiv">
            <h1 className="mainrefunddiv_h1">Terms and conditions</h1>
            <div className="points">
                <div className="point1">
                    <h1>Acceptance of Terms and Conditions</h1>
                    <p>Welcome to BOOK KULTURE, the premier destination for assessing a vast
                        online subscription-based book rental platform. BOOK KULTURE is a children’s
                        library service that provides a series programs devoted to the physical, emotional
                        and social development of children.
                        By accessing and using our services, you agree to comply with the following
                        Terms of Use. Please read these terms carefully before using our website and
                        services. If you do not agree to these terms, please do not use BOOK KULTURE.
                        By accessing or using the website or mobile app of ‘BOOK KULTURE’ (the
                        "Service"), you agree to be bound by these Terms of Use (the "Terms"). If you do
                        not agree to these Terms, you may not access or use the Service.
                    </p>
                </div>

                <div className="point3">
                    <h1> Account creation</h1>
                    <p>To rent books from BOOK KULTURE, you must create
                        an account. To create an account, you must provide a valid email address
                        and create a password. You need to fill the basic personal details such as
                        Name, Date of birth, Address, Contact information, Standard of the ward,
                        Date of birth of the ward, School Name and reference if any. You are
                        responsible for maintaining the confidentiality of your account and
                        password, and for restricting access to your computer. You agree to accept
                        responsibility for all activities that occur under your account or password.
                        You are responsible for maintaining the confidentiality of your account
                        credentials and for all activities that occur under your account. The account
                        shall be operated by the user or under the supervision, monitoring or
                        overview of the USER. The USER shall operate the website on behalf of
                        ward with respect to selection of books and its review and be solely
                        responsible for all the activities undertaken under the USER
                        REGISTERED ACCOUNT. You agree to provide accurate and up-to-date
                        information during the registration process.
                    </p>
                </div>


                <div className="point2">
                    <h1>User Eligibility</h1>
                    <p>You must be at least 18 years old or the legal age of
                        majority to use BOOK KULTURE. By using our services, you represent
                        and warrant that you meet these eligibility requirements. The user shall
                        create an account on behalf of family.
                    </p>
                </div>
                <div className="point3">
                    <h1>Subscription plans</h1>
                    <p>You may subscribe to the Service on a 3 monthly plan
                        (Bronze), 6 monthly plan (Silver) or annual basis (Platinum). All threeterm subscription plan shall hold a book limit viz., Bronze (40 book limit),
                        Silver (80 Book Limit) and Platinum (160 Book Limit). You will be
                        charged the subscription fee specified on the website at the beginning of
                        each subscription period. Subscription plans are inclusive of Library fees,
                        Registration fees and Deposit (Non-Refundable). Fees towards
                        subscription are non-refundable. You may cancel your subscription at any time, but you will not be refunded
                        for any unused portion of your subscription fee. To cancel your
                        subscription, please contact BOOK KULTURE customer support. BOOK
                        KULTURE offers subscription plans that grant access to a collection of
                        books and delivers the selected books as per subscription plan.
                        Subscription fees, billing cycles, and available plans are outlined on the
                        BOOK KULTURE website. You agree to abide by the terms and conditions
                        of your selected subscription plan. Subscription plans strictly belong to one
                        User family and it is non-sharable and the books shall be delivered to the
                        respective user family address only. Subscription plans are nontransferable, the user cannot transfer the subscription plan to any person
                        including relatives. Subscription plan shall end on expiry of plan term or
                        exhaustion of book limit whichever is earlier in reference to respective plans. We may also change Subscription fee plans in future. Changes to
                        that Policy are effective only after we provide you with at least One month
                        notice prior by posting the changes on the library site.
                    </p>
                </div>

                <div className="point3">
                    <h1>Payment terms</h1>
                    <p>You agree to pay all fees associated with your use of
                        BOOK KULTURE as outlined in our pricing and payment terms. Failure
                        to make payments may result in the suspension or termination of your
                        account. You authorize BOOK KULTURE to fix your payment method for
                        subscription fees and any applicable taxes. Payment information must be
                        accurate and up-to-date. You are responsible for any fees associated with
                        declined or failed payments. BOOK KULTURE may use third-party
                        payment processors to handle payments, and your use of such processors
                        is subject to their terms and conditions. Payment will be accepted through
                        Cash, UPI and Net banking in favor of ‘BOOK KULTURE’.
                    </p>
                </div>
                <div className="point3">
                    <h1>Delivery terms</h1>
                    <p>Delivery will be available for all plans. Delivery will be
                        done twice a month and maximum six books will be delivered at one time.
                        After delivery request, the books will be delivered within 2 days. A person
                        can hold only 6 books at one time, if he returns 3 books at one time, he can
                        only order 3 books next time. BOOK KULTURE provides access to a
                        variety of book titles. You may use these books for personal reading
                        purposes only. You may not reproduce, distribute, or display any content
                        from BOOK KULTURE without prior written permission.</p>
                </div>

                <div className="point3">
                    <h1>Book condition</h1>
                    <p> Books rented from BOOK KULTURE may be new or
                        used. You are responsible for maintaining the book in good condition while
                        it is in your possession. No writing, marking on the books. If the book is
                        damaged or lost, you will be charged the Maximum Retail Price of the
                        book. In case of Book Lost you need to pay the MRP which is mentioned
                        on the description of the particular book. One should not write upon,
                        damage or mark on library books. We will ensure that all book which we
                        will send to you are in good condition prior to Dispatch. The member should satisfy himself or herself as to whether the library book which are
                        lent to them is in sound condition. And if not, they should immediately
                        bring the matter to the knowledge of the library Logistic staff before he
                        leaves from your place. Failure to check and/or report the same will be
                        constituted as your having received a book in good condition. You will
                        liable to be held responsible for paying such compensation as calculated
                        by the BOOK KULTURE library.
                    </p>
                </div>

                <div className="point3">
                    <h1>Return policy</h1>
                    <p>Once the subscription ends, the books held by the user shall
                        be returned on the expiry of the subscription promptly and 2 days of grace
                        shall be allowed. On failure to return the books held after the grace days, 1
                        Month library fees shall be charged as fine or else legal action will be
                        undertaken or both. User can keep your books max for 30 days as books
                        are needed for further circulation. No late charges but request to return
                        books</p>
                </div>

                <div className="point3">
                    <h1> Links to Third Party Sites</h1>
                    <p>Any reference from the Web site and server
                        to any entity, product, service or information does not constitute an
                        endorsement or recommendation by the website or any of its employees.
                        Links to external Web sites are in the hope that users will find reference
                        material on related subjects. Website does not control and is not responsible
                        for the content included in the linked or referred sites including without
                        limitation any subsequent links contained within a linked site, or any
                        changes or updates to a linked site.
                    </p>
                </div>

                <div className="point3">
                    <h1>Privacy policy</h1>
                    <p>BOOK KULTURE collects and uses personal information
                        as described in our Privacy Policy. By using our services, you consent to
                        the collection and use of your personal information in accordance with our
                        Privacy Policy.
                    </p>
                </div>

                <div className="point3">
                    <h1>Intellectual property</h1>
                    <p>All content on the BOOK KULTURE website,
                        including but not limited to text, graphics, logos, images, and software, is
                        the property of BOOK KULTURE or its licensors and is protected by
                        copyright and other intellectual property laws. You agree not to copy,
                        modify, distribute, transmit, display, perform, reproduce, publish, license,
                        or create derivative works from any content on the Service without the
                        express written permission of BOOK KULTURE.

                    </p>
                </div>

                <div className="point3">
                    <h1>Disclaimer of Warranties</h1>
                    <p>The Service is provided "as is" and without
                        any warranties of any kind, express or implied. BOOK KULTURE
                        disclaims all warranties, express or implied, including but not limited to
                        the implied warranties of merchantability, fitness for a particular purpose,
                        and non-infringement.
                    </p>
                </div>

                <div className="point3">
                    <h1>Limitation of Liability</h1>
                    <p>To the fullest extent permitted by law, BOOK
                        KULTURE shall not be liable for any direct, indirect, incidental, special,
                        consequential, or exemplary damages, including but not limited to
                        damages for loss of profits, goodwill, use, data, or other intangible losses,
                        arising out of or in connection with your use of the Service, even if BOOK
                        KULTURE has been advised of the possibility of such damages.
                    </p>
                </div>

                <div className="point3">
                    <h1>User Conduct</h1>
                    <p>You agree not to engage in any activities on our platform
                        that are unlawful, harmful, or infringe on the rights of others. This includes,
                        but is not limited to, harassment, impersonation, spamming, or any form of
                        illegal content. If misusing library we can terminate your subscription. If
                        books are not available at particular time, please don’t do ruckus and be
                        cooperative.
                    </p>
                </div>

                <div className="point3">
                    <h1>Governing Law</h1>
                    <p>- These Terms shall be governed by and construed in
                        accordance with the laws of India and state laws of Maharashtra, without
                        regard to its conflict of law provisions. The jurisdiction for any dispute
                        shall be redressed in the courts of Mumbai jurisdiction.

                    </p>
                </div>

                <div className="point3">
                    <h1>Entire Agreement-</h1>
                    <p> These Terms constitute the entire agreement between
                        you and BOOK KULTURE with respect to your use of the Service and
                        supersede all prior or contemporaneous communications and agreements,
                        whether oral or written.
                    </p>
                </div>

                <div className="point3">
                    <h1>Severability</h1>
                    <p>If any provision of these Terms is held to be invalid or
                        unenforceable, such provision shall be struck from these Terms and the
                        remaining provisions shall remain in full force and effect.

                    </p>
                </div>

                <div className="point3">
                    <h1>Waiver</h1>
                    <p>No waiver of any provision of these Terms shall be effective
                        unless in writing and signed by both parties.
                    </p>
                </div>

                <div className="point3">
                    <h1>Termination</h1>
                    <p>BOOK KULTURE reserves the right to terminate or
                        suspend your account at its discretion, without notice, for any violation of
                        these Terms of Use.

                    </p>
                </div>


                <div className="point3">
                    <h1>Changes to the Terms</h1>
                    <p>BOOK KULTURE may change these Terms at any
                        time by posting the revised Terms on the website. Your continued use of
                        the Service after the posting of any revised Terms constitutes your
                        acceptance of such revised Terms.


                    </p>
                </div>


                <div className="point3">
                    <h1>Contact Information</h1>
                    <p>If you have any questions or concerns about these
                        Terms of Use, please contact us at [info@bookkulture.com].

                    </p>
                </div>

            </div>
        </div>
    </>
}

export default TermsCondition;