// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.link{
    
    gap: 3rem;
    color: #000;
    text-align: center;
    font-size: 30px;
    font-family: Happy Lucky;
    font-weight: 500;
    
}
.navLib{
    gap: 3rem;
    color: #000;
    text-align: center;
    font-size: 30px;
    font-family: Happy Lucky;
    font-weight: 500;
    text-decoration: none;
}

.bk_logo{
    width: 85px;
    height: 84px;
}
.navbar-collapse{
  
   z-index: 100;

}
.navbar-nav{
    margin-left: 300px;
}

.navbar-hidden-options .navbar-nav {
  
    margin-left: 465px;
  }

 .active-link{
    margin-top: 10px;
    gap: 3rem;
    color: var(--bs-nav-link-color);
    text-align: center;
    font-size: 30px;
    font-family: Happy Lucky;

    text-decoration: none;
 }

 .active-link:hover{
    color: #000;
 }

@media (max-width: 575px) {
    .navbar-nav{
        margin-left: 13px;
    }
    .navbar-hidden-options .navbar-nav {
  
        margin-left: 0px;
      }
  } 

`, "",{"version":3,"sources":["webpack://./src/components/nav/MainNav/MianNav.css"],"names":[],"mappings":";AAGA;;IAEI,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,wBAAwB;IACxB,gBAAgB;;AAEpB;AACA;IACI,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,wBAAwB;IACxB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;AACA;;GAEG,YAAY;;AAEf;AACA;IACI,kBAAkB;AACtB;;AAEA;;IAEI,kBAAkB;EACpB;;CAED;IACG,gBAAgB;IAChB,SAAS;IACT,+BAA+B;IAC/B,kBAAkB;IAClB,eAAe;IACf,wBAAwB;;IAExB,qBAAqB;CACxB;;CAEA;IACG,WAAW;CACd;;AAED;IACI;QACI,iBAAiB;IACrB;IACA;;QAEI,gBAAgB;MAClB;EACJ","sourcesContent":["\n@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');\n\n.link{\n    \n    gap: 3rem;\n    color: #000;\n    text-align: center;\n    font-size: 30px;\n    font-family: Happy Lucky;\n    font-weight: 500;\n    \n}\n.navLib{\n    gap: 3rem;\n    color: #000;\n    text-align: center;\n    font-size: 30px;\n    font-family: Happy Lucky;\n    font-weight: 500;\n    text-decoration: none;\n}\n\n.bk_logo{\n    width: 85px;\n    height: 84px;\n}\n.navbar-collapse{\n  \n   z-index: 100;\n\n}\n.navbar-nav{\n    margin-left: 300px;\n}\n\n.navbar-hidden-options .navbar-nav {\n  \n    margin-left: 465px;\n  }\n\n .active-link{\n    margin-top: 10px;\n    gap: 3rem;\n    color: var(--bs-nav-link-color);\n    text-align: center;\n    font-size: 30px;\n    font-family: Happy Lucky;\n\n    text-decoration: none;\n }\n\n .active-link:hover{\n    color: #000;\n }\n\n@media (max-width: 575px) {\n    .navbar-nav{\n        margin-left: 13px;\n    }\n    .navbar-hidden-options .navbar-nav {\n  \n        margin-left: 0px;\n      }\n  } \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
