import React from 'react'

const UserProductCard = ({book, addToCart}) => {
    // const handleSelect = () => {
    //     onSelect(book._id);
    //   };

  return (
    <>
    <div>
        {book.title} <span> --- </span> {book.description}
        {/* <input
            type="checkbox"
            checked={setSelectedBooks}
            onChange={handleSelect}
        /> */}
        <button onClick={() => addToCart(book)}>Add to Cart</button>
    </div>
    </>
  )
}

export default UserProductCard
