import './DashBoard_header.css';
import { Image, Space } from 'antd';
import logo from "../../../asset/bk_logo.png"
import { BellFilled } from "@ant-design/icons"
import { Badge } from 'antd';
import { Typography } from 'antd';
import { Input } from "antd";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

function DashBoardHeader({onHandleSearch}) {

  const [keyword, setKeyword] = useState("");
  const currentNavigation = useLocation();
  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setKeyword(value);
    if(currentNavigation.pathname == '/books1' || currentNavigation.pathname ==  '/admin/dashboard' ) {
      console.log("hello");
      onHandleSearch(value);
    } else onHandleSearch(value);
  }
  return <>

    <div className='AppHeader'>
      <div className='AppHeader_logo'>
        <Image src={logo}
          width={50}
          height={65}
        ></Image>
        <div className="AppHeader_heading">
          <h1>Book Kulture</h1>
        </div>
      </div>
      <Typography.Title style={{ fontFamily: 'Montserrat', fontSize: "32px", color: "rgba(0, 0, 0, 0.80)", fontWeight: "600" }}>DashBoard</Typography.Title>
      <Space>
        <Input
          type='text'
          name='search'
          placeholder="Search"
          value={keyword}
          onChange={handleSearchChange}
          style={{
            width: "345px", height: "41px", background: "#FFF",
          }}
        />
        {/* <Badge count={7}>
          <BellFilled style={{ fontSize: '30px', color: "rgba(0, 0, 0, 0.50)" }} />
        </Badge> */}
      </Space>
    </div>
  </>
}

export default DashBoardHeader;