import './ShopMainPage.css'
import ShopBook from '../../asset/ShopImages/ShopBook.jpg'
import ShopProductCarousel from './ShopProductCarousel';
import Navbarg from '../Main/Navbarg';
import DropdownAuthor from './DropdownAuthor';
import { useState, useEffect } from 'react';
import { searchBooks } from '../../functions/book';

function ShopMainPage() {

  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedAuthorId, setSelectedAuthorId] = useState(null);
  const [selectedAuthorName, setSelectedAuthorName] = useState("");
  const [cartItemsLength, setCartItemsLength] = useState(0);
  const [searchedResponse, setSearchedResponse] = useState([]);
  const [filterLabel, setFilterLabel] = useState(null);
  const [ageFilter, setAgeFilter] = useState("");
 
  const cartLengthUpdate = (len) => {
    setCartItemsLength(len);
  }

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
    handleSearch(keyword)
  };

  const onSelectedAuthor = (selectedAuthorId, selectedAuthorName) => {
    setSelectedAuthorId(selectedAuthorId);
    setSelectedAuthorName(selectedAuthorName);
  }

  const onFilterLabel = (filterLabel) => {
    setFilterLabel(filterLabel);
  }

  const onAgeFilter = (ageFilter) => {
    console.log('parent', ageFilter)
    setAgeFilter(ageFilter);
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return function(...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleSearch = debounce(async (term) => {
    if(term.length > 1){
      try {
        searchBooks(term)
        .then((res) => {
          // console.log(res.data)
          setSearchedResponse(res.data)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        })
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    
  }, 500);

  return (
    <>

      <div className='ShopMainPage'>
        <Navbarg cartItemsLength={cartItemsLength}></Navbarg>
        <div className='ShopMainPage_div'>
          <div className='ShopMainPage_div_content'>
            <h2>Harry Potter</h2>
            <div className='ShopMainPage_div_p'>
              <p>"Harry Potter and the Philosopher's Stone" by J.K. Rowling is the first book in the series, introducing young wizard Harry Potter as he begins his adventures at Hogwarts, uncovers the mystery of the Philosopher's Stone, and faces the dark wizard Voldemort.</p>
            </div>
          </div>
          <div className='ShopMainPage_div_book'>
            <img src={ShopBook} alt='ShopBookimg'></img>
          </div>
        </div>
      </div><br></br>

      <DropdownAuthor onSearchKeyword={handleSearchKeyword} onSelectedAuthor={onSelectedAuthor} onFilterLabel={onFilterLabel} onAgeFilter={onAgeFilter}></DropdownAuthor>


      <ShopProductCarousel
        cartLength={cartLengthUpdate}
        searchedResponse={searchedResponse}
        searchKeyword={searchKeyword}
        selectedAuthorId={selectedAuthorId}
        selectedAuthorName={selectedAuthorName}
        filterLabel={filterLabel}
        onSelectedAuthor={onSelectedAuthor}
        ageFilter={ageFilter}
      ></ShopProductCarousel>
    </>
  )

}

export default ShopMainPage;