import React, { useEffect, useState } from 'react'
import { orderHistory } from '../../../functions/order'
import { useSelector } from 'react-redux';
import { FadeLoader } from 'react-spinners';
import "./orderHistory.css";

function OrderHistory() {

  const { user } = useSelector((state) => ({ ...state }));
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const logoUrl = '/bookkulturelogo.png';

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    },500);
    loadUserOrderHistory();
  }, [])

  const loadUserOrderHistory = () => {
    console.log(user);
    if(user){
      if(user.role === 'subscriber'){
        setLoading(true);
        orderHistory(user.token)
        .then((res) => {
          console.log(res.data)
          setOrder(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err)
          setLoading(false);
        })
      }
    }
    
  }

  return <>
  <div className='main-box' style={{width: '100%', height:'90vh' ,overflowY: "auto"}}>
  <h2 className="head-order-history" >Order History</h2>
    {loading ? (
      <>
      <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '400px' }}>
        <FadeLoader color={'red'} loading={loading} size={150} />
      </div>
      </>
    ) : (
      <>
      <div>
      {user && user.role === 'subscriber' ? (
        <>
        {order && order.length > 0 && order.map((b) => (
          <div className='order_container' key={b._id}>
          <h5 style={{color: "black"}}>Status - {b.orderStatus}</h5>
          <p style={{color: "black"}}>
            Date of Order: {new Date(b.createdAt).toLocaleString('en-IN', { 
              timeZone: 'Asia/Kolkata', 
              year: 'numeric', 
              month: '2-digit', 
              day: '2-digit', 
              hour: '2-digit', 
              minute: '2-digit'
            })}
          </p>
          <div className='image_container' style={{display:"flex", justifyContent:"flex-start", width:"100%"}}>
            {b.books.map((book) => (
              <div key={book._id} className='ProductCard'>
              <div className='ProductImg'>
                <img src={book.images[0] ?  (book.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book.images[0].public_id + '.jpg' : book.images[0].url) : logoUrl} alt="Product" />
              </div>
              <div className='Product-cardheader'>
                <div className='Product-cardheader_content'>
                  <p>{book.title}</p>
                  <p className='available'>Available</p>
                </div>
              </div>
            </div>
            ))}
          </div>
          </div>
        ))}
        
        </>
      ) : (<p>No Order History</p>)}
      </div>
      </>
    )}
  </div>
    
  </>
      
}

export default OrderHistory
