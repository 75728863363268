// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dashboardwalcome{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 70vh; */
}


.WelcomeDashboardpage{
  
    color: #FF5A62;
text-align: center;
font-family: Montserrat;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-right: 100px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DashBoard/Dashboard.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;AACtB;;;AAGA;;IAEI,cAAc;AAClB,kBAAkB;AAClB,uBAAuB;AACvB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB,mBAAmB;AACnB","sourcesContent":["\n.dashboardwalcome{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    /* height: 70vh; */\n}\n\n\n.WelcomeDashboardpage{\n  \n    color: #FF5A62;\ntext-align: center;\nfont-family: Montserrat;\nfont-size: 40px;\nfont-style: normal;\nfont-weight: 400;\nline-height: normal;\nmargin-right: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
