// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/fonts/Warung Kopi Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../src/fonts/HappyLucky-gxnZP.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../src/fonts/Appetite Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */





body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Warung Kopi'; /* You can choose any name you like */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Happy Lucky'; /* You can choose any name you like */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Appetite'; /* You can choose any name you like */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* @font-face {
  font-family: 'Montserrat'; 
  src: url('../src/fonts/Appetite\\ Regular.ttf') format('truetype');
  src: url('../src/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;;kCAEkC;;;;;;AAMlC;;EAEE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,0BAA0B,EAAE,qCAAqC;EACjE,+DAAqE;EACrE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B,EAAE,qCAAqC;EACjE,+DAAgE;EAChE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB,EAAE,qCAAqC;EAC9D,+DAAiE;EACjE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;;;;;;GAMG","sourcesContent":["\n/* @import 'tailwindcss/base';\n@import 'tailwindcss/components';\n@import 'tailwindcss/utilities'; */\n\n\n\n\n\nbody {\n\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n@font-face {\n  font-family: 'Warung Kopi'; /* You can choose any name you like */\n  src: url('../src/fonts/Warung\\ Kopi\\ Regular.otf') format('opentype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Happy Lucky'; /* You can choose any name you like */\n  src: url('../src/fonts/HappyLucky-gxnZP.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Appetite'; /* You can choose any name you like */\n  src: url('../src/fonts/Appetite\\ Regular.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n/* @font-face {\n  font-family: 'Montserrat'; \n  src: url('../src/fonts/Appetite\\ Regular.ttf') format('truetype');\n  src: url('../src/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
