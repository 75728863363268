// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrowback{
    margin-top: 30px;
    margin-left: 60px;
}


.explore-divs{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-left: 40px;
   
    
}
.explore-heading{

display: flex;
flex-direction: row;
  text-align: center;
    color: #111;
    font-family: Montserrat;

font-size: 60px;
font-style: normal;
font-weight: 900;
line-height: normal;
gap: 50px;
margin-left: 40px;
padding-top:20px ;

}

@media (max-width: 575px) {
    .explore-heading{

      
     
        margin-left: 40px;
        gap: 40px;
        }

    .explore-heading h1{
        margin-top: 10px;
    }
}


`, "",{"version":3,"sources":["webpack://./src/pages/Shop/ExploreGenre.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;;AAGA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,iBAAiB;;;AAGrB;AACA;;AAEA,aAAa;AACb,mBAAmB;EACjB,kBAAkB;IAChB,WAAW;IACX,uBAAuB;;AAE3B,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB,SAAS;AACT,iBAAiB;AACjB,iBAAiB;;AAEjB;;AAEA;IACI;;;;QAII,iBAAiB;QACjB,SAAS;QACT;;IAEJ;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".arrowback{\n    margin-top: 30px;\n    margin-left: 60px;\n}\n\n\n.explore-divs{\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: 30px;\n    margin-left: 40px;\n   \n    \n}\n.explore-heading{\n\ndisplay: flex;\nflex-direction: row;\n  text-align: center;\n    color: #111;\n    font-family: Montserrat;\n\nfont-size: 60px;\nfont-style: normal;\nfont-weight: 900;\nline-height: normal;\ngap: 50px;\nmargin-left: 40px;\npadding-top:20px ;\n\n}\n\n@media (max-width: 575px) {\n    .explore-heading{\n\n      \n     \n        margin-left: 40px;\n        gap: 40px;\n        }\n\n    .explore-heading h1{\n        margin-top: 10px;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
