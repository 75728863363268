import { useState } from 'react';
import ShopMainPage from './Shop/ShopMainPage';
import Footer from '../components/nav/Footer/Footer';
import { useEffect } from 'react';
import { FadeLoader } from 'react-spinners';

function Shop() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); 
  }, []);

  return <>

    {loading ? (
      <>
        <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <FadeLoader color={'red'} loading={loading} size={150} />
        </div>
      </>
    ) : (
      <>
      <ShopMainPage />
      <br />
      <Footer />
      </>
    )}
    
    


  </>
}

export default Shop;