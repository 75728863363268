import React from 'react'

const BookCreateForm = ({handleSubmit, handleChange, values, setValues, handleCategoryChange, handleChangeAuthor}) => {


    const  {
        title,
        // slug,
        description,
        category,
        categories,
        author,
        authors,
        quantity,
        sold,
        images,
        barcode,
        age,
        language
    } = values
    

  return (
    <>
        <form onSubmit={handleSubmit}>
            <div>
                <label>Title:</label>
                <input
                name="title"
                type="text"
                placeholder='Title'
                value={title}
                onChange={handleChange}
                />
            </div>
            <div>
                <label>Description:</label>
                <textarea
                name="description"
                placeholder='Description'
                value={description}
                onChange={handleChange}
                required
                ></textarea>
            </div>
            <div>
                <label>Category:</label>
                <select
                    name="category"
                    placeholder='Catoegory'
                    onChange={handleCategoryChange}
                    value={category}
                >
                {categories.map((c) => (
                    <option key={c._id} value={c._id}>
                        {c.name}
                    </option>
                ))}
            </select> 
            </div>

            <div>
                <label>Author:</label>
                <select
                    name="author"
                    placeholder='Author'
                    onChange={handleChangeAuthor}
                    value={author}
                >
                {authors.map((c) => (
                    <option key={c._id} value={c._id}>
                        {c.name}
                    </option>
                ))}
                </select>
            
            </div>
            <div>
                <label>Quantity:</label>
                <input
                name="quantity"
                placeholder='Quantity'
                type="number"
                value={quantity}
                onChange={handleChange}
                />
            </div>
            <div>
                <label>Sold:</label>
                <input
                name="sold"
                placeholder='Sold'
                type="number"
                value={sold}
                onChange={handleChange}
                />
            </div>
            <div>
                <label>Barcode:</label>
                <input
                name="barcode"
                placeholder='Barcode'
                value={barcode}
                onChange={handleChange}
                />
            </div>
            <div>
                <label>Age:</label>
                <select value={age} 
                onChange={handleChange}
                placeholder='Age'
                name='age'
                >
                <option value="7 to 12">7 to 12</option>
                <option value="10 to 15">10 to 15</option>
                <option value="10 to 18">10 to 18</option>
                <option value="8 to 14">8 to 14</option>
                <option value="6 to 14">6 to 14</option>
                <option value="All Age Group">All Age Group</option>
                <option value="Not Specify">Not Specify</option>
                <option value="14 and Above">14 and Above</option>
                <option value="12 to 18">12 to 18</option>
                <option value="18+">18+</option>
                </select>
            </div>
            <div>
                <label>Language:</label>
                <select value={language}
                placeholder='Language'
                onChange={handleChange}
                name='language'
                >
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
                <option value="Marathi">Marathi</option>
                </select>
            </div>
            <button type="submit">Submit</button>
        </form>
    </>
  )
}

export default BookCreateForm
