import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCvUJDsMkHLEoaEl986T4oPfI7g9S2OULg",
  authDomain: "book-kulture.firebaseapp.com",
  projectId: "book-kulture",
  storageBucket: "book-kulture.appspot.com",
  messagingSenderId: "768052990479",
  appId: "1:768052990479:web:f8d6c2be45f6a92300897a"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

 //export
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

 
export default firebase;