// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainrefunddiv_h1{
    text-align: center;
    font-size: 30px;
    font-family: Happy Lucky;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 20px;
    margin-top: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
   
    .mainrefunddiv_h1{

        margin-bottom: 50px;
        font-weight: 600;
 
    }

}`, "",{"version":3,"sources":["webpack://./src/pages/Policies/Refund.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,wBAAwB;IACxB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;;IAEI;;QAEI,mBAAmB;QACnB,gBAAgB;;IAEpB;;AAEJ","sourcesContent":[".mainrefunddiv_h1{\n    text-align: center;\n    font-size: 30px;\n    font-family: Happy Lucky;\n    font-weight: 400;\n    letter-spacing: 1px;\n    margin-bottom: 20px;\n    margin-top: 10px;\n}\n\n@media (min-width: 768px) and (max-width: 1024px) {\n   \n    .mainrefunddiv_h1{\n\n        margin-bottom: 50px;\n        font-weight: 600;\n \n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
