let initialState = []

if(typeof window !== "undefined"){
    if(localStorage.getItem("cartItems")) {
        initialState = JSON.parse(localStorage.getItem("cartItems"));
    }else{
        initialState = [];
    }
}

export const cartReducer = (state = initialState, action) => {
    switch(action.type) {
        case "ADD_TO_CART":
            return [...state, action.payload];
        case "REMOVE_FROM_CART":
            console.log(state);
            console.log(action.payload);
            return state.filter((book) => book.book._id !== action.payload);
             
        default:
            return state;
    }
}