import React, { useEffect, useState } from 'react'
import { getCartList, removeBookCart } from '../../../functions/cart'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import UserNav from '../../../components/nav/UserNav';
import { createOrder } from '../../../functions/order';

const Cart = ({ cartBooks }) => {

  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBooks, setSelectedBooks] = useState([]);

  const { user, cart } = useSelector((state) => ({ ...state }));

  const totalBooksInCart = cartItems[0] ? cartItems[0].length : 0;


  useEffect(() => {
    loadCart();
  }, []);

  const loadCart = () => {
    setLoading(true);
    getCartList(user.token)
      .then((res) => {
        console.log("res/data--", res.data)

        setLoading(false);
        setCartItems((prevCartItems) => [...prevCartItems, res.data]);
        console.log("CartItems", cartItems)
        // setCartItems(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("hello", err);
      })
  }

  const handleRemove = (id) => {

    console.log(id);
    removeBookCart(id, user.token)
      .then((res) => {
        // loadCart();
        setCartItems(cartItems)
        // window.location.reload();
        // console.log("length---", cartItems.length)
        loadCart();
        console.log("removed", id);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const handleSelectBook = (bookId) => {
    setSelectedBooks((prevSelectedBooks) => {
      if (prevSelectedBooks.includes(bookId)) {
        console.log("Filter wala chala, remove kiya");
        return prevSelectedBooks.filter((id) => id !== bookId);
      } else {
        console.log("add hua ");
        return [...prevSelectedBooks, bookId];
      }
    });

    console.log('Selected Books:', selectedBooks);
  };

  const handleOrder = () => {
    if (selectedBooks.length > 6) {
      console.log("PLEASE SELECT LESS THAN 6 BOOKS")
      console.log(selectedBooks.length);
      toast.error("PLEASE SELECT LESS THAN 6 BOOKS");
    } else {
      console.log(selectedBooks)
      createOrder(selectedBooks, user.token)
        .then((res) => {

          console.log("Order placed successfully!!!!", res.data);
          console.log(selectedBooks.length);
          toast.success("ORDER PLACED SUCCESSFULLY");
        })
        .catch((err) => {
          console.log("Order me error", err);
        })

      // window.location.reload();
    }
  };

  return (
    <div>
      <UserNav cartItems={totalBooksInCart} />
      <h1>User Cart!!!</h1>
      {loading ? (
        <h4>Loading....</h4>
      ) : (
        <h4>Showing Cart({totalBooksInCart})</h4>
      )}

      {cartItems.map((cartItem, index) => (
        <div key={index}>
          {Array.isArray(cartItem) ? (
            cartItem.map((item) => (
              <div key={item._id}>
                <span>Author: {item.author.name}</span><br />
                <span>Title: {item.title}</span><br />
                <span>ID: {item._id}</span><br />
                
                <input
                  type="checkbox"
                  checked={selectedBooks.includes(item._id)} // Check if the book is in the selectedBooks array
                  onChange={() => handleSelectBook(item._id)} // Handle the checkbox change
                />

                <br />

                <button onClick={() => { handleRemove(item._id) }}>Remove</button>
                <hr />
                
              </div>
            ))
          ) : (
            <div>
              {/* Handle the case when cartItem is not an array */}
              {/* For example, you can show an error message */}
              <span>Invalid cart item</span>
              <hr />
            </div>
          )}
        </div>
      ))}
      <button onClick={handleOrder}>Order</button>
      {/* <h2>Cart Showing from redux</h2> */}
      {/* {JSON.stringify(cart)} */}

      {/* {JSON.stringify(cartItems)} */}



    </div>
  )
}

export default Cart
