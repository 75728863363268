import React from 'react'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { getBook, updateBook } from "../../../functions/book";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import BookUpdateForm from '../../../components/forms/BookUpdateForm';
import { getCategories } from '../../../functions/category';
import { getAuthors } from '../../../functions/author';
import { FadeLoader } from 'react-spinners';


const initialState = {
    title: "",
    description: "",
    categories: [],
    category: "",
    authors: [],
    author: "",
    quantity: 2,
    sold: 1,
    images: [],
    barcode: 1238,
    age: "",
    language: "English",
}

const UpdateBook = () => {

    const { user } = useSelector((state) => ({ ...state }));

    const [values, setValues] = useState(initialState);
    const [loadingData, setLoadingData] = useState(true);
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(true);
    //   const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const params = useParams();

    
    useEffect(() => {
        setTimeout(() => {
          setLoadingData(false);
        }, 1000); // Change the delay as needed
      }, []);

    if(user){
        if(user.role !== 'admin'){
            navigate('/library');
        }
    }

    useEffect(() => {
        loadCategories();
        loadCategory();
    }, [])

    const loadCategories = () => {

        getCategories()
        .then((c) => {
        setValues((prevValues) => ({ ...prevValues, categories: c.data}));
        values.categories = c.data
        
        console.log("Updated Categories:", values.categories);
        })
        .catch((error) => {
        // Handle error
        toast.error(error.response.data.err)
        console.log(error);
        });

        getAuthors()
        .then((c) => {
            setValues((prevValues) => ({ ...prevValues, authors: c.data}));
            values.authors = c.data;
            console.log("Updated Authors:", c.data);
          })
          .catch((error) => {
            // Handle error
            toast.error(error.response.data.err)
            console.log(error);
          });
    }

    const loadCategory = () => {
        getBook(params.slug)
        .then((c) => {
            console.log("c-->", c);
            setValues({...values, ...c.data});
            // setLoadingData(false);
        })
        .catch((err) => {
            // setLoadingData(false);
            console.log(err);
            toast.error(err.response.data.err)
            
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // setLoading(true);
        updateBook(params.slug, {values}, user.token)
            .then((res) => {
                // setLoading(false);
                setTitle("");
                toast.success(`"${res.data.title}"is updated`);
                navigate('/books1');
                //   loadCategories();
            })
            .catch((err) => {
                console.log(err);
                // setLoading(false);
                if (err.response.status === 400) toast.error(err.response.data);
            });
    }

    const handleChange = (e) => {
        
        setValues({...values, [e.target.name] : e.target.value})
        console.log("Values handleChange-----",values);
    }

    const handleCategoryChange = (e) => {
        e.preventDefault();
        console.log("CLICKED CATEGORY", e.target.value);
        setValues({ ...values, category: e.target.value });
        console.log("Values handleChange-----",values);
    };

    const handleChangeAuthor = (e) => {
        e.preventDefault();
        console.log("CLICKED CATEGORY", e.target.value);
        setValues({ ...values, author: e.target.value });
        console.log("Values handleChange-----",values);
    };

    return (
        <>
            {/* <AdminNav /> */}
            {loadingData ? (
                <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <FadeLoader color={'red'} loading={loading} size={150} />
                </div>
            ) : (
                <>
                    <h1 style={{display: 'flex', justifyContent: 'center', margin: '20px', color: 'red'}}>UpdateBook Page</h1>
                    {/* {JSON.stringify(values)} */}
                    <BookUpdateForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        values={values}
                        setValues={setValues}
                        handleCategoryChange={handleCategoryChange}
                        handleChangeAuthor={handleChangeAuthor}
                    />
                </>
            )}
        </>
    )
}
export default UpdateBook;
