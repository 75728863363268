import { Space, Typography, Image, Table, Modal, Input } from "antd";
import "./Books.css"
import book1 from "../../../asset/BestSellerImages/book1.png"
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons"
import { toast } from 'react-toastify'
import { createBookMarathi, getBooksByCount } from "../../../functions/book";
import { createBook, removeBook } from "../../../functions/book";
import { getAuthors } from "../../../functions/author";
import { getCategories } from "../../../functions/category";
import FileUpload from "../../../components/forms/FileUpload";
import { Link } from "react-router-dom";

const initialState = {
    title: "",
    slug: "",
    description: "",
    categories: [],
    category: "",
    authors: [],
    author: "",
    quantity: "",
    sold: Number,
    images: [],
    barcode: "",
    age: "",
    language: "English",
}

function Books({ searchKeyword, keyLength }) {

    const [addbook, setAddbook] = useState(false)
    const [isEditing, setIsediting] = useState(false)
    const [editingbook, setEdittingbook] = useState(null)
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([])
    const [values, setValues] = useState(initialState);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchedBooks, setSearchedBooks] = useState([]);
    const { user } = useSelector((state) => ({ ...state }));

    useEffect(() => {
        loadAllProducts(currentPage);
        loadCategories();
    }, [])

    useEffect(() => {
        if (keyLength.length > 0) {
            const formattedData = searchKeyword.map((book) => ({
                key: book._id,
                name: book.title,
                id: book._id,
                status: book.status,
                quantity: book.quantity,
                image: book.images,
                author: book.author,
                slug: book.slug,
            }));
            setSearchedBooks(formattedData);
        } else {
            setSearchedBooks([]); // Reset searchedBooks if searchKeyword is empty
        }
    }, [searchKeyword]);
    
    const addbookfunction = () => {
        setAddbook(true);
    }
    const closeAddBook = () => {
        setAddbook(false);
    };

    const loadCategories = () => {

        getCategories()
            .then((c) => {
                setValues((prevValues) => ({ ...prevValues, categories: c.data, category: c.data[0]._id }));
            })
            .catch((err) => {
                toast.error(err.response.data.err)
                console.log(err);
            });

        getAuthors()
            .then((c) => {
                setValues((prevValues) => ({ ...prevValues, authors: c.data, author: c.data[0]._id }));
            })
            .catch((err) => {
                toast.error(err.response.data.err)
                console.log(err);
            });
    }

    const loadAllProducts = (page) => {
        setLoading(true);
        setCurrentPage(page);
        const numberOfBooks = page * 15;
        getBooksByCount(numberOfBooks)
            .then((res) => {
                
                setBooks(res.data);
                const formattedData = res.data.map((book) => ({
                    key: book._id, // Use a unique key for each item
                    name: book.title,
                    id: book._id,
                    status: book.status,
                    quantity: book.quantity,
                    image: book.images,
                    author: book.author,
                    slug: book.slug,
                }));
                setDataSource(formattedData);
                loadCategories();
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err.response.data.err)
                console.log(err);
            })
    }

    const columns = [
        {
            key: "1",
            title: "Product details",
            dataIndex: 'name',
            render: (text, dataSource) => (
                <Space>
                    <Image src={dataSource.image[0] ? (dataSource.image[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + dataSource.image[0].public_id + '.jpg' : dataSource.image[0].url)  : book1} style={{ width: "70px" }} />

                    <div className="product_name">
                        <h1>{text}</h1>

                        <p>{dataSource?.author?.name}</p>
                    </div>
                </Space>
            ),
        },
        {
            key: "2",
            title: "Product Id",
            dataIndex: 'id'
        },
        {
            key: "3",
            title: "Status",
            dataIndex: 'status'
        },
        {
            key: "4",
            title: "Quantity",
            dataIndex: 'quantity'
        },
        {
            key: "5",
            title: "Actions",
            render: (record) => {
                return <>
                    <Link to={`/admin/book1/${record.slug}`}>
                        <EditOutlined onClick={() => {
                            onEditStudent(record);
                        }} />
                    </Link>

                    <DeleteOutlined onClick={() => {
                        handleRemove(record.slug)
                    }}
                        style={{ color: "red", marginLeft: "20px" }} />
                </>
            }
        }

    ]

    const onEditStudent = (record) => {
        setIsediting(true);
        setEdittingbook({ ...record })
    };

    const resetEditing = () => {
        setIsediting(false);
        setEdittingbook(null)
    }

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handleChangeAuthor = (e) => {
        e.preventDefault();
        setValues({ ...values, author: e.target.value });
    };

    const handleCategoryChange = (e) => {
        e.preventDefault();
        setValues({ ...values, category: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Clicked", values)
        setLoading(true);
        if(values.language === "Marathi"){
            createBookMarathi(values, user?.token)
            .then((res) => {
                setLoading(false);
                setValues(initialState);
                initialState.images = [];
                toast.success(`"${res.data.title}"is created`);
                loadAllProducts();
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error : Could not create book")
                setLoading(false);
            })
        }else if(values.language === "English"){
            createBook(values, user?.token)
            .then((res) => {
                setLoading(false);
                setValues(initialState);
                initialState.images = [];
                toast.success(`"${res.data.title}"is created`);
                loadAllProducts();
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error : Could not create book")
                setLoading(false);
            })
        }
        
    }

    const handleRemove = async (slug) => {
        if (window.confirm("Delete?")) {
            setLoading(true);
            removeBook(slug, user.token)
                .then((res) => {
                    setLoading(false);
                    toast.error(`${res.data.title} deleted`);
                    loadAllProducts();
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error("Error: Could Not Delete the Book");
                })
        }
    }
    
    // if(searchKeyword.length > 0){
    //     console.log(searchKeyword);
    //     const formattedData = searchKeyword.map((book) => ({
    //         key: book._id,
    //         name: book.title,
    //         id: book._id,
    //         status: book.status,
    //         quantity: book.quantity,
    //         image: book.images,
    //         author: book.author,
    //         slug: book.slug,
    //     }));
    //     setSearchedBooks(formattedData);
    // }
   

    return (
        <>

            <div className="bookslist_edit">
                <Typography.Title level={2}>
                    Books
                </Typography.Title>
                <div className="newbook_button">
                    <button onClick={addbookfunction}>+ New Book</button>

                </div>


                <div>
                    <Table
                        columns={columns}
                        dataSource={keyLength.length > 0 ? searchedBooks : dataSource}
                        pagination={{
                            pageSize: 10,
                            onChange: (page, pageSize) => {
                                if(page > currentPage) loadAllProducts(page);
                            },
                        }}
                        >
                    </Table>
                </div>
                {addbook &&
                    <div className="AddBook">
                        <h1>Add Book</h1>
                        <CloseOutlined onClick={closeAddBook} style={{ float: 'right', cursor: 'pointer' }} />
                        <form onSubmit={handleSubmit}>


                            <div className="addbook_details">
                                <input
                                    className="bookname"
                                    type="text"
                                    name="title"
                                    value={values.title}
                                    onChange={handleChange}
                                    placeholder="Book Name"


                                    required
                                />
                                <label>Author:</label>
                                <select
                                    className="authordropdown"
                                    name="author"
                                    placeholder="Author"
                                    onChange={handleChangeAuthor}
                                    value={values.author}
                                >
                                    {values.authors.map((c) => (
                                        <option key={c._id} value={c._id}>
                                            {c.name}
                                        </option>
                                    ))}
                                </select>

                                <label>Category:</label>
                                <select
                                    className="bookscategory"
                                    name="category"
                                    placeholder="Category"
                                    onChange={handleCategoryChange}
                                    value={values.category}
                                >
                                    {values.categories.map((c) => (
                                        <option key={c._id} value={c._id}>
                                            {c.name}
                                        </option>
                                    ))}
                                </select>

                                <div className="addbookidquantity">
                                    <input
                                        className="addbookid"
                                        type="text"
                                        name="barcode"
                                        value={values.barcode}
                                        onChange={handleChange}
                                        placeholder="Barcode"

                                        required
                                    />
                                    <input
                                        className="addquantity"
                                        type="text"
                                        value={values.quantity}
                                        onChange={handleChange}
                                        placeholder="Quantity"
                                        name="quantity"
                                        required
                                    />
                                </div>
                                <textarea
                                    className="adddescription"
                                    placeholder="  Description"
                                    rows={4}
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    style={{ resize: 'vertical' }}
                                    required
                                />

                                <label>Age:</label>
                                <select value={values.age}
                                    onChange={handleChange}
                                    name='age'
                                    className="userage"
                                >
                                    <option value="">Age</option>
                                    <option value="7 to 12">7 to 12</option>
                                    <option value="10 to 15">10 to 15</option>
                                    <option value="10 to 18">10 to 18</option>
                                    <option value="8 to 14">8 to 14</option>
                                    <option value="6 to 14">6 to 14</option>
                                    <option value="All Age Group">All Age Group</option>
                                    <option value="Not Specify">Not Specify</option>
                                    <option value="14 and Above">14 and Above</option>
                                    <option value="12 to 18">12 to 18</option>
                                    <option value="18+">18+</option>
                                </select>

                            </div >

                            <div>
                                
                                <FileUpload
                                    values={values}
                                    setValues={setValues}
                                    loading={loading}
                                    setLoading={setLoading}
                                />

                                <label>Language:</label>
                                <select value={values.language}
                                    onChange={handleChange}
                                    name='language'
                                    className="userage"
                                >
                                    <option value="English">English</option>
                                    <option value="Marathi">Marathi</option>
                                </select>
                                
                                <button className="uploadbbok">Upload</button>
                            </div>
                        </form >
                    </div >
                }
                <br />

                <Modal
                    className="editingbook"
                    title="Edit Books"
                    open={isEditing}
                    okText="Save"
                    onCancel={() => {

                        resetEditing()
                    }}
                    onOk={() => {
                        setDataSource(pre => {
                            return pre.map(book => {
                                if (book.id === editingbook.id) {
                                    return editingbook
                                }
                                else {
                                    return book;
                                }
                            })
                        })
                        resetEditing()
                    }}
                >
                    <Input value={editingbook?.name}
                        placeholder="Book Name"
                        onChange={(e) => {
                            setEdittingbook(pre => {
                                return { ...pre, name: e.target.value }
                            })
                        }} />
                    <Input value={editingbook?.id}
                        placeholder="Book Id"
                        onChange={(e) => {
                            setEdittingbook(pre1 => {
                                return { ...pre1, id: e.target.value }
                            })
                        }} />
                    <Input value={editingbook?.status}
                        placeholder="Status"
                        onChange={(e) => {
                            setEdittingbook(pre => {
                                return { ...pre, status: e.target.value }
                            })
                        }} />
                    <Input value={editingbook?.quantity}
                        placeholder="Book Quantity"
                        onChange={(e) => {
                            setEdittingbook(pre => {
                                return { ...pre, quantity: e.target.value }
                            })
                        }} />
                </Modal>
            </div >
        </>
    );
}

export default Books;