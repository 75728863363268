import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { searchBooks } from '../../../functions/book';
import { adminUpdateOrder } from '../../../functions/order';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'


const ChangeOrder = () => {
    const location = useLocation();
    const { order, subscriberId } = location.state;
    const logoUrl = '/bookkulturelogo.png';
    const [orderBooks, setOrderBooks] = useState(order);
    const [inputValue, setInputValue] = useState('');
    const [searchedBooks, setSearchedBooks] = useState([]);
    const {user} = useSelector((state) => ({ ...state }));

    const openpopupfunction = () => {

    }

    const handleAddToCart = (book) => {
        if(orderBooks.length < 6){
            setOrderBooks([...orderBooks, book]);
        }else{
            alert("You can only select up to 6 books.");
        }
    }

    const handleRemoveFromCart = (bookId) => {
        setOrderBooks(orderBooks.filter((book) => book._id !== bookId._id));
    };


    const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setInputValue(value);
        debouncedSearch(value);
    };

    function debounce(func, delay) {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), delay);
        };
    }

    const search = async (value) => {
        if (value.length > 0) {
            searchBooks(value)
                .then((res) => {
                    console.log(res)
                    setSearchedBooks(res.data);
                })
        } else {
            setSearchedBooks([]);
        }
    };

    const debouncedSearch = debounce(search, 300);

    const handleOrder = () => {
        if (orderBooks.length > 6) {
          toast.error("PLEASE SELECT LESS THAN 6 BOOKS");
        } else {
            const selectedBooks = orderBooks;
          const body = {
            selectedBooks,
            subscriberId
          }
          adminUpdateOrder(body, user.token)
            .then((res) => {    
              console.log(orderBooks.length);
              toast.success("ORDER PLACED SUCCESSFULLY");
            })
            .catch((err) => {
              console.log("Order me error", err);
            })
        }
    };


    return (
        <div>
            <h1>ChangeOrder</h1>

            <div className="iconsearches">
                <FontAwesomeIcon
                    className="searchicong"
                    style={{ color: 'red', marginRight: '10px' }}
                    icon={faSearch}
                />
                <div className='searchBar'>
                    <input
                        type="text"
                        name="search"
                        className="orderBookSearchBar"
                        id="scrh"
                        placeholder="Search"
                        value={inputValue}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>

            <div className='ShopProductCarousel_container_bestSeller'>
                <div className='shop_explore'>
                    <h3 className='genre' >Ordered Books</h3>
                </div>
                <div className="wrapper">
                    {orderBooks.length > 0 ? (
                        <>
                            {orderBooks.map((book) => (
                                <div key={book._id}>
                                    <div className="ProductCard">
                                        <div className="ProductImg">
                                            {book.images[0] ? (
                                                <img
                                                    src={book.images[0].url}
                                                    onClick={() => openpopupfunction(book)}
                                                    alt="Product"
                                                />
                                            ) : (
                                                <img
                                                    src={logoUrl}
                                                    onClick={() => openpopupfunction(book)}
                                                    alt="Product"
                                                />
                                            )}
                                        </div>
                                        <div className="Product-cardheader">
                                            <div className="Product-cardheader_content">
                                                <p>{book.title}</p>

                                                {book.quantity > 0 ? (
                                                    <p className="available">Available</p>
                                                ) : (
                                                    <p className="available" style={{ color: "red" }}>Unavailable</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="addtocart">
                                            <button onClick={() => handleRemoveFromCart(book)}>
                                                <FaShoppingCart className="icon" />
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (<p>No result found</p>)}
                </div>
            </div>

            {inputValue.length > 0 && (
                <div className='ShopProductCarousel_container_bestSeller'>
                    <div className='shop_explore'>
                        <h3 className='genre' >Search Result</h3>
                    </div>
                    <div className="wrapper">
                        {searchedBooks.length > 0 ? (
                            <>
                                {searchedBooks.map((book) => (
                                    <div key={book._id}>
                                        <div className="ProductCard">
                                            <div className="ProductImg">
                                                {book.images[0] ? (
                                                    <img
                                                        src={book.images[0].url}
                                                        onClick={() => openpopupfunction(book)}
                                                        alt="Product"
                                                    />
                                                ) : (
                                                    <img
                                                        src={logoUrl}
                                                        onClick={() => openpopupfunction(book)}
                                                        alt="Product"
                                                    />
                                                )}
                                            </div>
                                            <div className="Product-cardheader">
                                                <div className="Product-cardheader_content">
                                                    <p>{book.title}</p>

                                                    {book.quantity > 0 ? (
                                                        <p className="available">Available</p>
                                                    ) : (
                                                        <p className="available" style={{ color: "red" }}>Unavailable</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="addtocart">
                                                <button onClick={() => handleAddToCart(book)}>
                                                    <FaShoppingCart className="icon" />
                                                    Add to Cart
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (<p>No result found</p>)}
                    </div>
                </div>
            )}

            <div className='buttonContainer'>
                <button className='orderButton' onClick={handleOrder}>Order Now</button>
            </div>
        </div>
    )
}

export default ChangeOrder
