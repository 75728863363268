import React, { useEffect, useState } from 'react'
import UserNav from "../../components/nav/UserNav"
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function UserProfile() {

  console.log("User Profile render---")

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    roleCheck();

    if(user.role === "admin"){
      navigate('/library');
    }
  }, []);

  const roleCheck = () => {
    if ((user.role === 'subscriber') || (user.role === 'viewer')) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <>
        <h1>User Profile</h1>
        <UserNav />
        {user.role}
        </>
      ) : (
        <>
        <h1>Loading...</h1>
        </>
      )}
    </>

  )
}


// {user ? (
//   <>
//   {((user.role === 'viewer') || (user.role === 'subscriber'))  && 
//     <>
//     <h1>User Profile</h1>
//     <UserNav />
//     {user.role}
//     </>
//   }
//   </>
// ) : (
//   <h4>Loading...</h4>
// )}