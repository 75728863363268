import axios from "axios";
import axiosInstance  from './services/interceptor';

export const getAuthors=async () =>
    await axios.get(`${process.env.REACT_APP_API}/authors`);

export const getAuthor=async (slug) =>
    await axios.get(`${process.env.REACT_APP_API}/author/${slug}`);

export const removeAuthor=async (slug, authtoken) =>
    await axiosInstance.delete(`${process.env.REACT_APP_API}/author/${slug}`,{
        headers: {
            authtoken,
        }
    });

export const updateAuthor=async (slug, author, authtoken) =>
    await axiosInstance.put(`${process.env.REACT_APP_API}/author/${slug}`,author,{
        headers: {
            authtoken,
        }
    });

export const createAuthor=async (author, authtoken) =>
    await axiosInstance.post(`${process.env.REACT_APP_API}/author`,author,{
        headers: {
            authtoken,
        }
    });