// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .container{
    display: flex;
    justify-content: center;
  }
  .heading{
    font-family: Amatic SC;
    font-size: 38px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.5px;
    text-align: center;
  }
  
  .main_box_cr{
    display: block;
    width: 30em;
    height: 26em;
    padding: 29px;
    border-radius: 1em;
    border: 2px solid black;
  }

  .email_cr{
    width: 402px;
    height: 47px;
    justify-content: center;
    position: relative;
    left: 33px;
    border-radius: 10px;
    margin-top: 21px;
    margin-bottom: 33px;
  }

  .password_cr{
    width: 402px;
    height: 47px;
    justify-content: center;
    position: relative;
    left: 33px;
    border-radius: 10px;
    margin-top: 21px;
    margin-bottom: 33px;
  }

  .submit_cr{
    width: 307px;
    height: 39px;
    display: block;
    position: relative;
    left: 80px;
    background: linear-gradient(0deg, #1271C4, #1271C4), linear-gradient(0deg, #286EFA, #286EFA);
    border-radius: 20px;
    color: black;
    margin-top: 17px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/auth/CompleteRegistration.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,uBAAuB;EACzB;EACA;IACE,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,uBAAuB;EACzB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,4FAA4F;IAC5F,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;EAClB","sourcesContent":["  .container{\n    display: flex;\n    justify-content: center;\n  }\n  .heading{\n    font-family: Amatic SC;\n    font-size: 38px;\n    font-weight: 700;\n    line-height: 40px;\n    letter-spacing: 0.5px;\n    text-align: center;\n  }\n  \n  .main_box_cr{\n    display: block;\n    width: 30em;\n    height: 26em;\n    padding: 29px;\n    border-radius: 1em;\n    border: 2px solid black;\n  }\n\n  .email_cr{\n    width: 402px;\n    height: 47px;\n    justify-content: center;\n    position: relative;\n    left: 33px;\n    border-radius: 10px;\n    margin-top: 21px;\n    margin-bottom: 33px;\n  }\n\n  .password_cr{\n    width: 402px;\n    height: 47px;\n    justify-content: center;\n    position: relative;\n    left: 33px;\n    border-radius: 10px;\n    margin-top: 21px;\n    margin-bottom: 33px;\n  }\n\n  .submit_cr{\n    width: 307px;\n    height: 39px;\n    display: block;\n    position: relative;\n    left: 80px;\n    background: linear-gradient(0deg, #1271C4, #1271C4), linear-gradient(0deg, #286EFA, #286EFA);\n    border-radius: 20px;\n    color: black;\n    margin-top: 17px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
