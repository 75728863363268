import { Link } from 'react-router-dom';
import './Footer.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import  insta from './Images/instagram 1.png'
import Linkedin from './Images/linkedin 1.png'
import fb from './Images/facebook 1.png'
import logo from '../../../asset/bookkulturelogo.png'

import { HeartFilled } from '@ant-design/icons';
import {MailFilled ,PhoneFilled ,EnvironmentFilled } from '@ant-design/icons';
function Footer() {
  return <>
    <div className="BkFooter">
      <div className="FooterMain">
        <div className="FooterLogo col-sm-4 "><img className="Bklogo" src={logo} alt="img" />
        <h1>BOOK KULTURE</h1></div>
        <div className='ContentFooter col-sm-8'>
      
         

          <div className="Contact">
            <div className='contactheadinh'><h2>Contact</h2></div>
            <div className='contactdetails'>
              <ul className='addlogo'>
                <li>
                <EnvironmentFilled className="socialLogo" style={{color:"white"}}/>
                <br /><br /><br />
                {/* <img className="socialLogo" src={email} alt="img" /> */}
                </li>
                <li>
                <PhoneFilled className="socialLogo" style={{color:"white"}} />
                {/* <img className="socialLogo" src={phone} alt="img" /> */}
                </li>
                <li>
                <MailFilled className="socialLogo" style={{color:"white"}}/>
                {/* <img className="socialLogo" src={location} alt="img" /> */}
                </li>

              </ul>
              <ul className='add'>
                <li>
                  12, Swastik Residency Road,<br/>Anand Nagar,<br/> Thane, Maharashtra 400615
                </li>
                <li>
                  7304886600
                </li>
                <li>
                  info@bookkulture.com
                </li>

              </ul>
            </div>
          </div>

          <div className="Support">
            <h2>Support</h2>
            <ul>
              <li >
               <Link className='bk' to="/terms" >Terms and Conditions</Link> 
              </li>
              {/* <li>
               <Link className='bk'>contact US</Link> 
              </li> */}
              <li>
               <Link className='bk' to="/privacy">Privacy Policy</Link> 
              </li>
              <li>
               <Link className='bk'to="/refund">Refund and Return Policy</Link> 
              </li>
              {/* <li>
               <Link className='bk'>SiteMap</Link> 
              </li>
              <li>
               <Link className='bk'>Learning Resources</Link> 
              </li> */}
            </ul>
          </div>
        </div>
      </div><br></br>

      <div className='socialLinks'>
        {/* <div className='line'></div> */}
        <div className='links'>
          <div className=' reserved col-sm' > All rights are reserved </div>
          <div className=' korslet col-sm'> <Link to={'https://korslet.com'} className='bk'>Made With <span className="heart-icon"><HeartFilled  style={{ color: 'red', marginBottom: '20px' , width: "30px" ,fontSize: '25px' , bottom:'5px' }}/></span> by KORSLET</Link> </div>
          <div className=' allLinks col-sm'> 
            <ul className='end'>
              <li ><Link><img className="socialLogo" src={insta} alt="img" /></Link></li>
              <li ><Link><img className="socialLogo" src={Linkedin} alt="img" /></Link></li>
              <li ><Link><img className="socialLogo" src={fb} alt="img" /></Link></li>
            </ul>
          </div>
        </div>
      </div>

    </div>


  </>
}

export default Footer;