import React from 'react'
import {Link} from 'react-router-dom'

const AdminNav = () => {

  return (
    <nav>
        <ul>
            <li><Link to="/admin/dashboard">Dashboard</Link></li>

            <li><Link to="/admin/book">Books</Link></li>

            <li><Link to="/admin/category">Category</Link></li>

            <li><Link to="/admin/author">Author</Link></li>

            <li><Link to="/admin/coupon">Coupon</Link></li>

            <li><Link to="/admin/password">Password</Link></li>
        </ul>
    </nav>
  )
}

export default AdminNav;
