// interceptor.js

// import axios from 'axios';
// import { useEffect } from 'react';
// import firebase from '../../firebase';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

// const useAxiosInterceptor = () => {
//   const axiosInstance = axios.create();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const setupResponseInterceptor = () => {
//       axiosInstance.interceptors.response.use(
//         (response) => response,
//         (error) => {
//           if (error.response && error.response.status === 401) {
//             // Handle 401 Unauthorized error, e.g., by logging out the user
//             console.log("logging out...interceptor");
//             //logout(); // You can call your logout function here
//             logout();
//           }
//           return Promise.reject(error);
//         }
//       );
//     };

//     const setupRequestInterceptor = () => {
//       axiosInstance.interceptors.request.use(
//         (config) => {
//           // Modify the request config, such as adding headers
//           console.log('Request interceptor fired:', config);
//           return config;
//         },
//         (error) => {
//           // Handle request errors
//           console.error('Request interceptor error:', error);
//           return Promise.reject(error);
//         }
//       );
//     };

//     // Call the setup interceptor methods
//     setupResponseInterceptor();
//     setupRequestInterceptor();

//     const logout = (data) => {
//       console.log("logging out...from logout service")
//       // firebase.auth().signOut();
//       // dispatch({ type: 'LOGOUT', payload: null });
//       // navigate('/login');
//     };

//     // Clean up the interceptors when the component is unmounted
//     return () => {
//       axiosInstance.interceptors.request.eject();
//       axiosInstance.interceptors.response.eject();
//     };
//   }, [axiosInstance]);

//   return axiosInstance;
// };

// export default useAxiosInterceptor;



import axios from 'axios';
import useLogoutService from './logoutService';

class AxiosInterceptor {
  logout = useLogoutService();
  constructor() {
    this.axiosInstance = axios.create();

    this.setupResponseInterceptor();
    this.setupRequestInterceptor();
  }

  setupResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error, e.g., by logging out the user
          // console.log("logging out...");
          this.logout.logout();
        }
        return Promise.reject(error);
      }
    );
  }

  setupRequestInterceptor() {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        // Modify the request config, such as adding headers
        // console.log('Request interceptor fired:', config);
        return config;
      },
      (error) => {
        // Handle request errors
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
      }
    );
  }

  getAxiosInstance() {
    return this.axiosInstance;
  }
}

const interceptor = new AxiosInterceptor();
export default interceptor.getAxiosInstance();
