import React, { useState } from 'react'
import { searchBooks } from '../../../functions/book';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FaShoppingCart } from 'react-icons/fa';
import { Button } from 'antd';
import { searchSubscribers } from '../../../functions/subscriber';
import { useSelector } from 'react-redux';
import { Table } from "antd";
import { createOrder } from '../../../functions/order';
import { toast } from 'react-toastify';
import './OrderBooks.css'
import { adminCreateOrder } from '../../../functions/order';

const OrderBooks = () => {
  const logoUrl = '/bookkulturelogo.png';
  const [searchedBooks, setSearchedBooks] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [isBookSearchable, setIsBookSearchable] = useState(true);
  const [subscriber, setSubscriber] = useState([]);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { user } = useSelector((state) => ({ ...state }));

  const columns = [
    {
      key: "1",
      title: "User Name",
      dataIndex: 'name',

    },
    {
      key: "2",
      title: "Email",
      dataIndex: 'mail'
    },
    {
      key: "3",
      title: "Contact",
      dataIndex: 'contact'
    },
    {
      key: "4",
      title: "Plan",
      dataIndex: 'plan'
    },
    {
      key: "5",
      title: "Days Left",
      dataIndex: 'days'
    },
    {
      key: "6",
      title: "Ordered books",
      dataIndex: 'bookedorders'
    },
    {
      key: "7",
      title: "Orders Left",
      dataIndex: 'leftorders'
    }
  ]

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setInputValue(value);
    debouncedSearch(value);
  };

  const search = async (value) => {
    if (value.length > 0) {
      if (isBookSearchable) {
        searchBooks(value)
          .then((res) => {
            setSubscriber([]);
            console.log(res)
            setSearchedBooks(res.data);
          })
      } else {
        setSearchedBooks([]);
        searchSubscribers(value, user.token)
          .then((res) => {
            const formattedData = res.data.map((subs) => ({
              name: subs.name,
              mail: subs.email,
              contact: subs.mobile,
              plan: subs.plan,
              days: (Math.round((new Date(subs.dateOfExpiration) - new Date()) / (1000 * 60 * 60 * 24))) > 0 ? (Math.round((new Date(subs.dateOfExpiration) - new Date()) / (1000 * 60 * 60 * 24))) : 0,
              bookedorders: subs.bookOrdered,
              leftorders: (subs.plan === '6 Months' ? 80 : (subs.plan === '9 Months' ? 120 : (subs.plan === '12 Months' ? 160 : 0))) - subs.bookOrdered || 0,
              _id: subs._id,
            }));
            setSubscriber(formattedData);
          })
      }

    } else {
      setSubscriber([]);
      setSearchedBooks([]);
    }
  };

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const openpopupfunction = () => {

  }

  const handleAddToCart = (book) => {
    console.log(book)
    if (selectedBooks.length < 6) {
      if (selectedBooks.includes(book._id)) alert("Already in cart");
      else setSelectedBooks([...selectedBooks, book]);
    } else {
      alert("You can only select up to 6 books.");
    }
  };

  const handleRemoveFromCart = (bookId) => {
    console.log(bookId)
    setSelectedBooks(selectedBooks.filter((book) => book._id !== bookId._id));
    console.log(selectedBooks)
  };

  const handleUserSelect = (subs) => {
    console.log(subs)
    const formattedData = {
      name: subs.name,
      mail: subs.mail,
      contact: subs.contact,
      plan: subs.plan,
      days: (Math.round((new Date(subs.dateOfExpiration) - new Date()) / (1000 * 60 * 60 * 24))) > 0 ? (Math.round((new Date(subs.dateOfExpiration) - new Date()) / (1000 * 60 * 60 * 24))) : 0,
      bookedorders: subs.bookedorders,
      leftorders: (subs.plan === '6 Months' ? 80 : (subs.plan === '9 Months' ? 120 : (subs.plan === '12 Months' ? 160 : 0))) - subs.bookedorders || 0,
      _id: subs._id,
    }
    setSelectedUser(formattedData);
    console.log(selectedUser)
  };

  const debouncedSearch = debounce(search, 300); // Adjust the debounce delay as needed

  const handleOrder = () => {
    console.log(selectedBooks, selectedUser._id)
    const subscriberId = selectedUser._id;
    if (selectedBooks.length > 6) {
      toast.error("PLEASE SELECT LESS THAN 6 BOOKS");
    } else {
      const body = {
        selectedBooks,
        subscriberId
      }
      adminCreateOrder(body, user.token)
        .then((res) => {

          console.log("Order placed successfully!!!!", res.data);
          console.log(selectedBooks.length);
          toast.success("ORDER PLACED SUCCESSFULLY");
        })
        .catch((err) => {
          toast.error(err.response.data);
          console.log("Order me error", err);
        })
    }
  };

  return (
    <>
      <h1 className='orderBookTitle'>Order By Admin</h1>

      <div className='orderBookContainer'>

        <Button
          className={isBookSearchable ? 'button-active' : 'button-inactive'}
          onClick={() => setIsBookSearchable(true)}
        >
          Books
        </Button>
        <Button
          className={!isBookSearchable ? 'button-active' : 'button-inactive'}
          onClick={() => setIsBookSearchable(false)}
        >
          Users
        </Button>
        <div className="iconsearches">
          <FontAwesomeIcon
            className="searchicong"
            style={{ color: 'red', marginRight: '10px' }}
            icon={faSearch}
            onClick={() => setOpenSearch(!openSearch)}
          />
          <div className='searchBar'>
            <input
              type="text"
              name="search"
              className="orderBookSearchBar"
              id="scrh"
              placeholder="Search"
              value={inputValue}
              onChange={handleSearchChange}
            />
          </div>
        </div>

      </div>


      {selectedUser && (
        <div>
          <h1 className='selectedUser'>Selected User</h1>
          <Table className="order_table" columns={columns} dataSource={[selectedUser]} />
        </div>
      )}

      {/* displaying selected books */}
      {selectedBooks.length > 0 && (
        <div className='ShopProductCarousel_container_bestSeller'>
          <div className='shop_explore'>
            <h3 className='genre' >Selected Books</h3>
          </div>
          <div className="wrapper">
            {selectedBooks.length > 0 ? (
              <>
                {selectedBooks.map((book) => (
                  <div key={book._id}>
                    <div className="ProductCard">
                      <div className="ProductImg">
                        {book.images[0] ? (
                          <img
                            src={(book.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book.images[0].public_id + '.jpg' : book.images[0].url)}
                            onClick={() => openpopupfunction(book)}
                            alt="Product"
                          />
                        ) : (
                          <img
                            src={logoUrl}
                            onClick={() => openpopupfunction(book)}
                            alt="Product"
                          />
                        )}
                      </div>
                      <div className="Product-cardheader">
                        <div className="Product-cardheader_content">
                          <p>{book.title}</p>

                          {book.quantity > 0 ? (
                            <p className="available">Available</p>
                          ) : (
                            <p className="available" style={{ color: "red" }}>Unavailable</p>
                          )}
                        </div>
                      </div>
                      <div className="addtocart">
                        <button onClick={() => handleRemoveFromCart(book)}>
                          <FaShoppingCart className="icon" />
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (<p>No result found</p>)}
          </div>
        </div>
      )}

      {inputValue.length > 0 && !isBookSearchable && (
        <div >
          <Table
            className="order_table"
            columns={columns}
            dataSource={subscriber}
            onRow={(record) => ({
              onClick: () => handleUserSelect(record),
              style: {
                backgroundColor:
                  selectedUser?._id === record._id ? '#e6f7ff' : 'transparent',
              },
            })}
          />
        </div>
      )}


      {/* Displaying search results */}

      {inputValue.length > 0 && isBookSearchable && (
        <div className='ShopProductCarousel_container_bestSeller'>
          <div className='shop_explore'>
            <h3 className='genre' >Search Result</h3>
          </div>
          <div className="wrapper">
            {searchedBooks.length > 0 ? (
              <>
                {searchedBooks.map((book) => (
                  <div key={book._id}>
                    <div className="ProductCard">
                      <div className="ProductImg">
                        {book.images[0] ? (
                          <img
                            src={(book.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book.images[0].public_id + '.jpg' : book.images[0].url)}
                            onClick={() => openpopupfunction(book)}
                            alt="Product"
                          />
                        ) : (
                          <img
                            src={logoUrl}
                            onClick={() => openpopupfunction(book)}
                            alt="Product"
                          />
                        )}
                      </div>
                      <div className="Product-cardheader">
                        <div className="Product-cardheader_content">
                          <p>{book.title}</p>

                          {book.quantity > 0 ? (
                            <p className="available">Available</p>
                          ) : (
                            <p className="available" style={{ color: "red" }}>Unavailable</p>
                          )}
                        </div>
                      </div>
                      <div className="addtocart">
                        <button onClick={() => handleAddToCart(book)}>
                          <FaShoppingCart className="icon" />
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (<p>No result found</p>)}
          </div>
        </div>
      )}


      {selectedBooks.length > 0 && selectedUser && (
        <div className='buttonContainer'>
          <Button className='orderButton' onClick={handleOrder}>Order Now</Button>
        </div>
      )}

    </>
  )
}

export default OrderBooks
