import React, {useState, useEffect} from 'react'
import UserNav from '../../../components/nav/UserNav'
import { getBooksByCount } from '../../../functions/book'
import UserProductCard from '../../../components/cards/UserProductCard';
import  {toast} from 'react-toastify'
import Cart from '../Cart/Cart';
import {addToCart, getCartList} from "../../../functions/cart";
import { useDispatch, useSelector } from 'react-redux';
import { currentSubscriber } from '../../../functions/subscriber';
import _ from "lodash";

const Books = () => {
    const [books, setBooks] = useState([]);
    const [selectedBooks, setSelectedBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [cartBooks, setCartBooks] = useState([]);
    const [cartItems, setCartItems] = useState([]);

    const {user} = useSelector((state) => ({...state}));

    let dispatch = useDispatch();

    const totalBooksInCart = cartItems[0] ? cartItems[0].length : 0;
    // let userToken = user.token;
    useEffect(() => {
      console.log(selectedBooks); // Perform actions whenever selectedBooks changes
    }, [selectedBooks]);

    useEffect(() => {
      loadAllProducts();
      loadCartItems();
    }, [])

    const loadCartItems = async() => {
      getCartList(user.token)
      .then((res) => {
        console.log("res/data--", res.data)
        setLoading(false);
        setCartItems((prevCartItems) => [...prevCartItems, res.data]);
        // window.location.reload();
        console.log("CartItems", cartItems)
        // setCartItems(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("hello", err);
      })
      
    }
  
    const loadAllProducts = () => {
      setLoading(true);
      getBooksByCount(10)
      .then((res) => {
        console.log(res.data);
        setBooks(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      })
    }

    const handleAddToCart = (book) => {
      console.log("book---------------->",{...book})
      if (cartItems.includes(book._id)) {
        toast.error('Already in the cart');
      } else {
        addToCart( book, user.token)
          .then((res) => {
            console.log("res", res)
            console.log("Book--->", book.title);
            // setCartBooks((prevCartBooks) => [...prevCartBooks, book._id]);
            // setCartItems((prevCartItems) => [...prevCartItems, res.data]);
            window.location.reload();
            // loadCartItems();
            
            // console.log("cartBooks", cartBooks);
            console.log("cartItems", cartItems);
            if(res.data === "Already in the cart"){
              toast.error(res.data);
            }else{
              toast.success("Added to the Cart");
            }
            
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
  
    return (
      <>
      <div>
        <h1>Display all Books</h1>
        <UserNav totalBooksInCart={totalBooksInCart}/>  
        <h4>All Books</h4>
        <h3>Cart {(totalBooksInCart)}</h3>
        {console.log("totalBooksInCart--->",totalBooksInCart)}
        {books.map((book) => (
            <div key={book._id}>
              <UserProductCard
                book={book}
                addToCart={handleAddToCart} 
              />
            </div> 
        ))}
          <br></br>
      </div>
      </>
    )
}

export default Books
