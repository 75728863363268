import axios from "axios";
import axiosInstance  from './services/interceptor';

export const getBooks = async () =>
    await axios.get(`${process.env.REACT_APP_API}/books`);

export const getBook = async (slug) =>
    await axios.get(`${process.env.REACT_APP_API}/book/${slug}`);

export const removeBook = async (slug, authtoken) =>
    await axiosInstance.delete(`${process.env.REACT_APP_API}/book/${slug}`, {
        headers: {
            authtoken,
        }
    });

export const downloadBooks = async(authtoken)=>
    await axios.get(`${process.env.REACT_APP_API}/books/admin/downloadbooks`,{
        responseType: 'blob',
        headers: {
            authtoken,
        },
    });

export const updateBook = async (slug, category, authtoken) =>
    await axiosInstance.put(`${process.env.REACT_APP_API}/book/${slug}`, category, {
        headers: {
            authtoken,
        },
    });

export const createBook = async (category, authtoken) =>
    await axios.post(`${process.env.REACT_APP_API}/book`, category, {
        headers: {
            authtoken,
        }
    });

export const createBookMarathi = async (category, authtoken) =>
    await axiosInstance.post(`${process.env.REACT_APP_API}/book/marathi`, category, {
        headers: {
            authtoken,
        }
    });

export const getBooksByCount = async (count) =>
    await axios.get(`${process.env.REACT_APP_API}/books/${count}`);

export const issuedBooks = async (authtoken) => {
    return await axiosInstance.get(`${process.env.REACT_APP_API}/issued-books`,
        {
            headers: {
                authtoken,
            }
        });
};

export const getBooksByCategories = async (count, data) => 
    await axios.post(`${process.env.REACT_APP_API}/category-books/${count}`, data);

export const searchBooks = async (searchKey) =>
    await axios.get(`${process.env.REACT_APP_API}/book/search/${searchKey}`);

export const booksByAuthor = async (count, authorName) =>
    await axios.post(`${process.env.REACT_APP_API}/author-books/${count}`, authorName);