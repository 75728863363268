import "./Refund.css"
import MainNavBar from "../../components/nav/MainNav/MainNavBar";
function Refund() {

  return <>
    <MainNavBar hideOptions={true}></MainNavBar>
    <div className="mainrefunddiv">
      <h1 className="mainrefunddiv_h1">Refund and Return Policy</h1>
      <div className="points">
        <div className="point1">
          <h1>1. Introduction</h1>
          <p>Welcome to BOOK KULTURE, your trusted destination for a world of literary
            exploration through our subscription-based book rental service. This Book
            Replacement and Return Policy outlines how BOOK KULTURE handles
            replacement requests for subscribers who encounter issues with their book rentals
            and the standard procedure of return of books subscribed. By subscribing to
            BOOK KULTURE and using our book rental services, you agree to the terms and
            conditions outlined in this policy. </p>
        </div>

        <div className="point2">
          <h1>2. Replacement Eligibility</h1>
          <p>2.1. Subscription Fees: Subscription fees are non-refundable once charged. We
            do not provide refunds for subscription fees, whether for quarterly, semi-annual,
            or annual plans.
          </p>
          <p>
            2.2. Replacement Eligibility: Replacement for book delivered are evaluated on a
            case-by-case basis. Replacement for book may be considered under the following
            circumstances:
            <p>
              • The book received is damaged or in poor condition.
              <br></br>
              • The wrong book was shipped or there was an error in the book selection.
              <br></br>
              • The book is lost in transit.
              <br></br>
              • Extenuating circumstances beyond the subscriber's control.
            </p>
          </p>

          <p>2.3. Damaged or Defective Books: If you receive a damaged or defective book,
            please contact us at [info@bookkulture.com] within 30 days of receiving the
            book. We will provide instructions for returning the book and, upon inspection,
            will offer a replacement.
          </p>

          <p>2.4. Lost Books: If you lose a rented book, you are responsible for the cost i.e.,
            Minimum retail price of the book, which will be charged to your account. If you
            later find and return the book in good condition within 30 days, a refund for the
            book's cost may be considered. The issue of refund will at the sole discretion of
            the management of BOOK KULTURE.
          </p>

          <p>2.5. Late fees: Late fees resulting from overdue book returns are also nonrefundable.
          </p>
        </div>

        <div className="point2">
          <h1 style={{fontFamily: "monserrat", fontWeight: "bold", fontSize: "25px"}}>3. Cancellation of Subscription</h1>
          <p>3.1. Subscription Cancellation: If you wish to cancel your subscription, please
            refer to our Subscription Cancellation policy mentioned in terms and condition.
          </p>
          <p>
            3.2. Cancellation Period: If you wish to cancel your BOOK KULTURE
            subscription you may do so within subscription period, starting from the date of
            your initial subscription or renewal. Subscription fees are not refundable.
          </p>
        </div>

        <div className="point2">
          <h1>4. Replacement Process </h1>
          <p>4.1. Contact Customer Support: If you encounter an issue with a rented book and
            believe you are eligible for a replacement, please contact our customer support
            team at [info@bookkulture.com] within 30 days of receiving the book. </p>
          <p>
            4.2. Provide Information: To process your replacement request, our customer
            support team may request information such as your order number, details about
            the issue, and photographic evidence (if applicable).
          </p>

          <p>4.3. Evaluation: BOOK KULTURE will evaluate your replacement request based
            on the information provided. We aim to respond to replacement requests within 3
            (three) business days.

          </p>

          <p>4.4. Replacement Decision: If your replacement request is approved, BOOK
            KULTURE will issue the demanded book with reference to the
            scenarios/circumstances mentioned in clause (2.2). Replacement will be
            processed within 3 (three) business days from the date of evaluation mention in
            clause (4.3).
          </p>

        </div>

        <div className="point2">
          <p>5. Late Fees</p>
          <p>5.1. Late Fees: Late fees incurred due to delayed book returns are not eligible for
            refunds. </p>
        </div>

        <div className="point3">
          <h1> 6. Return Policy and Procedure</h1>
          <p>6.1. All books shall be returned once the subscription ends [Kindly refer
            Subscription paragraph of Terms and Conditions].
          </p>
          <p>
            6.2. A person can hold only 6 books at one time (e.g., If a user returns 3 books
            out 6 books, he can order next 3 books in place of the former ordered books).

          </p>
          <p>
            6.3. Each book can be held by the user up to 30 days only after which the
            book/books shall have to be returned. As this provision is to ensure maximum
            circulation amongst the BOOK KULTURE user community.
          </p>

        </div>

        <div className="point1">
          <h1 style={{fontFamily: "monserrat", fontWeight: "bold", fontSize: "25px"}}>7. Contact Us</h1>
          <p>If you have any questions or concerns about our Book Replacement Policy or
            need assistance with a Replacement request, please contact us at
            [info@bookkulture.com].  </p>
        </div>


        <div className="point1">
          <h1>8. Changes to this Replacement and Return Policy </h1>
          <p>BOOK KULTURE may update this Book Replacement Policy to reflect changes
            in our practices or legal requirements. We will notify you of any significant
            changes by email or through our platform. Please review this policy periodically
            for updates.
            Thank you for choosing BOOK KULTURE to embark on your literary journey.
            We are committed to ensuring a satisfying book rental experience for our valued
            subscribers </p>
        </div>
      </div>

    </div>
  </>
}

export default Refund;