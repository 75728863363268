import './FAQ.css'
import React, { useState } from 'react';
import FAQ from './FaqData';

function FAQs() {
    const [faqs, setfaqs] = useState([
        {
            key:"1",
            question: "Who are we?",
            answer: "Book Kulture is a children's library service that help in physical, emotional, and social development of children. We offer a unique assistance which plays a important part in comprehensive growth for kids. Our program includes library services for children two years and above. The books are designed to improve co-ordination and reinforces good social skills and reading skills.",
            open:true
        },
        {
            key:"2",
            question: "Is reading important for children in their early age?",
            answer: "YES! Reading helps to expand the mind and give us more ideas. Reading has been proven to keep our minds young, healthy and sharp.",
            open:true
        },
        {
            key:"3",
            question: "Will my child read books? I doubt that because he has never read books before? ",
            answer: " If your child has never read books then this would be a great platform to make your child a good reader. This would be a perfect beginning for your child.",
            open: true
        },
        {
            key:"4",
            question: "My child reads books, but how will your library help our child to be a good reader?",
            answer: "We are very pleased that your child reads books. We will guide him on which books to read and how to increase vocabulary with the help of reading.",
            open: true
        },
       
    ]);


    const toggleFAQ = index =>{
        setfaqs(faqs.map((faq ,i) => {
            if(i === index){
                faq.open = !faq.open;
            } else{
                // faq.open=false;
            }
            return faq;

        }))
    }
    return <>
        <div className='Appd'>
            <header>FAQ Page</header>

            <div className='faqs' >
                {faqs.map((faq, i) => (
                    <div className='faq' key={faq.key}>
                       <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ}></FAQ>
                    </div>




                ))}






            </div>

          



        </div>


    </>
}

export default FAQs;