import React, { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { currentAdmin, currentAdminDummy } from "../../functions/auth"; // Assuming you have a function to check the current admin

function AdminRoute({ children }) {
  const [ok, setOk] = useState(false);
  const { user } = useSelector((state) => ({ ...state }));

  useLayoutEffect(() => {
    if (user && user.token) {
      // currentAdminDummy(user.token)
      currentAdmin(user.token)
        .then((res) => {
          // console.log("CURRENT ADMIN RES", res);
          setOk(true);
        })
        .catch((err) => {
          console.log("ADMIN ROUTE ERR", err);
          setOk(false);
        });
    }
  }, [user]);

 

  if (!ok) {
    return <div>Loading...</div>; // Render a loading state until ok is determined
  }

  return <>{children}</>;
}

export default AdminRoute;
