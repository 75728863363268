import React, {useState, useEffect} from 'react'
import AdminNav from '../../../components/nav/AdminNav';
import  {toast} from 'react-toastify'
import { useSelector } from 'react-redux'
import {createCategory, getCategories, removeCategory} from "../../../functions/category";
import { Link } from 'react-router-dom';
import CategoryForm from '../../../components/forms/CategoryForm';


const CategoryCreate = () => {

    const {user} = useSelector((state) => ({...state}));

    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        loadCategories();
    }, [])

    const loadCategories = () => {
        getCategories()
        .then((c) => setCategories(c.data));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);
        createCategory({name}, user.token)
        .then((res) => {
            setLoading(false);
            setName("");
            toast.success(`"${res.data.name}"is created`);
            loadCategories();
        })
        .catch((err) =>{
            console.log(err);
            setLoading(false);
            if(err.response.status === 400) toast.error(err.response.data);
        })

    }

    const handleRemove = async(slug) => {
        if(window.confirm("Delete?")){
            setLoading(true);
            removeCategory(slug, user.token)
                .then((res) => {
                    setLoading(false);
                    toast.error(`${res.data.name} deleted`);
                    loadCategories();
                })
                .catch((err) => {
                    if(err.response.status === 400){
                        setLoading(false);
                        toast.error(err.response.data);
                    }
                })
        }
    }

    const handleEdit = () => {
        //
    }

    const handleSearchChange = (e) => {
        e.preventDefault();
        setKeyword(e.target.value.toLowerCase());
    };

    const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);


  return (
    <>
        <AdminNav/>
        {loading ? (
            <h1>Loading</h1>
        ) : (
            <h1>CreateCategory Page</h1>
        )}
        
        {/* {categoryForm()} */}
        
        <CategoryForm
            handleSubmit={handleSubmit}
            name={name}
            setName={setName}
        />

        <input type="search" placeholder='Filter' value={keyword} onChange={handleSearchChange}/>


        {categories.filter(searched(keyword)).map((c) => (
            <div key={c._id}>
            {c.name}
            <button onClick={() => handleRemove(c.slug)}>Delete</button>
            <Link to={`/admin/category/${c.slug}`}>
                <button onClick={handleEdit}>Edit</button>
            </Link>
            
            </div>
        ))}
    </>
  )
}

export default CategoryCreate;
