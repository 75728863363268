import React, {useState, useEffect} from 'react'
import AdminNav from '../../../components/nav/AdminNav';
import  {toast} from 'react-toastify'
import { useSelector } from 'react-redux'
import {createBook, removeBook} from "../../../functions/book";
import { getCategories} from "../../../functions/category";
import { getAuthors} from "../../../functions/author";
import BookCreateForm from '../../../components/forms/BookCreateForm';
import FileUpload from '../../../components/forms/FileUpload';


const initialState = {
    title: "",
    description: "",
    categories: [],
    category: "",
    authors: [],
    author: "",
    quantity: "",
    sold: Number,
    images:[],
    barcode: "",
    age: "",
    language: "English",
}


const CreateBook = () => {

    

    const {user} = useSelector((state) => ({...state}));

    const [values, setValues] = useState(initialState);


    const [loading, setLoading] = useState(false);
    // const [categories, setCategories] = useState([]);
    const [keyword, setKeyword] = useState("");



    useEffect(() => {
        loadCategories();
    }, [])

    const loadCategories = () => {

        getCategories()
        .then((c) => {
        setValues((prevValues) => ({ ...prevValues, categories: c.data, category: c.data[0]._id }));

        console.log("Updated Categories:", c.data);
        })
        .catch((error) => {
        toast.error(error.response.data.err)
        console.log(error);
        });

        getAuthors()
        .then((c) => {
            setValues((prevValues) => ({ ...prevValues, authors: c.data, author: c.data[0]._id }));
            console.log("Updated Authors:", c.data);
          })
          .catch((error) => {
            // Handle error
            toast.error(error.response.data.err)
            console.log(error);
          });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);
        createBook(values, user.token)
        .then((res) => {
            setLoading(false);
            toast.success(`"${res.data.title}"is created`);
            
            loadCategories();
        })
        .catch((err) =>{
            console.log(err);
            setLoading(false);
            if(err.response.status === 400) toast.error(err.response.data);
        })

    }

    const handleChange = (e) => {
        
        setValues({...values, [e.target.name] : e.target.value})
        console.log("Values handleChange-----",values);
    }

    const handleCategoryChange = (e) => {
        e.preventDefault();
        console.log("CLICKED CATEGORY", e.target.value);
        setValues({ ...values, category: e.target.value });
        console.log("Values handleChange-----",values);
    };

    const handleChangeAuthor = (e) => {
        e.preventDefault();
        console.log("CLICKED CATEGORY", e.target.value);
        setValues({ ...values, author: e.target.value });
        console.log("Values handleChange-----",values);
    };

    const handleSearchChange = (e) => {
        e.preventDefault();
        setKeyword(e.target.value.toLowerCase());
    };

    const searched = (keyword) => (c) => c.title.toLowerCase().includes(keyword);

  return (
    <>
        <AdminNav/>
        {loading ? (
            <h1>Loading</h1>
        ) : (
            <h1>CreateBook Page</h1>
        )}
        
        <BookCreateForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            values={values}
            setValues={setValues}
            handleCategoryChange={handleCategoryChange}
            handleChangeAuthor={handleChangeAuthor}
        />

        <FileUpload
            values={values}
            setValues={setValues}
            setLoading={setLoading}
        />

        <input type="search" placeholder='Filter' value={keyword} onChange={handleSearchChange}/>
    </>
  )
}

export default CreateBook;
