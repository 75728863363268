import { useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';

function Click({ openpopup, onClose, selectedBook }) {
  const [selectedimg, setSelectedimg] = useState(selectedBook?.images[0]?.url);
  const logoUrl = '/bookkulturelogo.png';
  console.log(selectedBook)
  
  return (
    <>
      {openpopup && (
        <div className="mainbgdiv">
          <CloseCircleOutlined
            className="cancel"
            style={{ fontSize: '60px', color: 'red' }}
            onClick={onClose}
          />

          <div className="mainbgdiv_content">
            <div className="mainbgdiv_productdetails">
              <div className="mainbgdiv_productimg">
                {selectedBook?.images?.length ? (
                  <img className="mainimg" src={(selectedBook.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + selectedBook.images[0].public_id + '.jpg' : selectedBook.images[0].url)} alt="Product" />
                ) : (
                  <img className="mainimg" src={logoUrl} alt="Product" />
                )}
                <div className="imglist">
                  {selectedBook.images?.map((image, index) => (
                    <img
                      key={index}
                      src={(selectedBook.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + selectedBook.images[0].public_id + '.jpg' : selectedBook.images[0].url)}
                      onClick={() => setSelectedimg(image)}
                      alt="Product"
                    />
                  ))}
                </div>
              </div>
              <div className="mainbgdiv_productdes">
                <h1>{selectedBook?.title}</h1>
                <p>{selectedBook?.description}</p>
                {selectedBook.quantity > 0 ? (
                  <h2 style={{ color: "green" }}>Available</h2>
                ) : (
                  <h2 style={{ color: "red" }}>Unavailable</h2>
                )}

                <h2>Category: {selectedBook.category.name}</h2>
                <h2>Age: {selectedBook.age}</h2>
                {/* <button className="des_addtocart-button">
                  <ShoppingCartOutlined
                    classID="carticon"
                    style={{ fontSize: '32px', marginRight: "15px", marginBottom: "20px" }}
                  />
                  <span className="button-text">Add to cart</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Click;
