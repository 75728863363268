import Carousel from "react-elastic-carousel";
import Item from "./Item";
import "./Reviews.css";
import React from "react";
import sheet from '../../asset/ReviewImages/reviewsheet.png'
import clientimg from '../../asset/ReviewImages/ClientImage.png'
import client from "../../asset/ReviewImages/clientimageboy.png"
import client2 from "../../asset/ReviewImages/clientimagegirl2.png"
import clent3 from "../../asset/ReviewImages/ClientImagegirl.png"

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 3 },
];

function Reviews() {
    return (
        <>
            <h1 className="ReviewStart" style={{ textAlign: "center" }}>Reviews</h1>
            <div className="App">
                <Carousel breakPoints={breakPoints}>
                    <Item className="item1">
                        <img className="sheet-bgimg" src={sheet} alt="profilephoto" />
                        <div className="reviewcontent">
                            <h1>“</h1>
                            <div>
                                <p>Book Kulture has a wide range of books for my 
                                    11 year old boy who is a voracious reader.Books
                                     are in good condition and most importantly they have 
                                     free home delivery twice in a month.It's a great initiative 
                                     to inculcate reading habit in children .Thank you Book Kulture for being a 
                                     part in children's development. </p>

                            </div>
                            <div className="identity">
                                <img  src={clientimg} alt="profilephoto" />
                                <p>Shweta Raje</p>
                            </div>
                        </div>
                    </Item>
                    <Item className="item1"><img className="sheet-bgimg" src={sheet} alt="profilephoto" />
                        <div className="reviewcontent">
                            <h1>“</h1>
                            <div>
                                <p>
                                    It's an awesome library especially for kids to enrich their reading habits..It's the best gift one can give to their young ones!!!!!
                                    My daughter is 4 years and we have reads so many books.
                                    Very nice place with very good collection of books 📚👌 I would highly recommend to many friends!!!

                                </p>

                            </div>
                            <div className="identity">
                                <img className="clientphoto1" src={client2} alt="profilephoto" />
                                <p>Sona Patel</p>
                            </div>
                        </div>
                    </Item>
                    <Item className="item1">
                        <img className="sheet-bgimg" src={sheet} alt="profilephoto" />

                        <div className="reviewcontent">
                            <h1>“</h1>
                            <div>
                                <p>I am happy tht i found this place, my daughter is very fond of reading books, she is very happy and always eagerly to go to book culture after she finishes reading set of books . Thank you book kulture for opening such a beautiful Library..
                                </p>

                            </div>
                            <div className="identity">
                                <img className="clientphoto2"  src={clent3} alt="profilephoto" />
                                <p>Shushmita Vijay</p>
                            </div>
                        </div>
                    </Item>
                    <Item className="item1">
                        <img className="sheet-bgimg" src={sheet} alt="profilephoto" />

                        <div className="reviewcontent">
                            <h1>“</h1>
                            <div>
                                <p>Awesome place. Even though they provide home delivery service we have mostly
                                    visited Book Kulture so that kids have a feel of choosing the books they want
                                    to read. My 6 & 4 year old kids just love visiting here.Seems like a small
                                     space from outside but has big collection of books especially for kids. 
                                     
                                </p>

                            </div>
                            <div className="identity">
                                <img className="clientphoto1"  src={client} alt="profilephoto" />
                                <p>Harish Anand</p>
                            </div>
                        </div>
                    </Item>
                    <Item className="item1">
                        <img className="sheet-bgimg" src={sheet} alt="profilephoto" />

                        <div className="reviewcontent">
                            <h1>“</h1>
                            <div>
                                <p>A big thank you to Book Kulture for providing such a vast collection of books for my 10yrs kid. He is enjoying reading books. I am really very happy with the collection they have also the quality of the books is very well maintained. I wish a very best luck to Book Kulture for their future endeavors.
                                    Highly Recommended.</p>

                            </div>
                            <div className="identity">
                                <img src={clientimg} alt="profilephoto" />
                                <p>Sarika Mangalurkar</p>
                            </div>
                        </div>

                    </Item>
                    <Item className="item1">
                        <img className="sheet-bgimg" src={sheet} alt="profilephoto" />
                        <div className="reviewcontent">
                            <h1>“</h1>
                            <div>
                                <p>As someone said : the best gift one can give are books...
                                    Great to have a library near home with such a good collection.We use their services
                                    for my 4 year old son and now it's a habit that my son doesn't want to sleep without completing a book every night.I feel this is a journey.And hope he turns into a voracious reader!</p>

                            </div>
                            <div className="identity">
                                <img className="clientphoto1"  src={client} alt="profilephoto" />
                                <p>Vivek Saha</p>
                            </div>
                        </div>

                    </Item>
                    <Item className="item1">
                        <img className="sheet-bgimg" src={sheet} alt="profilephoto" />
                        <div className="reviewcontent">
                            <h1>“</h1>
                            <div className="review-text">
                                <p>Thank you to Book
                                    Kulture for providing me such a vast collection of
                                    books for my 8yrs old bookworm. I am njoying
                                    Book Kulture services since Feb 2022.I am really very
                                    happy with the collection they have.Have recommended this to many of my friends.
                                    </p>

                            </div>
                            <div className="identity">
                            <img className="clientphoto2"  src={clent3} alt="profilephoto" />
                                <p>Ashlesha Damle</p>
                            </div>
                        </div>

                    </Item>
                    <Item className="item1">
                        <img className="sheet-bgimg" src={sheet} alt="profilephoto" />

                        <div className="reviewcontent">
                            <h1>“</h1>
                            <div className="review-text">
                                <p>Wonderful concept.Specially a must in an era of
                                    electronics media.They have an option of home delivery,
                                    still my daughter prefers to go in person and select books. Daughter has discovered her profound love for reading ,
                                    she is happy to read.And I m happy that she's away from the electronic craze



                                </p>

                            </div>
                            <div className="identity">
                                <img src={clientimg} alt="profilephoto" />
                                <p>Anupam Savle</p>
                            </div>
                        </div>

                    </Item>


                    <Item className="item1">
                        <img className="sheet-bgimg" src={sheet} alt="profilephoto" />

                        <div className="reviewcontent">
                            <h1>“</h1>
                            <div className="review-text">
                                <p>Book kulture is the blessing for my son in these tough times.
                                    It is giving options to be off screen .
                                    They have good collection of books. I would recommend Book Kulture.
                                    Really appreciate
                                    how they deliver your desire books in this pandemic too.
                                 



                                </p>

                            </div>
                            <div className="identity">
                            <img className="clientphoto1"  src={client2} alt="profilephoto" />
                                <p className="pooja">Pooja Agarwal</p>
                            </div>
                        </div>

                    </Item>
                    <Item className="item1">
                        <img className="sheet-bgimg" src={sheet} alt="profilephoto" />

                        <div className="reviewcontent">
                            <h1>“</h1>
                            <div className="review-text">
                                <p>Thank you Book Kulture for such a wonderful concept 
                                    it helps kids in many ways increased love for reading 
                                    books of different author, vocabulary, imagination power,
                                    better writer, orator.....home delivery is other gesture which
                                     I really appreciate,thank you for always delivering in time.👍


                                </p>

                            </div>
                            <div className="identity">
                                <img src={clientimg} alt="profilephoto" />
                                <p >Neha Dholakia</p>
                            </div>
                        </div>

                    </Item>

                </Carousel>
            </div>
        </>
    );
}
export default Reviews;