import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { currentUser } from './functions/auth';
import { auth } from './firebase';
import { toast } from 'react-toastify';

import "react-toastify/dist/ReactToastify.css"


import Home from './pages/Home';
import ShopHome from './pages/ShopHome'
import Register from './pages/auth/Register';
import CompleteRegistration from './pages/auth/CompleteRegistration';
import ForgotPassword from './pages/auth/ForgotPassword';
import UserProfile from './pages/subscriber/UserProfile';

import AdminRoute from './components/routes/AdminRoute';
import Login from './pages/auth/Login'
import CategoryCreate from './pages/admin/category/CategoryCreate';
import CategoryUpdate from './pages/admin/category/CategoryUpdate';
import CreateAuthor from './pages/admin/author/CreateAuthor';
import AuthorUpdate from './pages/admin/author/AuthorUpdate';
import CreateBook from './pages/admin/book/CreateBook';
import UpdateBook from './pages/admin/book/UpdateBook';
import Books from './pages/subscriber/Books/Books';
import Cart from './pages/subscriber/Cart/Cart';
import SubscriberForm from './components/forms/SubscriberForm';
import ShopProductCarousel from './pages/Shop/ShopProductCarousel';
import DropdownAuthor from './pages/Shop/DropdownAuthor';
import MainpageCarousel from './pages/Shop/MainpageCarousel';
import ShoppingCart from './pages/Shop/ShoppingCart';
import MediumSubscription from './pages/Subscription/MediumSubscription';
import Click from './pages/Shop/Click';
import ParentComponent from './pages/Shop/ParentComponent';
import AboutBk from './pages/Main/AboutBk';
import RouteLayout from './components/UserDashboard/Routes/RouteLayout';
import Userdetail from './pages/User/Detail/Userdetail';
import Address from './pages/User/Address/Address';
import UserPlan from './pages/User/UserPlan/UserPlan';
import OrderHistory from './pages/User/OrderHistory/orderHistory';
import DashboardLayout from './components/DashBoard_BK/Approutes/DashboardLayout';
import DashBoard from './pages/DashBoard/Dashboard';
import Orders from './pages/DashBoard/Orders/Orders';
import Users from './pages/DashBoard/Users/Users';
import DownloadBooks from './pages/DownloadBooks';

import TermsCondition from './pages/Policies/TermsCondition';
import Privacy from './pages/Policies/Privacy';
import Refund from './pages/Policies/Refund';
import SubscriptionBk from './pages/Main/SubscriptionBk';

import OrderBooks from './pages/DashBoard/orderBooks/OrderBooks';
import ChangeOrder from './pages/DashBoard/ChangeOrder/ChangeOrder';

const App = () => {

  // const router =createBrowserRouter([
  //   {path:'/', 
  //   element:<RootLayout/> ,
  //   children:[
  //     {path:'/', element:<Home/>},
  //     {path:'/user' ,element: <User/>},
  //   ],
  // },

  // ]);

  const dispatch = useDispatch();

  //to check firebase auth state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id
              },
            });

          }).catch((err) => {
            // toast.error('An Error Occured')
            console.log(err, "onAuthStateChanged")
          });
      }
    });

    //cleanup
    return () => unsubscribe();
  }, [dispatch]);

  return (

    <div>
      <BrowserRouter>
        <ToastContainer />
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="admin/dashboard" element={
            <AdminRoute>
              <DashboardLayout>
                <DashBoard />
              </DashboardLayout>
            </AdminRoute>} />
          <Route path="/books1" element={<DashboardLayout></DashboardLayout>} />
          <Route path="/users" element={<AdminRoute><Users></Users></AdminRoute>} />
          <Route path="/downloadbooks" element={<AdminRoute><DownloadBooks /></AdminRoute>} />
          <Route path="/orders" element={<AdminRoute><Orders /></AdminRoute>} />
          <Route path="/orderBooks" element={<AdminRoute><OrderBooks /></AdminRoute>} />
          <Route path="/changeOrder" element={<AdminRoute><ChangeOrder /></AdminRoute>} />
          <Route path='/details' element={<RouteLayout><Userdetail></Userdetail></RouteLayout>} />
          <Route path='/orderhistory' element={<RouteLayout><OrderHistory></OrderHistory></RouteLayout>} />
          <Route path='/add' element={<RouteLayout><Address></Address></RouteLayout>} />
          <Route path='/plan' element={<RouteLayout><UserPlan></UserPlan></RouteLayout>} />
          <Route path='/terms' element={<TermsCondition />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/refund' element={<Refund />} />
          <Route path='/explore' element={<ParentComponent />} />
          <Route path='/medium' element={<MediumSubscription />}></Route>
          <Route path='/about' element={<AboutBk />} />
          <Route path='/subscription' element={<SubscriptionBk></SubscriptionBk>} />
          <Route path='/cart' element={<ShoppingCart />} />
          <Route path='/dropdown' element={<DropdownAuthor></DropdownAuthor>} />
          <Route path='/show' element={<Click></Click>}></Route>
          <Route path='/main' element={<MainpageCarousel></MainpageCarousel>} />
          <Route path='/product' element={<ShopProductCarousel />} />
          <Route path='/login' element={<Login />}></Route>
          <Route path="register" element={<Register />} />
          <Route path="register/completeregistration" element={<CompleteRegistration />} />
          <Route path="forgot/password" element={<ForgotPassword />} />
          <Route path='/library' element={<ShopHome />} />
          <Route path='/subscriberform' element={<SubscriberForm />} />
          <Route path='/click' element={<Click></Click>} />
          <Route path='/user/profile' element={<UserProfile />} />
          <Route path='/user/book' element={<Books />} />
          <Route path='/user/cart' element={<Cart />} />
          <Route path="admin/category" element={
            <AdminRoute>
              <CategoryCreate />
            </AdminRoute>} />
          <Route path="admin/category/:slug" element={
            <AdminRoute>
              <CategoryUpdate />
            </AdminRoute>} />
          <Route path="admin/author" element={
            <AdminRoute>
              <CreateAuthor />
            </AdminRoute>} />
          <Route path="admin/author/:slug" element={
            <AdminRoute>
              <AuthorUpdate />
            </AdminRoute>} />
          <Route path="admin/book" element={
            <AdminRoute>
              <CreateBook />
            </AdminRoute>} />
          <Route path="admin/book1/:slug" element={
            <UpdateBook />
          } />
        </Routes>
      </BrowserRouter>


    </div>
  )
}

export default App;

