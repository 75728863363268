import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import './DropdownAuthor.css';
import { getAuthors } from "../../functions/author";
import { getCategories } from "../../functions/category";
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function MainDropdown({ onSearchKeyword, onSelectedAuthor, onFilterLabel, onAgeFilter }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [categories, setCategories] = useState({ value: '', label: '' });
  const [authorss, setAuthorss] = useState({ value: '', label: '' });
  const [age, setAge] = useState({ value: '', label: '' });
  const [opensearchbar, setOpensearchbar] = useState(false);

  const ageOptions = [
    { value: "7 to 12", label: "7 to 12" },
    { value: "10 to 15", label: "10 to 15" },
    { value: "10 to 18", label: "10 to 18" },
    { value: "8 to 14", label: "8 to 14" },
    { value: "6 to 14", label: "6 to 14" },
    { value: "All Age Group", label: "All Age Group" },
    { value: "Not Specify", label: "Not Specify" },
    { value: "14 and Above", label: "14 and Above" },
    { value: "12 to 18", label: "12 to 18" },
    { value: "18+", label: "18+" }
  ];

  useEffect(() => {
    loadCategories();
  }, [])

  const opensearch = () => {
    setOpensearchbar(!opensearchbar)
  }

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    // setKeyword(value);
    onSearchKeyword(value);
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    const filterLabel = selectedOption.label;
    
    if(filterLabel.toLowerCase() !== 'age'){
      onFilterLabel(filterLabel);
      setShowOptions(true);
    }else{
      setShowOptions(true);
    }
  };

  const handleOptionChangeSelectedOption = (selectedOption1) => {
    setSelectedOption1(selectedOption1);
    // setShowOptions(true);
    console.log(selectedOption)
    if(selectedOption.label.toLowerCase() !== 'age'){
      const selectedAuthorId = selectedOption1.value;
      const selectedAuthorName = selectedOption1.label;
      onSelectedAuthor(selectedAuthorId, selectedAuthorName);
    }else{
      console.log('asd', selectedOption1);
      onAgeFilter(selectedOption1.value)
    }
    
  }

  const loadCategories = () => {
    getCategories()
      .then((res) => {
        const data = res.data
        const categoryOptions = data.map((category) => ({
          value: category._id, // Assuming the category object has an '_id' property
          label: category.name, // Assuming the category object has a 'name' property
        }));
        setCategories(categoryOptions);
      })
      .catch((err) => {
        toast.error(err)
        console.log(err);
      })

    getAuthors()
      .then((res) => {
        const data = res.data;
        const authorOptions = data.map((author) => ({
          value: author._id, // Assuming the author object has an '_id' property
          label: author.name, // Assuming the author object has a 'name' property
        }));
        setAuthorss(authorOptions)
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      })

  }



  const handleDropdownOpen = () => {
    setShowOptions(false);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,

      border: "none",
      minHeight: "40px",
      height: "20px",
      boxShadow: "none"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#666"
    }),
    option: (provided) => ({
      ...provided,
      color: 'red',
      fontSize: '14px',
      fontFamily: 'Montserrat'
    }),
  };
  const customStyles2 = {
    control: (provided) => ({
      ...provided,

      border: "none",
      minHeight: "40px",
      height: "20px",
      boxShadow: "none",

    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#666",
      fontFamily: "Warung Kopi"
    }),
    option: (provided) => ({
      ...provided,
      color: 'red',
      fontSize: '14px',
      fontFamily: 'Montserrat'
    }),
  };

  const [width, setWidth] = useState(0);
  const ShopProductCarousel_SliderWrapper = useRef();

  return (
    <div className="app">
      <div className="iconsearches">
        <FontAwesomeIcon className="searchicong" style={{ color: "red", marginRight: "10px" }} icon={faSearch} onClick={opensearch} />
        {
          <div className='searching'>
            <input
              type='text'
              name='search'
              className={` kk ${opensearch ? 'openup' : ''}`}
              id='scrh'
              placeholder='Search'
              onChange={handleSearchChange}
            ></input>
          </div>
        }
      </div>
      <div className="dropdown-container">
        <Select
          options={[
            { value: authorss, label: "Author" },
            { value: categories, label: "Genre" },
            { value: age, label: "Age" },
          ]}
          styles={customStyles}
          placeholder="Filter"
          value={selectedOption}
          onChange={handleOptionChange}
          onMenuOpen={handleDropdownOpen}
          isSearchable={false}
        />
        {showOptions && (
          <Select
            styles={customStyles2}
            options={selectedOption.label === "Author" ? authorss : (selectedOption.label === "Genre" ? categories : ageOptions)}
            // options={selectedOption.label === "Author" ? authorss : categories}
            value={selectedOption1}
            onChange={handleOptionChangeSelectedOption}
            isSearchable
          />
        )}

        
      </div>
    </div>
  );
}

export default MainDropdown;





