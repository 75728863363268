import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

export default function DownloadBooks() {
  const [mPiyush, setmPiyush] = useState('');
  const [mAdmin, setmAdmin] = useState('');
  const [mTotal, setmTotal] = useState('');
  const [year, setYear] = useState('');

  const { user } = useSelector((state) => ({ ...state }));
  const authtoken = user?.token;
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.role !== 'admin') {
      navigate('/library');
    }
  }, [user, navigate]);

  const downloadFile = async (url) => {
    try {
      const response = await fetch(url, {
        headers: {
          authtoken
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'downloadedFile'; // you might want to get this from response headers
      link.click();
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  };

  return (
    <div>
      <button onClick={() => downloadFile(`${process.env.REACT_APP_API}/books/admin/downloadbooks`)}>DownloadBooks</button>
      <button onClick={() => downloadFile(`${process.env.REACT_APP_API}/admin/download-subscriber`)}>DownloadSubscribers</button>
      <div>
        <button onClick={() => downloadFile(`${process.env.REACT_APP_API}/admin/piyush-month-subscriber/${mPiyush}/${year}`)}>Piyush Month Subscriber</button>
        <input
          type="text"
          value={mPiyush}
          onChange={(e) => setmPiyush(e.target.value)}
          placeholder="Enter month for Piyush"
        />
      </div>
      <div>
        <button onClick={() => downloadFile(`${process.env.REACT_APP_API}/admin/admin-month-subscriber/${mAdmin}/${year}`)}>Admin Month Subscriber</button>
        <input
          type="text"
          value={mAdmin}
          onChange={(e) => setmAdmin(e.target.value)}
          placeholder="Enter month for Admin"
        />
      </div>
      <div>
        <button onClick={() => downloadFile(`${process.env.REACT_APP_API}/admin/total-month-subscriber/${mTotal}/${year}`)}>Total Month Subscriber</button>
        <input
          type="text"
          value={mTotal}
          onChange={(e) => setmTotal(e.target.value)}
          placeholder="Enter total month"
        />
      </div>
      <input
          type="text"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          placeholder="Enter the year"
        />
    </div>
  );
}
