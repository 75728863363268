import React from 'react'
import './BookUpdateForm.css'

const BookUpdateForm = ({ handleSubmit, handleChange, values, setValues, handleCategoryChange, handleChangeAuthor }) => {


    const {
        title,
        // slug,
        description,
        category,
        categories,
        author,
        authors,
        quantity,
        sold,
        // image,
        barcode,
        age,
        language
    } = values


    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className='form-container'>
                    <div className='form-box'>
                        <div>
                            <label style={{marginBottom: '23px', paddingRight: '10px'}}>Title:</label>
                            <input
                                className="bookname"
                                name="title"
                                type="text"
                                value={title}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Description:</label>
                            <textarea
                                className="adddescription"
                                name="description"
                                value={description}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>
                        <div>
                            <label>Category:</label>
                            <select
                                className="bookscategory"
                                name="category"
                                value={category.name}
                                onChange={handleCategoryChange}
                            >
                                {categories.map((c) => (
                                    <option key={c._id} value={c._id}>
                                        {c.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label>Author:</label>
                            <select
                                className="authordropdown"
                                name="author"
                                onChange={handleChangeAuthor}
                                value={author}
                            >
                                {authors.map((c) => (
                                    <option key={c._id} value={c._id}>
                                        {c.name}
                                    </option>
                                ))}
                            </select>

                        </div>
                        <div>
                            <label>Quantity:</label>
                            <input
                                className="addquantity"
                                name="quantity"
                                type="number"
                                value={quantity}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Sold:</label>
                            <input
                                className='soldUpdate'
                                name="sold"
                                type="number"
                                value={sold}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Barcode:</label>
                            <input
                                className="addbookid"
                                name="barcode"
                                type="number"
                                value={barcode}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Age:</label>
                            <select value={age}
                                onChange={handleChange}
                                name='age'
                                className="userage"
                            >
                                <option value="7 to 12">7 to 12</option>
                                <option value="10 to 15">10 to 15</option>
                                <option value="10 to 18">10 to 18</option>
                                <option value="8 to 14">8 to 14</option>
                                <option value="6 to 14">6 to 14</option>
                                <option value="All Age Group">All Age Group</option>
                                <option value="Not Specify">Not Specify</option>
                                <option value="14 and Above">14 and Above</option>
                                <option value="12 to 18">12 to 18</option>
                                <option value="18+">18+</option>
                            </select>
                        </div>
                        <div>
                            <label>Language:</label>
                            <select value={language}
                                onChange={handleChange}
                                name='language'
                            >
                                <option value="English">English</option>
                                <option value="Hindi">Hindi</option>
                                <option value="Marathi">Marathi</option>
                            </select>
                        </div>
                        <button className='submit-btn' type="submit">Submit</button>
                    </div>
                </div>

            </form>
        </>
    )
}

export default BookUpdateForm
