import React from 'react'
import {Link} from 'react-router-dom'

const UserNav = ({cartItems, totalBooksInCart}) => {

  return (
    <nav>
        <ul>
            <li><Link to="/user/dashboard">Dashboard</Link></li>

            <li><Link to="/user/book">Books</Link></li>

            <li><Link to="/user/password">Password</Link></li>

            <li><Link to="/user/cart">Cart </Link>{cartItems}  {totalBooksInCart} </li>
        </ul>

    </nav>
  )
}

export default UserNav;
