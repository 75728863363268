import axios from "axios";
import axiosInstance  from './services/interceptor';

export const getCartList=async (authtoken) =>
    await axiosInstance.post(`${process.env.REACT_APP_API}/cart/list`,
    {},{
        headers: {
            authtoken,
        }
    });

export const addToCart=async (cart, authtoken) =>
    await axiosInstance.post(`${process.env.REACT_APP_API}/cart`,cart,{
        headers: {
            authtoken,
        },
    });

export const removeBookCart=async (id, authtoken) =>{
    await axiosInstance.delete(`${process.env.REACT_APP_API}/cart/remove`, {
        headers: {
          authtoken: authtoken,
        },
        data: {
          bookId: id,
        },
      });
    } 
    