import axios from "axios";
import axiosInstance  from './services/interceptor';

export const payment = async (authtoken, amount) =>{
    return await axiosInstance.post(`${process.env.REACT_APP_API}/orders`, 
      {amount}, {
      headers:{
        authtoken,
      }
    });
  };

  export const verify = async (authtoken, response) =>{
    return await axiosInstance.post(`${process.env.REACT_APP_API}/orders`, 
      {response}, {
      headers:{
        authtoken,
      }
    });
  };