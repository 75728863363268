import { useState } from 'react';
import Click from './Click';
import Explore from './ExploreGenre';

function ParentComponent() {
  const [openpopup, setOpenpopup] = useState(false); // Lift the state to the parent component

  const handlePopupToggle = () => {
    setOpenpopup((prevState) => !prevState);
  };

  return (
    <>
      <Explore onImageClick={() => setOpenpopup(true)} /> {/* Pass a function to open the popup */}
      <Click openpopup={openpopup} onClose={handlePopupToggle} /> {/* Pass the openpopup state and the close function */}
    </>
  );
}

export default ParentComponent;
