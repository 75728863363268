import React from "react";
import './FAQ.css'

function FaqData({ faq, index ,toggleFAQ}) {
    return <>
        <div className={"faq" + (faq.open ? 'open' : "")}

            key={index}
            onClick={() => toggleFAQ(index)}
        >
            <div className="faq_questions">
                {faq.question}
            </div>
            <div className="faq_answers">
                {faq.answer}
            </div>


        </div>
    </>
}


export default FaqData;