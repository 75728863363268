import firebase from "../../../firebase"
import { Menu } from "antd"
import { UserOutlined, EnvironmentFilled, WalletFilled, LogoutOutlined, ClockCircleOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import "./Sidebar.css"
import { currentSubscriber } from "../../../functions/subscriber";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux'
import useLogoutService from "../../../functions/services/logoutService"

function Sidebar() {

  const [subscriberDetails, setSubscriberDetails] = useState([]);
  const { user } = useSelector((state) => ({ ...state }));
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { logout } = useLogoutService();

  useEffect(() => {
    getSubscriberDetails();
  }, [])

  const getSubscriberDetails = () => {
    if (user) {
      if (user.role === 'subscriber') {
        currentSubscriber(user.token)
          .then((res) => {
            console.log(res);
            setSubscriberDetails(res.data);
          })
          .catch((err) => {
            console.log(err);
          })
      }
    }
  }

  const logoutHandle = () => {
    logout('hi');
    // firebase.auth().signOut();
    // dispatch({
    //   type: "LOGOUT",
    //   payload: null,
    // });
    // navigate("/login");
  }

  return <>


    <div className="Sidebar">
      <div className="logindetail">
        <h1>{subscriberDetails.name}</h1>
        <p>{subscriberDetails.email}</p>
      </div>
      <Menu
        className="items"
        onClick={(item) => {
          navigate(item.key);
        }}


        items={[

          {
            label: "Subscriber Detail",
            icon: <UserOutlined />,
            key: "/details",

          },
          {
            label: "Address",
            icon: <EnvironmentFilled />,
            key: "/add"
          },
          {
            label: "Plan",
            icon: <WalletFilled />,
            key: "/plan"
          },
          {
            label: "Your Orders",
            icon: <ClockCircleOutlined />,
            key: "/orderHistory"
          }
        ]}


      >
      </Menu>
      {user && (
        <div className="signout">
          <button>
            <LogoutOutlined onClick={logoutHandle} style={{ marginRight: '10px', fontSize: '20px' }} />
            <span className="button-textin" onClick={logoutHandle}>
              Sign out
            </span>
          </button>
        </div>
      )}
    </div>

  </>
}

export default Sidebar;