'use client';

import { Carousel } from 'react-responsive-carousel';
import ShopBook from '../../asset/ShopImages/ShopBook.png'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import bg from '../../asset/ShopImages/ShopBg.jpg'
import "./MainpageCarousel.css"
import Navbarg from '../Main/Navbarg';
export default function MainpageCarousel() {
    return (
       <div>
       
        <Carousel>
             
            <div className=" items flex  items-center justify-center  dark:bg-gray-700 dark:text-white">
            
                <img src={bg}></img>
                <Navbarg />
                    <div className='MainPagecarousel_div'>
                    
                        <div className='MainPage_div_content'>
                     
                            <h2>Harry Potter</h2>
                            <div className='MainPage_div_p'>
                                <p> Qorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                            </div>

                        </div>
                        <div className='MainPage_div_book'>

                            <img src={ShopBook} alt='ShopBookimg'></img>
                        </div>

                    
                </div>

            </div>
            <div className=" items flex  items-center justify-center  dark:bg-gray-700 dark:text-white">
                <img src={bg}></img>
              
                    <div className='MainPagecarousel_div'>
                        <div className='MainPage_div_content'>
                            <h2>Harry Potter</h2>
                            <div className='MainPage_div_p'>
                                <p> Qorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                            </div>

                        </div>
                        <div className='MainPage_div_book'>

                            <img src={ShopBook} alt='ShopBookimg'></img>
                        </div>

                    
                </div>

            </div>
            <div className=" items flex  items-center justify-center  dark:bg-gray-700 dark:text-white">
                <img src={bg}></img>
              
                    <div className='MainPagecarousel_div'>
                        <div className='MainPage_div_content'>
                            <h2>Harry Potter</h2>
                            <div className='MainPage_div_p'>
                                <p> Qorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                            </div>

                        </div>
                        <div className='MainPage_div_book'>

                            <img src={ShopBook} alt='ShopBookimg'></img>
                        </div>

                    
                </div>

            </div>
        </Carousel></div>
    )
}


