import React from 'react';
import "./RouteLayout.css"
import Sidebar from '../Side/Sidebar.js';

import {ArrowLeftOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';



const RouteLayout = ({ children }) => {
  const navigate = useNavigate();

const handleGoBack = () => {
    navigate(-1);
};
  return (
    <>
 

      <div className="explore-heading">
        <ArrowLeftOutlined style={{ fontSize: "30px", marginTop: "10px" }} onClick={handleGoBack} />

      </div>

      <div className="subapp-container">

        <Sidebar />
        {children}
      </div>



    </>
  );
};

export default RouteLayout;