
import { Typography, Table } from "antd";
import { useEffect, useState } from "react";
import "./Users.css"
import book1 from "../../../asset/ShopImages/ShopProduct1.png"
import { CloseOutlined } from "@ant-design/icons"
import DashBoardHeader from "../../../components/DashBoard_BK/DashBoard_header/DashBoardHeader";
import DashBoardSidebar from "../../../components/DashBoard_BK/DashBoard_SideMenu/DashBoardSidebar";
import { getAllSubscribers, searchSubscribers, adminGetSubscriber } from "../../../functions/subscriber";
import { useSelector } from "react-redux";
import { adminOrderHistory } from "../../../functions/order";
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from "react-router-dom";
import { EyeOutlined} from "@ant-design/icons"

function Users() {
    const [data, setData] = useState(null);
    const [orderhistoryshow, setOrderhistoryshow] = useState(false);
    const [subscriber, setSubscriber] = useState([])
    const [orderHistory, setOrderHistory] = useState([]);
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);
    const [userSearchKeyword, setUserSearchKeyword] = useState('');
    const [userFilteredData, setUserFilteredData] = useState([]);
    const currentNavigation = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        loadAllSubscribers();
        loadUserHistory();
    },[])

    const columns = [
        {
            key: "1",
            title: "User Name",
            dataIndex: 'name',

        },
        {
            key: "2",
            title: "Email",
            dataIndex: 'mail'
        },
        {
            key: "3",
            title: "Contact",
            dataIndex: 'contact'
        },
        {
            key: "4",
            title: "Plan",
            dataIndex: 'plan'
        },
        {
            key: "5",
            title: "Days Left",
            dataIndex: 'days'
        },
        {
            key: "6",
            title: "Ordered books",
            dataIndex: 'bookedorders'
        },
        {
            key: "7",
            title: "Orders Left",
            dataIndex: 'leftorders'
        },
        {
            key: "8",
            title: "Actions",
            dataIndex: 'actions',
            render: (text, record) => {
                const isByPiyush = record.broughtBy === "piyush";
                return (
                    <>
                        <button className="orderdetail_check" onClick={() => {
                            previewOrderdetails(record);
                        }}>Order Detail</button>

                        <EyeOutlined onClick={() => {handleSubsEdit(text, record)}}
                         style={{ color: isByPiyush? "green":"red", marginLeft: "20px" }}/>
                    </>
                );
            },
        },
    ]

    const handleSubsEdit = (text, record) => {
        adminGetSubscriber(record._id, user?.token)
        .then((res) => {
            const data = res.data;
            navigate('/subscriberform', 
            {
                state: {
                    data
                }
            }
        );
        })
        .catch((err) => {
            console.log('err', err);
        })
    }

    const { user } = useSelector((state) => ({ ...state }));

    const loadAllSubscribers = () => {
        if (user) {
            getAllSubscribers(500, user.token)
                .then((res) => {
                    const data = res.data.map((subs) => ({
                        name: subs.name,
                        mail: subs.email,
                        contact: subs.mobile,
                        plan: subs.plan,
                        days: (Math.round((new Date(subs.dateOfExpiration) - new Date()) / (1000 * 60 * 60 * 24))) > 0 ? (Math.round((new Date(subs.dateOfExpiration) - new Date()) / (1000 * 60 * 60 * 24))) : 0,
                        bookedorders: subs.bookOrdered,
                        leftorders: (subs.plan === '6 Months' ? 80 : (subs.plan === '9 Months' ? 120 : (subs.plan === '12 Months' ? 160 : 0))) - subs.bookOrdered || 0,
                        _id: subs._id,
                        broughtBy: subs.broughtBy
                    }))
                    setSubscriber(data);
                })
                .catch((err) => {
                    toast.error(err.response.data.err)
                    console.log(err)
                })
        }
    }

    const loadUserHistory = (selectedSubscriberId) => {
        adminOrderHistory(selectedSubscriberId, user.token)
            .then((res) => {
                setOrderHistory(res.data);
            })
            .catch((err) => {
                toast.error(err.response.data.err)
                console.log(err);
            })
    }


    const previewOrderdetails = (record) => {
        setData({ ...record });
        setSelectedSubscriber(record); // Store the selected subscriber's ID
        setOrderhistoryshow(true);
        loadUserHistory(record);

    }

    const closeorderhistory = () => {
        setOrderhistoryshow(false)
    }

    const filteredSubscribers = (term) => {
        searchSubscribers(term, user.token)
            .then((res) => {
                const formattedData = res.data.map((subs) => ({
                    name: subs.name,
                    mail: subs.email,
                    contact: subs.mobile,
                    plan: subs.plan,
                    days: (Math.round((new Date(subs.dateOfExpiration) - new Date()) / (1000 * 60 * 60 * 24))) > 0 ? (Math.round((new Date(subs.dateOfExpiration) - new Date()) / (1000 * 60 * 60 * 24))) : 0,
                    bookedorders: subs.bookOrdered,
                    leftorders: (subs.plan === '6 Months' ? 80 : (subs.plan === '9 Months' ? 120 : (subs.plan === '12 Months' ? 160 : 0))) - subs.bookOrdered || 0,
                    _id: subs._id,
                }));
                setUserFilteredData(formattedData);
                console.log('filtered results', res.data);
                console.log("format", formattedData);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const handleSearch = debounce(async (term) => {
        if (term.length > 1) {
            try {
                if (currentNavigation.pathname === '/users') filteredSubscribers(term);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }, 1000);

    const onHandleSearch = (keyword) => {
        const trimmedKeyword = keyword.trim();
        setUserSearchKeyword(keyword)
        if (currentNavigation.pathname === '/users' && trimmedKeyword.length > 0 && keyword !== '') {
            handleSearch(keyword);
        } else {
            setUserFilteredData([]);
        }
    }

    return <>

        <DashBoardHeader onHandleSearch={onHandleSearch} />
        <div className="app-container">
            <DashBoardSidebar />
            <div className="orderlist_edit">
                <Typography.Title level={2}>
                    Users ({subscriber.length})
                </Typography.Title>

                <div>
                    <Table className="order_table"
                        columns={columns}
                        dataSource={userSearchKeyword.length > 0 ? userFilteredData : subscriber}
                    >
                    </Table>
                </div>
                {orderhistoryshow &&
                    <div className="orderdetail">
                        <CloseOutlined onClick={closeorderhistory} style={{ cursor: 'pointer', marginLeft: "20px", marginTop: "20px", color: "white", fontSize: "30px" }} />
                        {orderHistory.map((order) => (
                            <div className="new_prev_orders">
                                <div className="OrderId_date">
                                    <h1 className="orderid">Order Id: {order._id}</h1>
                                    <h1 className="date">DateOfOrder: {new Date(order.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</h1>
                                </div>

                                <div className="User_address">
                                    <h2 className="address">Order Status</h2>
                                    <h3>{order.orderStatus}</h3>
                                </div>

                                <h1 className="items_heading">Items:</h1>
                                <div className="bookorderd_details">
                                    {order.books.map((book) => (
                                        <div key={book._id} className="ordered_bookimg">
                                            <img src={(book.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book.images[0].public_id + '.jpg' : book.images[0].url)  || book1} alt={book.title} />
                                            <div>
                                                <h1 className="ordered_bookname">{book.title}</h1>
                                                <h3 className="ordered_bookauthor">{book.author.name}</h3>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>



    </>
}

export default Users;