import './AuthFooter2.css'
import { Link } from 'react-router-dom';
import insta from './Images/instagram 1.png'
import link from './Images/linkedin 1.png'
import { HeartFilled } from '@ant-design/icons';
import face from './Images/facebook 1.png'

function AuthFooter (){
    return<>
    <div className='authfooter_main'>
        <div className='authfooter_rights'>
            <h2>@all rights are reserved</h2>
        </div>
        <div className='authfooter_korslet'>
      <Link className='bk'>Madeu With <span className="heart-icon"><HeartFilled  style={{ color: 'red', marginBottom: '20px' , width: "30px" ,fontSize: '20px' }}/></span> by KORSLET</Link>
        </div>
        <div className='authfooter_main_icons'>
           <img className="authfooter_main_insta" src={insta}></img>
           <img className="authfooter_main_link" src={link}></img>
           <img src={face}></img>
        </div>
    </div>
    </>
}


export default AuthFooter;