import React from "react";
import { useState, useEffect  } from "react";
// import {auth } from "../../firebase2"
import {auth} from "../../firebase"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import './ForgotPassword.css'

const ForgotPassword = ({history}) => {

    const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');

    let navigate = useNavigate();

    const { user } = useSelector((state) => ({...state}));

    useEffect(() => {
      if(user && user.token) navigate('/');
    },[user,navigate])

    const handleSubmit = async (e) =>{
        e.preventDefault();

        const config = {
            url: process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT,
            handleCodeInApp: true,
        }

        await auth.sendPasswordResetEmail(email, config)
        .then(() => {
            setEmail("");
            toast.success("Check your email for password reset link");
        })
        .catch((error) => {
            toast.error(error.message);
            console.log("ERROR MSG IN FORGOT PASSWORD", error);
        });
    };

    return(
        <>
    
        <div className="container">
            <div className="box_fp">
                <p className="heading">Forgot Password</p>
                <form onSubmit={handleSubmit}>
                    <input 
                        className="email_fp"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Type your email"
                        autoFocus
                    />
                    <br/>
                    <button className="submit_fp">Submit</button>
                </form>
            </div>
        </div>
        
        </>
    )
}

export default ForgotPassword;