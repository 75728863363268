import "./SideMenu.css"
import { Menu } from "antd"
import { UserOutlined, BookFilled, ShoppingFilled, PieChartFilled } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

function DashBoardSidebar({ userSearch }) {

    const navigate = useNavigate();
    return <>
        <div className="SideMenu">
            <Menu
                onClick={(item) => {
                    navigate(item.key, { state: {} });
                }}

                items={[
                    {
                        label: "Books",
                        icon: <BookFilled />,
                        key: "/books1",

                    },
                    {
                        label: "Users",
                        icon: <UserOutlined />,
                        key: "/users"
                    },
                    {
                        label: "Orders",
                        icon: <ShoppingFilled />,
                        key: "/orders"
                    },
                    {
                        label: "Analytics",
                        icon: <PieChartFilled />,
                        key: "/analytics"
                    },
                    {
                        label: "OrderBooks",
                        icon: <ShoppingFilled/>,
                        key: "/orderBooks"
                    }
                ]}


            >
            </Menu>
        </div>

    </>
}

export default DashBoardSidebar;