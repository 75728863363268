// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.points{
    margin: 0 50px 0 50px;
}

.point1 h1 , .point2 h1 , .point3 h1{
    font-size: 25px;
    font-family: Happy Lucky;
    font-weight: 400;
    letter-spacing: 1px;
   
}
.mainprivacypolicydiv_h1{
    text-align: center;
    font-size: 30px;
    font-family: Happy Lucky;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 20px;
    margin-top: 10px;
}
.point1 p , .point2 p , .point3 p{
    font-family:Warung Kopi;
    font-size: 20px;
    margin-bottom: 40px;
}
@media (max-width: 468px) {
    .points{
        margin: 0 30px 0 30px;
    }
    .point1 h1 , .point2 h1 , .point3 h1{
        font-size: 20px;
       
    }
    .point1 p , .point2 p , .point3 p{
      
        font-size: 20px;
 
       text-align: justify;
    }
    .mainprivacypolicydiv_h1{

        margin-bottom: 40px;
 
    }

}
@media (min-width: 768px) and (max-width: 1024px) {
    .point1 h1 , .point2 h1 , .point3 h1{
        font-size: 35px;
        font-weight: 600;
       
    }
    .point1 p , .point2 p , .point3 p{
      
        font-size: 25px;
 
       text-align: justify;
    }
    .mainprivacypolicydiv_h1{

        margin-bottom: 40px;
        font-weight: 600;
 
    }

}`, "",{"version":3,"sources":["webpack://./src/pages/Policies/Privacy.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,wBAAwB;IACxB,gBAAgB;IAChB,mBAAmB;;AAEvB;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,wBAAwB;IACxB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI;QACI,qBAAqB;IACzB;IACA;QACI,eAAe;;IAEnB;IACA;;QAEI,eAAe;;OAEhB,mBAAmB;IACtB;IACA;;QAEI,mBAAmB;;IAEvB;;AAEJ;AACA;IACI;QACI,eAAe;QACf,gBAAgB;;IAEpB;IACA;;QAEI,eAAe;;OAEhB,mBAAmB;IACtB;IACA;;QAEI,mBAAmB;QACnB,gBAAgB;;IAEpB;;AAEJ","sourcesContent":[".points{\n    margin: 0 50px 0 50px;\n}\n\n.point1 h1 , .point2 h1 , .point3 h1{\n    font-size: 25px;\n    font-family: Happy Lucky;\n    font-weight: 400;\n    letter-spacing: 1px;\n   \n}\n.mainprivacypolicydiv_h1{\n    text-align: center;\n    font-size: 30px;\n    font-family: Happy Lucky;\n    font-weight: 400;\n    letter-spacing: 1px;\n    margin-bottom: 20px;\n    margin-top: 10px;\n}\n.point1 p , .point2 p , .point3 p{\n    font-family:Warung Kopi;\n    font-size: 20px;\n    margin-bottom: 40px;\n}\n@media (max-width: 468px) {\n    .points{\n        margin: 0 30px 0 30px;\n    }\n    .point1 h1 , .point2 h1 , .point3 h1{\n        font-size: 20px;\n       \n    }\n    .point1 p , .point2 p , .point3 p{\n      \n        font-size: 20px;\n \n       text-align: justify;\n    }\n    .mainprivacypolicydiv_h1{\n\n        margin-bottom: 40px;\n \n    }\n\n}\n@media (min-width: 768px) and (max-width: 1024px) {\n    .point1 h1 , .point2 h1 , .point3 h1{\n        font-size: 35px;\n        font-weight: 600;\n       \n    }\n    .point1 p , .point2 p , .point3 p{\n      \n        font-size: 25px;\n \n       text-align: justify;\n    }\n    .mainprivacypolicydiv_h1{\n\n        margin-bottom: 40px;\n        font-weight: 600;\n \n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
