// logoutService.js

import firebase from '../../firebase'; // Import Firebase if you're using Firebase authentication
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

const useLogoutService = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  const logout = () => {
    console.log("logging out...from logout service")
    firebase.auth().signOut();
    // dispatch({ type: 'LOGOUT', payload: null });
    // navigate('/login');
    window.location.href = '/login';
  };

  return { logout };
};

export default useLogoutService;
