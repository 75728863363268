import "./MediumSubscription.css"

import rupee from "../../asset/ImgesSubscription/rupee.png"
import book from "../../asset/ImgesSubscription/book.png"
import MainNavBar from '../../components/nav/MainNav/MainNavBar'
import Footer from '../../components/nav/AuthFooter2/AuthFooter2'
import { CheckOutlined } from "@ant-design/icons"
import { useLocation } from "react-router-dom"
import { createSubscriber, adminCreateSubscriber } from "../../functions/subscriber"
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { payment } from "../../functions/payment"
import { useState, useEffect } from "react"
import { FadeLoader } from 'react-spinners';

function MediumSubscription() {

  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();

  const subscriptionDetails = location.state ? location.state : {};

  const { plan, price, duration, limit, delivery, access, formData } = subscriptionDetails;

  console.log(formData);
  useEffect(() => {
    console.log("plan", plan)
    if (user) {
      if (user.role === 'subscriber') {
        toast.error("Already a Susbcriber");
        navigate('/library');
      } else {
        setLoading(false); // Step 3: Update loading state once initial checks are done
      }
    } else {
      setLoading(false); // Step 3: Update loading state if user is not authenticated
    }
  }, [user, navigate]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); 
  }, []);

  const handleOpenRazorpay = (data) => {

    const options = {
      key: process.env.RAZORPAY_KEY_ID,
      amount: Number(data.amount),
      currency: data.currency,
      order_id: data.id,
      name: 'BOOKKULTURE',//
      description: 'XYZ',//
      handler: function (response) {
        if (user) {
          console.log("formDataaa--", formData)
          createSubscriber(formData, response, user.token)
          .then((res) => {
            toast.success("Subcriber Created Successfully");
            window.location.href = '/library';
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.err)
            toast.error("An Error Occured While Creating the Subscriber");
          })
        } else {
          toast.error("SigUp Or Login With an account!!!")
        }
      }
    }
    const rzp = new window.Razorpay(options)
    rzp.open()

  }

  const handlePayNow = (e) => {
    e.preventDefault();
    let amount = 0;
    if(price === 2999) amount = 2999;
    else if(price === 3999) amount = 3999;
    else if(price === 4999) amount = 4999;

    if(user && user.role === 'admin'){
      console.log(formData.email, formData.adminEmail)
      formData.email = formData.adminEmail
      delete formData.adminEmail;
      formData.name = formData.name + ' ' + formData.user;
      delete formData.user;
      console.log(formData)
      adminCreateSubscriber(formData, user.token)
      .then((res) => {
        console.log(res, 'createdSubs')
        toast.success("Subcriber Created Successfully");
        navigate('/library');
        // window.location.href = '/library';
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response)
        toast.error("An Error Occured While Creating the Subscriber");
      })
    }else {
      payment(user.token, amount)
      .then(res => {
        handleOpenRazorpay(res.data.data)
      })
      .catch(err => {
        toast.error(err.response.data.err)
        console.log(err)
      }) 
    }
     
    
  }

  return <>
    <MainNavBar hideOptions={true}></MainNavBar>

    {loading ? (
      <>
        <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <FadeLoader color={'red'} loading={loading} size={150} />
        </div>
      </>
    ) : (
      <>
        <div className="Meduim_subscription">
          <div className="Meduim_subscription_details">

            <img className="bookieimg" src={book}></img>

            <h1 className="medium">{plan}</h1>
            <div className="Medium_subscription_payment">
              <img src={rupee}></img>
              <div className="rupeeprice"></div>
              <h3>{price}</h3>

            </div>
            <ul>
              <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} />   {duration}</li>
              <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} /> {limit}</li>
              <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} /> {delivery}</li>
              <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} /> {access}</li>
            </ul>
          </div>



          <div className="Medium_subscription_checkout">

            <h1>Subscription Summary</h1>
            <div className="userfetchdetails">
              <div className="userfetch-name">
                <h3>Name </h3>
                <p>{formData?.name} {formData?.user}</p>
              </div>
              <div className="userfetch-email">
                <h3>Email</h3>
                {user && user.role === 'admin' ? (
                  <p>{formData.adminEmail ? formData.adminEmail : null}</p>
                ) : ( <p>{formData.email ? formData.email : user? user.email : null}</p>)}
                {/* <p>{formData.email ? formData.email : user? user.email : null}</p> */}
              </div>
              <div className="userfetch-add">
                <h3>Address </h3>
                <div>
                  <p className="justify">{formData?.address.city}</p>
                </div>

              </div>
            </div>

            <div className="horizontal_line"></div>
            <div className="subtotal">
              <ul className="subtotalul">
                <li>Subtotal</li>
                <li>Delivery </li>
              </ul>
              <ul className="medium_amount">
                <li>{price}</li>
                <li className="free">Free</li>
              </ul>
            </div>

            <div className="horizontal_line"></div>
            <div className="total">
              <h2>Total</h2>
              <h3>{price}</h3>
            </div>
            <div className="horizontal_line"></div>

            <button className="subscription_checkout" onClick={handlePayNow}>PAY NOW</button>
          </div>
        </div>
      </>
    )}

    <Footer></Footer>

  </>
}

export default MediumSubscription;