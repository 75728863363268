import "./Privacy.css"
import MainNavBar from "../../components/nav/MainNav/MainNavBar";
function Privacy(){

    return<>
       <MainNavBar hideOptions={true}></MainNavBar>
    <div className="mainprivacypolicydiv">
        <h1 className="mainprivacypolicydiv_h1">Privacy and Policy</h1>
      <div className="points">
        <div className="point1">
          <h1>WHAT DO WE DO WITH YOUR INFORMATION?</h1>
          <p>When you purchase something from our store, as part of the buying and selling process, we collect the personal 
            information you give us such as your name, address and email address.
            When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to 
            provide us with information that helps us learn about your browser and operating system.</p>
        </div>
        <div className="point2">
          <h1>CONSENT</h1>
          <p>When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a 
            delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only..</p>
        </div>
        <div className="point3">
          <h1> PAYMENT</h1>
          <p>We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through 
            the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as 
            long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.

Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands 
like Visa, MasterCard, American Express and Discover.

PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.

For more insight, you may also want to read terms and conditions of razorpay on https://razorpay.com</p>
        </div>

      </div>
    </div>
    </>
}

export default Privacy;