import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import "./SubscriberForm.css"
import Authfooter from "../nav/AuthFooter2/AuthFooter2"
import MainNavBar from '../nav/MainNav/MainNavBar'
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { editAdminSubscriber } from '../../functions/subscriber';
import { Select } from 'antd';
const { Option } = Select;


const SubscriberForm = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();
  const location = useLocation();


  const { plan, price, duration, limit, delivery, access } = location.state || {};

  const subscriberDetails = location.state?.data;

  const [isCoupon, setIsCoupon] = useState(false);

  const [formData, setFormData] = useState(() => {
    if (!location.state?.data) {
      const storedFormData = localStorage.getItem('subscriberFormData');
      return storedFormData ? JSON.parse(storedFormData) : {
        name: '',
        lastname:'',
        email: user ? user.email : storedFormData.email,
        adminEmail: '',
        cart: [],
        booksIssued: [],
        plan: '',
        address: {
          city: '',
          pincode: '',
          house: '',
          area: '',
          landmark: '',
        },
        mobile: '',
        dateOfBirth: '',
        school: '',
        std: '',
        // refferedBy: '',
        broughtBy: '',
        bookOrdered: 0,
        dateOfSubscription: new Date(),
        dateOfExpiration: new Date()
      };
    } else {
      return {
        name: subscriberDetails?.name,
        lastname: subscriberDetails?.lastname,
        email: subscriberDetails?.email,
        adminEmail: subscriberDetails?.adminEmail,
        cart: [],
        booksIssued: [],
        plan: subscriberDetails?.plan,
        address: {
          city: subscriberDetails?.address?.city,
          pincode: subscriberDetails?.address?.pincode,
          house: subscriberDetails?.address?.house,
          area: subscriberDetails?.address?.area,
          landmark: subscriberDetails?.address?.landmark,
        },
        mobile: subscriberDetails?.mobile,
        dateOfBirth: new Date(subscriberDetails?.dateOfBirth).toISOString().split('T')[0],
        school: subscriberDetails?.school,
        std: subscriberDetails?.std,
        // refferedBy: subscriberDetails?.refferedBy,
        broughtBy: subscriberDetails?.broughtBy,
        bookOrdered: subscriberDetails?.bookOrdered,
        dateOfExpiration: new Date(subscriberDetails?.dateOfExpiration).toISOString().split('T')[0],
        dateOfSubscription: new Date(subscriberDetails?.dateOfSubscription).toISOString().split('T')[0]
      }
    }

  });

  useEffect(() => {
    if (!location.state?.data) {
      formData.plan = duration;
      localStorage.setItem('subscriberFormData', JSON.stringify(formData));
    }
  }, [formData]);

  function renderHeading() {
    if (location?.state?.data)
      return <div className='usersubhead'><h1 style={{ color: "Red", fontFamily: "Happy Lucky" }}> Edit Subcriber Form</h1></div>
    else
      return <div className='usersubhead'><h1 style={{ color: "Red", fontFamily: "Happy Lucky" }}> Subcriber Form</h1></div>
  }

  function renderFormFooter() {
    if (location?.state?.data)
      return <><div className='submitContainer'><button className='cancelBtn' onClick={() => navigate('/users')}>Cancel</button><button className='formsubmit' type="submit">Save</button></div></>
    else
      return <><div className='checkbox'> <input type="checkbox" required /><h1 className='checkcon'>By clicking this checkbox,I agree all the <Link to='/terms'>Terms and conditions</Link></h1></div><button className='formsubmit' type="submit">Submit</button></>
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePlanChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target)
    if (name === 'plan') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }))
    }
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    if (name === 'city') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: {
          ...prevFormData.address,
          city: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: {
          ...prevFormData.address,
          [name]: value,
        },
      }));
    }
  };

  const handleCoupon = () => {
    console.log(isCoupon)
    if (isCoupon) setIsCoupon(false);
    else setIsCoupon(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!location.state?.data) {
      if (user) {
        setFormData(formData)
        navigate('/medium', {
          state: {
            plan,
            price,
            duration,
            limit,
            delivery,
            access,
            formData,
          },
        })
      } else {
        toast.error("Please Be a Valid User, SigIn Or Login With an account!!!")
      }
    } else {

      const subscriber = formData
      const subscriber_formData = {
        subscriber
      }
      editAdminSubscriber(subscriberDetails?._id, subscriber_formData, user?.token)
        .then((res) => {
          console.log('subscriber details updated successfully', res);
          toast.success('Updated successfully');

          navigate('/users');
        })
        .catch((err) => {
          console.log('err', err);
        })
    }
    


  };

  if(user) var referenceClass = user.role === 'admin' ? 'reference admin' : 'reference user';

  return (

    <>
      <MainNavBar hideOptions={true}></MainNavBar>
      {renderHeading()}
      <div className='usersubform'>
        <form onSubmit={handleSubmit} className='userfillform'>
          <div className='nameandlast'>
            <div className='username'>
              <input
                type="text"
                name="name"
                maxLength="50"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            {!location?.state?.data && (
              <>
                <div className='Lastname'>

                  <input
                    type="text"
                    name="lastname"
                    value={formData.lastname}
                    // value={formData.name}
                    maxLength="50"
                    onChange={handleChange}
                    required
                  />
                </div>
              </>
            )}
          </div>

          <div className='citypin'>

            <div className=' userpin'>
              <input
                type="number"
                name="pincode"
                value={formData.address.pincode}
                onChange={handleAddressChange}
                maxLength="7"
                onInput={(e) => {
                  // Prevents entering more than 7 digits
                  if (e.target.value.length > 7) {
                    e.target.value = e.target.value.slice(0, 7);
                  }
                }}
                required
              />
            </div>

            <div className='usercity'>

              <Select
                className="custom-select"
                name="city"
                value={formData.address.city}
                onChange={(value) => handleAddressChange({ target: { name: 'city', value } })}
                required
              >
                <Option value="Thane">Thane</Option>
                <Option value="Navi Mumbai">Navi Mumbai</Option>
                <Option value="Mumbai">Mumbai</Option>
              </Select>
            </div>
          </div>
          <div className='housearea'>

            <div className='userhouse'>
              <input
                type="text"
                name="house"
                value={formData.address.house}
                maxLength="70"
                onChange={handleAddressChange}
                required
              />
            </div>

            <div className='userarea'>
              <input
                type="text"
                name="area"
                maxLength="70" 
                value={formData.address.area}
                onChange={handleAddressChange}
                required
              />
            </div>

          </div>


          <div className='usermobland'>

            <div className='userlandmark'>
              <input
                type="text"
                name="landmark"
                value={formData.address.landmark}
                onChange={handleAddressChange}
                maxLength="70" 
                required
              />
            </div>

            <div className='usermob'>
              <input
                type="number"
                name="mobile"
                value={formData.mobile}
                maxLength="11"
                onInput={(e) => {
                  // Prevents entering more than 7 digits
                  if (e.target.value.length > 11) {
                    e.target.value = e.target.value.slice(0, 11);
                  }
                }}
                onChange={handleChange}
                required
              />
            </div>

          </div>

          <div className='userclassdob'>
            {/* <div className='userstandard'>
              <input
                type="number"
                name="std"
                value={formData.std}
                onChange={handleChange}
                required
              />
            </div> */}

           <div className='userstandard'>
                <Select
                className="custom-select"
                name="std"
                value={formData.std}
                onChange={(value) => handleChange({ target: { name: 'std', value } })}
                required>
                <Option value="Nursery">Nursery</Option>
                <Option value="Junior Kg">Junior Kg</Option>
                <Option value="Senior Kg">Senior Kg</Option>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
                <Option value="11">11</Option>
                <Option value="12">12</Option>
                <Option value="Other">Other</Option>

              </Select>
            </div>

            <div className='userdob'>
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
                required
              />
            </div>

          </div>

          {!location.state?.data ? (
            <>
              {user && user.role === 'admin' ? (
                <>
                  <div className='useremail'>
                    <input
                      type="email"
                      name="adminEmail"
                      value={formData.adminEmail}
                      onChange={handleChange}
                      // readOnly
                      required
                    />
                  </div>
                </>
              ) : (
                <div className='useremail'>
                  <input
                    type="email"
                    name="email"
                    defaultValue={formData.email}
                    // readOnly
                    required
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className='usercity_plan'>
                <Select
                  className="custom-select"
                  name="plan"
                  value={formData.plan}
                  onChange={(value) => handlePlanChange({ target: { name: 'plan', value } })}
                  required>
                  <Option value="6 Months">6 Months</Option>
                  <Option value="9 Months">9 Months</Option>
                  <Option value="12 Months">12 Months</Option>
                </Select>
              </div>
            </>
          )}


          <div className='userschoolin'>
            <input
              type="text"
              name="school"
              value={formData.school}
              maxLength="70" 
              onChange={handleChange}
              required
            />
          </div>

          {/* <div className='reference'>
            <input
              type="text"
              name="refferedBy"
              value={formData.refferedBy}
              onChange={handleChange}
            />
          </div> */}

          {user && user.role === 'admin' && (
            <>
              <div className='bookOrdered'>
                <input
                  type="text"
                  name="bookOrdered"
                  value={formData.bookOrdered}
                  onChange={handleChange}
                  placeholder='Book Ordered'
                  required
                />
              </div>

              <div className='dateOfSubscription'>
                <input
                  type="date"
                  name="dateOfSubscription"
                  value={formData.dateOfSubscription}
                  onChange={handleChange}
                  placeholder='Date of Subscription'
                  required
                />
              </div>

              <div className='dateOfExpiration'>
                <input
                  type="date"
                  name="dateOfExpiration"
                  value={formData.dateOfExpiration}
                  onChange={handleChange}
                  placeholder='Date of Expiration'
                  required
                />
              </div>
            </>
          )}

          <h2 onClick={handleCoupon} className='coupon'>Do you have a Coupon?</h2>
          {isCoupon && (
            <>
              <div className={referenceClass}>
                <input
                  type="text"
                  name="broughtBy"
                  value={formData.broughtBy}
                  maxLength="70" 
                  onChange={handleChange}
                />
              </div>
            </>
          )}

          {renderFormFooter()}
          {/* <div className='checkbox'>

            <input
              type="checkbox"
              required
            />
            <h1 className='checkcon'>By clicking this checkbox,I agree all the <Link to='/terms'>Terms and conditions</Link></h1>


          </div>

          <button className='formsubmit' type="submit">Submit</button> */}
        </form>

      </div>
      <Authfooter></Authfooter>
    </>
  );
};

export default SubscriberForm;

