import './LandingPage.css';
import child from '../../asset/child_final.png'
import plant from '../../asset/Plant.png'
import { Link } from 'react-router-dom';

function LandingPage() {
  return <>
    <div className="landing-page">
      <div className='MainPage_elements'>
        <img className='plant' src={plant} alt="Logo" />
        <button > <Link to="/library" className="custom-link">Get Your Books</Link></button>
      </div>
      <div className='Child'>
        <img className='bk_logo' src={child} alt="Logo" />
      </div>

    </div>
  </>
}

export default LandingPage;