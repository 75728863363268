

import "./Dashboard.css"

function DashBoard (){
    return<>
<div className="dashboardwalcome">
    <h1 className="WelcomeDashboardpage">Welcome to BookKulture Dashboard</h1>
</div>


    </>
}

export default DashBoard;