// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container{
    display: flex;
    justify-content: center;
}

.heading{
    font-family: Amatic SC;
    font-size: 38px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.5px;
    text-align: center;
}
  
.box_fp{

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 29px;
    width: 515px;
    height: 400px;
    margin-top: 30px;
    border-radius: 20px;
    border: 1px solid rgba(255, 90, 98, 0.30);
    background: #FFF;
}

.email_fp{
    width: 346px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #FF5A62;
    background: #FFF;
    margin-top: 20px;
    margin-bottom: 15px;
    flex-shrink: 0;
}

.submit_fp{
    width: 270px;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    background: #FF5A62;
    margin-bottom: 15px;
}


@media (max-width: 575px) {
    .box_fp {
        width: 350px;
        height: 300px;
        border: 3px solid rgba(255, 90, 98, 0.30);
    }
  
  
  
  
    .submit_fp ,.email_fp{
      width: 310px;
    }
  
    
  }`, "",{"version":3,"sources":["webpack://./src/pages/auth/ForgotPassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;;IAEI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,yCAAyC;IACzC,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;AACvB;;;AAGA;IACI;QACI,YAAY;QACZ,aAAa;QACb,yCAAyC;IAC7C;;;;;IAKA;MACE,YAAY;IACd;;;EAGF","sourcesContent":[".container{\n    display: flex;\n    justify-content: center;\n}\n\n.heading{\n    font-family: Amatic SC;\n    font-size: 38px;\n    font-weight: 700;\n    line-height: 40px;\n    letter-spacing: 0.5px;\n    text-align: center;\n}\n  \n.box_fp{\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    padding: 29px;\n    width: 515px;\n    height: 400px;\n    margin-top: 30px;\n    border-radius: 20px;\n    border: 1px solid rgba(255, 90, 98, 0.30);\n    background: #FFF;\n}\n\n.email_fp{\n    width: 346px;\n    height: 40px;\n    flex-shrink: 0;\n    border-radius: 10px;\n    border: 1px solid #FF5A62;\n    background: #FFF;\n    margin-top: 20px;\n    margin-bottom: 15px;\n    flex-shrink: 0;\n}\n\n.submit_fp{\n    width: 270px;\n    height: 40px;\n    margin-top: 10px;\n    margin-bottom: 20px;\n    border-radius: 10px;\n    background: #FF5A62;\n    margin-bottom: 15px;\n}\n\n\n@media (max-width: 575px) {\n    .box_fp {\n        width: 350px;\n        height: 300px;\n        border: 3px solid rgba(255, 90, 98, 0.30);\n    }\n  \n  \n  \n  \n    .submit_fp ,.email_fp{\n      width: 310px;\n    }\n  \n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
