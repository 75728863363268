import './SubscriptionBK.css'
import book from '../../asset//ImgesSubscription/book.png'
import book1 from '../../asset//ImgesSubscription/greenbook.png'
import { CheckOutlined } from "@ant-design/icons"
import { useNavigate } from 'react-router-dom';
import rupee from "../../asset/ImgesSubscription/rupee.png"
import { useSelector } from 'react-redux';
import {toast} from 'react-toastify'


function SubscriptionBk() {
    const navigate = useNavigate();
    const { user } = useSelector((state) => ({ ...state }));
  
    
    const handleClick = (plan, price, duration, limit, delivery, access) => {
        if(!user){
            toast.error("Please Login First")
            navigate('/login')
        }else{
            navigate('/subscriberform', {
                state: {
                  plan,
                  price,
                  duration,
                  limit,
                  delivery,
                  access,
                },
              });
        }
        

    }
    return (<>
        <div>
            <div className='SubscribeHeader' id="subscription" >
                <h1>Subscription</h1>
                <p>"Spark your child's imagination with our captivating book subscription, where<br></br>
                    adventure awaits on every page." </p>
            </div>
            <div className="container">
                <div className="showBook">
                    <img src={book} alt="book" />
                    <div className="bookText">
                        <h3>Silver</h3>
                        <div className='rupeeprice'>
                         <img src={rupee} alt='book'></img>
                        <h2>2999</h2>
                        </div>
                        <h1 className='duration'>6 Months</h1>
                        
                        <ul>
                            <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} />Book Limit- 80</li>
                            <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} />Delivery at Door Step</li>
                            <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} />Access to all books</li>
                        </ul>
                        <button className='subscribe_button' onClick={() => handleClick('Silver', 2999, '6 Months', 'Book Limit- 80', 'Delivery at Door Step', 'Access to all books')}>subscribe</button>
                    </div>
                </div>
                <div className="showBook showBook-2">
                    <img src={book1} alt="book" />
                    <div className="bookText bookText2">
                        <h3>Gold</h3>
                        <div className='rupeeprice'>
                         <img src={rupee} alt='book'></img>
                        <h2>3999</h2>
                        </div>
                        <h1 className='duration'>9 Months</h1>
                        <ul>
                            <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} /> Book Limit- 120</li>
                            <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} />Delivery at Door Step</li>
                            <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} />Access to all books</li>
                        </ul>
                        <button className='subscribe_buttongreen'  onClick={() => handleClick('Silver', 3999, '9 Months', 'Book Limit- 120', 'Delevery at Door Step', 'Access to all books')}>subscribe</button>
                    </div>
                </div>
                <div className="showBook"> <img src={book} alt="book" />
                    <div className="bookText">
                        <h3 className='platinum'>Platinum</h3>
                        <div className='rupeeprice'>
                         <img src={rupee} alt='book'></img>
                        <h2>4999</h2>
                        </div>
                        <h1 className='duration'>12 Months</h1>
                        <ul>
                            <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} />Book Limit- 160</li>
                            <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} />Delivery at Door Step</li>
                            <li><CheckOutlined style={{ color: "#F1E578", marginRight: "5px" }} />Access to all books</li>
                        </ul>
                        <button className='subscribe_button1'  onClick={() => handleClick('Platinum', 4999, '12 Months', 'Book Limit- 160', 'Delivery at Door Step', 'Access to all books')}>subscribe</button>
                    </div>
                </div>
            </div>
        </div>
<br></br>
    </>
    );
}

export default SubscriptionBk;