import axios from "axios";
import axiosInstance  from './services/interceptor';

export const createOrder =async (books, authtoken) =>
    await axiosInstance.post(`${process.env.REACT_APP_API}/order`, books,
      {
      headers:{
        authtoken,
      }
    });
  

export const listAll = async (count, authtoken) => {
  return await axiosInstance.get(
    `${process.env.REACT_APP_API}/order/${count}`,
    {
      headers: {
        authtoken: authtoken, // Set the authtoken in the headers
      },
    }
  );
};

export const updateStatus = async (authtoken, _id, orderStatus) =>{
    return await axiosInstance.put(`${process.env.REACT_APP_API}/order/update-status`, 
      {_id, orderStatus}, {
      headers:{
        authtoken,
      }
    });
  };

export const adminOrderHistory = async (subscriber, authtoken) =>{
    return await axiosInstance.post(`${process.env.REACT_APP_API}/admin-order-history`, subscriber,
      {
      headers:{
        authtoken,
      }
    });
  };

  export const adminIssuedBooks = async (authtoken) =>{
    return await axiosInstance.get(`${process.env.REACT_APP_API}/admin-issued-books`,
      {
      headers:{
        authtoken,
      }
    });
  };

  export const returnBook = async (authtoken, book, _id, ) =>{
    console.log('id', _id, 'book_idd', book)
    return await axiosInstance.put(`${process.env.REACT_APP_API}/order/return`, 
      {book,  _id }, {
      headers:{
        authtoken,
      }
    });
  };

  export const orderHistory = async ( authtoken) =>{
    return await axiosInstance.get(`${process.env.REACT_APP_API}/order-history`,
      {
      headers:{
        authtoken,
      }
    });
  };

  export const adminCreateOrder = async (subscriber, authtoken) =>{
    return await axiosInstance.post(`${process.env.REACT_APP_API}/admin-create-order`, subscriber,
      {
      headers:{
        authtoken,
      }
    });
  };

  export const adminUpdateOrder = async (subscriber, authtoken) =>{
    return await axiosInstance.post(`${process.env.REACT_APP_API}/admin-update-order`, subscriber,
      {
      headers:{
        authtoken,
      }
    });
  };