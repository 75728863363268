import React, { useState } from 'react'
import Resizer from "react-image-file-resizer";
import axios from 'axios';
import { useSelector } from "react-redux";
import { Avatar, Badge } from "antd";

const FileUpload = ({ values, setValues, loading, setLoading }) => {

  const { user } = useSelector((state) => ({ ...state }));
  const [loader, setLoader] = useState(false);

  const fileUploadAndResize = (e) => {

    let file = e.target.files;
    let allUploadedFiles = values.images;

    if (file) {
      for (let i = 0; i < file.length; i++) {
        console.log(file[i]);
        Resizer.imageFileResizer(
          file[i],
          720,
          720,
          "JPEG",
          100,
          0,
          (uri) => {
            setLoading(true)
            setLoader(true)
            axios.post(

              `${process.env.REACT_APP_API}/uploadimages3`,
              { image: uri },
              {
                headers: {
                  authtoken: user ? user.token : "",
                },
              }
            )
              .then((res) => {
                setLoading(false);
                setLoader(false)
                allUploadedFiles.push(res.data);

                setValues({ ...values, images: allUploadedFiles });
              })
              .catch(err => console.log("CLOUDINARY UPLOAD ERR", err));
          },
          // "base64"
        )
      };
    }
  }

  const handleImageRemove = (public_id) => {
    setLoading(true);
    axios
      .delete(
        `${process.env.REACT_APP_API}/removeimages3`, {

        data: { public_id },

        headers: {
          authtoken: user.token,
        },

      }
      )
      .then((res) => {
        setLoading(false);
        const { images } = values;
        let filteredImages = images.filter((item) => {
          return item.public_id !== public_id;
        });
        setValues({ ...values, images: filteredImages });
        console.log("image removed", {values})
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <div>
    {loader ? (
      <p>Loader...</p>
    ) : (
      <p></p>
    )}
      {values.images &&
        values.images.map((image) => (
          <Badge
            count="X"
            key={image.public_id}
            onClick={() => handleImageRemove(image.public_id)}
            style={{ cursor: "pointer" }}
          >
            <Avatar
              src={image.url}
              size={100}
              shape="square"
              className="ml-3"
            />
          </Badge>
        ))}
      <label>Choose File
        <input
          type='file'
          
          multiple
          hidden
          accept='images/*'
          onChange={fileUploadAndResize}
        />
      </label>
    </div>
  )
}

export default FileUpload;
