import "./ExploreGenre.css"
import product2 from '../../asset/ShopImages/ShopProduct2.png'
import { FaShoppingCart } from 'react-icons/fa';
import { ArrowLeftOutlined } from '@ant-design/icons'
import Click from "./Click";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { getBooksByCategories } from "../../functions/book";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import { addToCart, getCartList } from '../../functions/cart'
import { useDispatch } from "react-redux";
import { FadeLoader } from 'react-spinners';

function ExploreGenre() {

    const [open, setOpen] = useState(false);
    const [books, setBooks] = useState();
    const [filteredBooks, setFilteredBooks] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [selectedBook, setSelectedBook] = useState(null);
    const [loading, setLoading] = useState(false);
    // ... other state and functions ...

    const handleImageClick = (book) => {
        setSelectedBook(book);
        setOpen(true);
    }

    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();

    const { genere, genere_id } = location.state || {};

    const { user } = useSelector((state) => ({ ...state }));
    const logoUrl = '/bookkulturelogo.png';

    useEffect(() => {
        const savedCartItems = JSON.parse(localStorage.getItem('cartItems') || '[]');
        setCartItems(savedCartItems);
    }, []);



    useEffect(() => {
        loadBooks();
        loadCart();
    }, [])

    const loadCart = () => {
        if (user) {
            getCartList(user.token)
                .then((res) => {
                    const cartItemContainer = res.data;
                    setCartItems(cartItemContainer)
                    localStorage.setItem('cartItems', JSON.stringify(res.data.length));
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.err || "Error Occured!!!");
                })
        }
    }

    const loadBooks = () => {
        setLoading(true)
        // getBooksByCount(500)
        const data = { category: genere_id }
        getBooksByCategories(500, data)
            .then((res) => {
                setLoading(false);
                setBooks(res.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error("Error in Loading Books")
            })
    }

    const handleGoBack = () => {
        // navigate(-1);
        navigate('/library')
    };

    const openfunction = () => {
        setOpen(true);
        console.log("hello")
    }

    useEffect(() => {

        if (books && books.length > 0) {
            // console.log("Books length", books.length)
            const filteredBooks1 = books.filter((book) => {
                const genreName = book.category.name.toLowerCase();
                return genreName === genere?.toLowerCase();
            });
            setFilteredBooks(filteredBooks1);
        }
    }, [books, genere]);

    const handleAddToCart = (book) => {
        if (cartItems.includes(book._id)) {
            toast.error('Already in the cart');
        } else {
            if (user && user.role === 'subscriber') {
                addToCart(book, user.token)
                    .then((res) => {
                        if (res === "Already in the cart") {
                            toast.error(res.data);
                        } else {
                            toast.success('Added to the Cart');
                            localStorage.setItem('cartItems', JSON.stringify(res.data.cart.length));
                            localStorage.setItem('cartUpdated', true);
                        }
                    })
                    .catch((err) => {

                        if (err?.response?.data === "Already in the cart" || err?.response?.data === "Cannot add more than 15 books in the cart") {
                            toast.error(err?.response?.data);
                        }else{
                            toast.error("Could Not Add Book To The Cart")
                        }
                        console.log(err.response);
                    });
            } else {
                toast.error("Please subscribe")
            }
        }
    };

    return <>
        {open && selectedBook && (
            <Click openpopup={open} onClose={() => setOpen(false)} selectedBook={selectedBook} />
        )}

        <div className="exploregenre">
            <div className="explore-heading">
                <ArrowLeftOutlined style={{ fontSize: "30px", marginTop: "10px" }} onClick={handleGoBack} />
                <h1>{genere?.toUpperCase()}</h1>
            </div>

            {loading ? (
                <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <FadeLoader color={'red'} loading={loading} size={150} />
                </div>
            ) : (
                <>
                    {/* {JSON.stringify(books)} */}
                    <div className="explore-divs">
                        {books && books.length > 0 && books.map((book) => (
                            <div key={book._id} className='ProductCard'>
                                <div className='ProductImg'>
                                    <img src={book.images[0] ? (book.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book.images[0].public_id + '.jpg' : book.images[0].url)  : logoUrl} onClick={() => { handleImageClick(book) }} alt="Product" />
                                </div>
                                <div className='Product-cardheader'>
                                    <div className='Product-cardheader_content'>
                                        <p>{book.title.slice(0, 38)}...</p>
                                        {book.quantity > 0 ? (
                                            <p className="available">Available</p>
                                        ) : (
                                            <p className="available" style={{ color: "red" }}>Unavailable</p>
                                        )}
                                    </div>
                                </div>
                                <div className='addtocart'>
                                    <button disabled={book.quantity === 0}  onClick={() => handleAddToCart(book)}>
                                        <FaShoppingCart className="icon" />
                                        Add to Cart
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}

        </div>
    </>
}

export default ExploreGenre;

