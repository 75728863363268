import "./Userdetail.css"
import { Space, Typography } from "antd";
import { currentSubscriber } from "../../../functions/subscriber";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FadeLoader } from 'react-spinners';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';


function Userdetail() {


    const [subscriberDetails, setSubscriberDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const { user } = useSelector((state) => ({ ...state }));

    useEffect(() => {
        getSubscriberDetails();
    }, [user])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const getSubscriberDetails = () => {
        if (user) {
            if (user.role === 'subscriber') {
                currentSubscriber(user.token)
                    .then((res) => {
                        console.log(res);
                        setSubscriberDetails(res.data);
                    })
                    .catch((err) => {
                        toast.error(err.response.data.err)
                        console.log(err);
                    })
            }
        }
    }



    return <>

        {loading ? (
            <>
                <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '400px' }}>
                    <FadeLoader color={'red'} loading={loading} size={150} />
                </div>
            </>
        ) : (
            <>
                {user && user.role === 'viewer' ? (
                    <>
                        <div className="clickherediv" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '300px' }}>
                            <Link to='/' className="clickhere">CILCK HERE TO SUBSCRIBE!!!</Link>
                        </div>
                        
                    </>
                ) : (
                    <>
                        <div className="detailLayout">
                            <Typography.Title level={2} className="ty">
                                Subscriber Detail
                            </Typography.Title>
                            <div className="rowsdetails">
                                <Space direction="vertical">
                                    <h1 className="rowmain">Name</h1>
                                    <h2 className="rowdetail">{subscriberDetails.name}</h2>
                                </Space>
                                <Space direction="vertical">
                                    <h1 className="rowmain">Email</h1>
                                    <h2 className="rowdetail">{subscriberDetails.email}</h2>
                                </Space>
                                <Space direction="vertical">
                                    <h1 className="rowmain">DOB</h1>
                                    <h2 className="rowdetail">{subscriberDetails.dateOfBirth ? (subscriberDetails.dateOfBirth.substring(0, 10)) : (<p>---</p>)}</h2>
                                </Space>



                            </div>

                            <div className="changeable-details">
                                <div className="changenum">
                                    <input
                                        className="number"
                                        type="text"
                                        value={subscriberDetails.mobile}
                                        placeholder="Mobile Number"


                                    />
                                </div>

                                <div className="change-school-class">
                                    <input
                                        className="userschool"
                                        type="text"
                                        value={subscriberDetails.school}
                                        placeholder="School"


                                    />
                                    <div className="class-user">
                                        <input
                                            className="class"
                                            type="text"
                                            value={subscriberDetails.std}
                                            placeholder="Mobile Number"


                                        />

                                    </div>


                                </div>

                            </div>

                            {/* <div className="save-cancle">
                                <button className="savechanges">Save</button>
                                <button className="cancelchanges">Cancel</button>
                            </div> */}



                        </div>
                    </>
                )}

            </>
        )}


    </>
}

export default Userdetail;