import React, { useEffect, useState } from 'react';
import { Link, json } from 'react-router-dom';
import logo from '../../asset/bk_logo.png';
import { FaShoppingCart } from 'react-icons/fa';
import Hamburger from 'hamburger-react';
import './Navbarg.css';
import user1 from "../../asset/UserProfile.png"
import { getCartList } from '../../functions/cart';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { HashLink } from 'react-router-hash-link';
import firebase from "../../firebase";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function Navbarg({ onSearchKeyword, cartItemsLength }) {
    const [showMenu, setShowMenu] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cartNumber, setCartNumber] = useState(0);
    const {cart} = useSelector((state) => ({...state}))
    const { user } = useSelector((state) => ({ ...state }));
    let dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {     
        console.log('useEffect navbar')   
        readCartItemsFromLocalStorage();
        const cartUpdateCheck = localStorage.getItem('cartUpdated') || null;
        if(cartUpdateCheck === "true"){
            loadCartItems();
        }
    }, [cartItemsLength]);

    const handleMenuToggle = () => {
        setShowMenu(!showMenu);
    };

    

    const readCartItemsFromLocalStorage = () => {
        const ls_cartItems = localStorage.getItem('cartItems');
        console.log("ls_cart", ls_cartItems);
        if(ls_cartItems === null) loadCartItems();
        else setCartNumber(ls_cartItems);
    }

    const loadCartItems = async () => {
        if (user) {
            if (user.role === 'subscriber') {
                getCartList(user.token)
                    .then((res) => {
                        setLoading(false);
                        console.log("cart-list", res.data.length);
                        setCartItems(res.data.length);
                        localStorage.setItem('cartItems', JSON.stringify(res.data.length));
                        localStorage.setItem('cartUpdated', false);
                        readCartItemsFromLocalStorage();
                    })
                    .catch((err) => {
                        setLoading(false);
                        if(err?.response?.data?.err === "Subscription expired. Access denied."){
                            toast.error("Subscription expired, Please renew");
                        }
                    })
            }
        }
    }

    const logout = () => {
        console.log("logout");
        firebase
          .auth()
          .signOut()
          .then(() => {
            // Dispatch logout action after successful logout
            dispatch({
              type: "LOGOUT",
              payload: null,
            });
            // Navigate to another page
            navigate("/login");
          })
          .catch((error) => {
            console.error("Logout error:", error);
          });
    };

    return (

        <>

            <div className='shopnav'>
                <div className='logog'>
                    <img src={logo} alt='shoplogog'></img>
                </div>

                <div className='ji'>
                    
                    <ul className={`navbarul ${showMenu ? 'show' : ''}`}>
                        <li>
                            <Link className='navbarlig homelink' to='/'>
                                Home
                            </Link>
                        </li>

                        <li>
                            {user && user.role === 'subscriber' ? (
                                <>
                                </>
                            ) : (
                                <>
                                    <HashLink className='navbarlig' to='/#subscription' >
                                        Subscription
                                    </HashLink>
                                </>
                            )}

                        </li>
                        <li>
                            {user ? (
                                user.role === "admin" ? (
                                    <Link className='navbarlig' to='/admin/dashboard'>
                                        Admin Dash
                                    </Link>
                                ) : (
                                    <Link className='navbarlig' to='/details' >
                                        <img src={user1} alt='shoplogog'></img>
                                    </Link>
                                )

                            ) : (
                                <Link className='navbarlig' to='/login'>
                                    Login
                                </Link>
                            )}

                        </li>
                        <li>

                            {user && user.role === 'subscriber' ? (
                                <Link className='navbarlig' to='/cart' >
                                    <FaShoppingCart className='Cartg' />
                                    <div style={{
                                        display: 'inline-block',
                                        backgroundColor: 'red',
                                        color: 'white',
                                        borderRadius: '100%',
                                        position: 'absolute',
                                        top: '12px',
                                        // right: '259px',
                                        marginTop: '2px',
                                        padding: '0px 8px',
                                        fontSize: '22px'
                                    }}> {cartNumber}</div>
                                    
                                    {/* {cart.length */}


                                </Link>
                            ) : ( null )}
                            {/* <Link className='navbarlig' to='/' >
                                    <FaShoppingCart className='Cartg' />
                                </Link> */}

                        </li>


                        {/* <li>
                            {user && (
                                <Link className='navbarlig' onClick={logout}>
                                    Sign out
                                </Link>
                            )}
                        </li> */}
                        {/* <li>

                            {user && user.role === 'subscriber' ? (
                                <Link className='navbarlig' to='/cart' >
                                    <FaShoppingCart className='Cartg' />
                                    <div style={{
                                        display: 'inline-block',
                                        backgroundColor: 'red',
                                        color: 'white',
                                        borderRadius: '100%',
                                        position: 'absolute',
                                        top: '12px',
                                        // right: '259px',
                                        marginTop: '2px',
                                        padding: '0px 8px',
                                        fontSize: '22px'
                                    }}> {cart.length}</div>
                                    
                               


                                </Link>
                            ) : ( null )}
                        

                        </li> */}

                        <li>
                            {user && (
                                <Link className='navbarlig' onClick={logout}>
                                    Sign out
                                </Link>
                            )}
                        </li>
                 
                    </ul>
                </div>


                <div className='menug' onClick={handleMenuToggle} style={{ color: 'white' }}>
                    <Hamburger />
                </div>
            </div>
        </>
    );
}

export default Navbarg;
