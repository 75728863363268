import React from 'react';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../../asset/bookkulturelogo.png';
import './MianNav.css';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function MainNavBar({ hideOptions }) {

  const { user } = useSelector((state) => ({ ...state }));
  // console.log("MainNavBar.js")
  const navbarClassName = hideOptions ? 'navbar-hidden-options' : 'navbar-default';
  return (
    <Navbar collapseOnSelect expand="lg" className={`bg-body-tertiary ${navbarClassName}`}>
      <Container>
        <Navbar.Brand href="#home">
          <img className='bk_logo' src={logo} alt="Logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='link'>
            {<Link className='navLib' to="/">Home</Link>}
            {!hideOptions && (

              <HashLink className='navLib' to='#about' >
               What We offer ?
              </HashLink>
            
            )}
            <Link eventkey={2} className='navLib' to='/library'>Library</Link>
            {/* <Nav.Link eventKey={2} href="/library">
              Library
            </Nav.Link> */}
            {!user ? (
              <>
                {!hideOptions && <Link className='navLib' eventkey={2} to="/login">
                  Login
                </Link>}
              </>
            ) : null}

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavBar;





