
import React from "react";
import { useState } from "react";
import { auth } from "../../firebase"
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import './Register.css';
import AuthFooter from "../../components/nav/AuthFooter2/AuthFooter2";
import MainNavBar from "../../components/nav/MainNav/MainNavBar";

const Register = () => {


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(0);

    let navigate = useNavigate();

    const {user} = useSelector((state) => ({...state}));

    useEffect(() => {
      if(user && user.token) navigate('/');
    },[user,navigate])


    useEffect(() => {
        console.log(window.localStorage.getItem('emailForRegistration'));
        setEmail(window.localStorage.getItem('emailForRegistration'));
    },[])

    useEffect(() => {
      if(timeRemaining === 0) {
          setIsButtonDisabled(false);
        }
    }, [timeRemaining]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const config = {
            url: process.env.REACT_APP_REGISTER_REDIRECT_URL,
            handleCodeInApp: true,
        };

        setIsButtonDisabled(true); // Disable the button
        setTimeRemaining(30); // Set the timer to 30 seconds
        
        const timer = setInterval(() => {
          setTimeRemaining((prevTime) => prevTime - 1);
        }, 1000);

        auth.sendSignInLinkToEmail(email, config);
        toast.success(
          'Email is sent to ' + email + ' .Click link to complete.'
        );

        window.localStorage.setItem('emailForRegistration', email)

        setTimeout(() => {
          clearInterval(timer);
        }, 30000);
    };

    return(
    <>
   <MainNavBar hideOptions={true}></MainNavBar>
      <div className="container">
        <div className="main_box">
          <h1 className="heading">Sign Up</h1>
{/*          
          
          <input
            className="email"
            placeholder="Enter Your Email"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          /> */}
           {/* <input
            className="password"
            placeholder="Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          /> */}
          
          {/* <button 
            className="submit" 
            type="submit" 
            onClick={handleSubmit} 
            disabled={isButtonDisabled}
          >
            {isButtonDisabled ? `Please wait (${timeRemaining}s)` : "Create An Account"}
          </button> */}
          {/* <p className="para">--------------------Or--------------------</p> */}
          <button className="loginWithGoogleRegister">SIGN UP WITH GOOGLE</button>
          <p className="loginLink">Already Have An Account?<Link className="login" to="/login">Log In</Link></p>
        </div>
      </div>
      <AuthFooter></AuthFooter>
     
    </>
        
    );
}

export default Register;