import React from 'react'
import {useState, useEffect} from 'react'
import AdminNav from '../../../components/nav/AdminNav';
import  {toast} from 'react-toastify'
import { useSelector } from 'react-redux'
import {getCategory, updateCategory} from "../../../functions/category";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CategoryForm from '../../../components/forms/CategoryForm';
  
const CategoryUpdate = () => {
  
    const {user} = useSelector((state) => ({...state}));
  
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    //   const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        loadCategory();
    }, [])
  
    const loadCategory = () => {
        getCategory(params.slug).then((c) => setName(c.data.name));  
    }
  
      const handleSubmit = (e) => {
          e.preventDefault();
          setLoading(true);
          updateCategory(params.slug, {name}, user.token)
          .then((res) => {
              setLoading(false);
              setName("");
              toast.success(`"${res.data.name}"is updated`);
              navigate('/admin/category');
            //   loadCategories();
          })
          .catch((err) =>{
              console.log(err);
              setLoading(false);
              if(err.response.status === 400) toast.error(err.response.data);
          });
  
      };

      const categoryForm = () =>( 
        <form onSubmit={handleSubmit}>
          <label>Name</label>
          <input
              type='text'
              onChange={(e) => setName(e.target.value)}
              value={name}
              autoFocus
              required
          />
  
          <button>Save</button>
        </form>
      )

    return (
      <>
          <AdminNav/>
          {loading ? (
              <h1>Loading</h1>
          ) : (
              <h1>UpdateCategory Page</h1>
          )}
          
          {/* {categoryForm()} */}

          <CategoryForm
            handleSubmit={handleSubmit}
            name={name}
            setName={setName}
          />


  
          
      </>
    )
}
export default CategoryUpdate
