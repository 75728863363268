import React, { useEffect, useState } from 'react';
import "./Orders.css"
import book from "../../../asset/BestSellerImages/book2.png"
import DashBoardHeader from '../../../components/DashBoard_BK/DashBoard_header/DashBoardHeader';
import DashBoardSidebar from '../../../components/DashBoard_BK/DashBoard_SideMenu/DashBoardSidebar';
import { listAll } from '../../../functions/order';
import { useSelector } from 'react-redux';
import { updateStatus } from '../../../functions/order';
import { toast } from 'react-toastify'
import { returnBook } from '../../../functions/order';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { orderBy } from 'lodash';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState('All');
  const [issuedBooks, setIssuedBooks] = useState([]);
  const navigate = useNavigate()
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    showAllOrders();
  }, [])

  const showAllOrders = () => {
    listAll(1000, user.token)
      .then((res) => {
        console.log("listall", res.data)
        setOrders(res.data);
      })
      .catch((err) => {
        toast.error(err.response.data.err)
        console.log(err)
      })
  }



  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleStatusChange = (orderId, newStatus) => {
    if (newStatus === 'Completed') {
      updateStatus(user.token, orderId, newStatus)
        .then((res) => {;
          const issuedBookContainer = res.data.orderedBy.booksIssued
          setIssuedBooks([...issuedBooks, issuedBookContainer]);
          const updatedOrders = orders.filter((order) => order._id !== orderId);
          setOrders(updatedOrders);
          toast.success("Order Moved to Completed Section")
          showAllOrders();
        })
        .catch((err) => {
          toast.error(err.response.data.err)
          console.log(err)
        })
    } else if (newStatus === 'Dispatched') {
      updateStatus(user.token, orderId, newStatus)
        .then((res) => {
          toast.success("Order Moved to Dispatched Section")
          showAllOrders();
        })
        .catch((err) => {
          toast.error(err.response.data.err)
          console.log(err)
        })
    } else if (newStatus === "Processing") {
      updateStatus(user.token, orderId, newStatus)
        .then((res) => {
          toast.success("Order Moved to Processing Section")
          showAllOrders();
        })
        .catch((err) => {
          toast.error(err.response.data.err)
          console.log(err)
        })
    }

  };

  const handleReturn = (orderId, bookId) => {
    returnBook(user.token, orderId, bookId)
    .then((res) => {
      console.log("Book Returned", res.data)
      showAllOrders();
    })
    .catch((err) => {
      toast.error(err.response.data.err)
      console.log(err);
    })
  }

  const filteredOrders = filter === 'All' ? orders : orders.filter((order) => order.orderStatus === filter);
  console.log(filteredOrders);

  const handleChangeOrder = (order, id) => {
    console.log(order)
    const subscriberId = id
    navigate('/changeOrder', {state: {order, subscriberId}})
  }

  return (
    <>
      <DashBoardHeader />
      <div className="app-container">
        <DashBoardSidebar />

        <div>
          <div className='filter_buttons'>
            <button className='filters' onClick={() => handleFilterChange('All')}>All</button>
            <button className='filters' onClick={() => handleFilterChange('Processing')}>Processing</button>
            <button className='filters' onClick={() => handleFilterChange('Dispatched')}>Dispatched</button>
            <button className='filters' onClick={() => handleFilterChange('Completed')}>Completed</button>
          </div>

          <div >
            {filteredOrders.length === 0 && (
              <>
                <h3 style={{justifyContent: "center", alignItems: "center"}}>No Orders Found</h3>
              </>
            )}
            {filteredOrders.length > 0 && filteredOrders.map((order) => (
              <div key={order.id} className='orders'>
                <p className='ordernum'>Order Number: {order._id}</p>
                <p className="ordernum">Date of Order: {new Date(order.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</p>
                <p className='user_address'>Address: {order?.orderedBy?.address?.area}</p>

                <div className='userinfo'>

                  <p>Name : {order?.orderedBy?.name}</p>
                  <p>Email: {order?.orderedBy?.email}</p>
                  <p>Contact: {order?.orderedBy?.mobile}</p>
                </div>

                <h1 className='recentorderedbooks'>Issued Books</h1>
                <div className='product-container'>
                  {order?.orderedBy?.booksIssued[0] ? (
                      <div className='container-issuedBooks' style={{display: 'flex'}}>
                        {order.orderedBy.booksIssued.map((book1) => (
                          <div  className='mainproductdetails'>
                            <div key={book1._id} className='product_img_detail_IB'>
                              <img
                                src={book1.images[0] ? (book1.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book1.images[0].public_id + '.jpg' : book1.images[0].url)  : book}
                                alt={book1.title}
                                className='ordered-product-img'
                              />
                              <div className='order-pro-names'>
                                <p className='proname'>{book1.title}</p>
                                <p className='authorname'>{book1.author.name}</p>
                              </div>
                              <div className='return-button-container'>
                                <button onClick={() => handleReturn(book1._id, order._id)}>Returned</button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>No Issued Books</p>
                    )}
                </div>

                <p style={{marginLeft: "30px"}} className={`product-status ${order.orderStatus}`}>
                  <select
                    value={order.orderStatus}
                    onChange={(e) => handleStatusChange(order._id, e.target.value)}
                    className="status-select"
                    disabled={order.orderStatus === 'Completed'}
                  >
                    <option value="Not Processed">Not Processed</option>
                    <option value="Processing">Processing</option>
                    <option value="Dispatched">Dispatched</option>
                    <option value="Completed">Completed</option>
                  </select>

                  <EditOutlined className='editIcon' onClick={() => handleChangeOrder(order?.books, order.orderedBy._id)} />

                </p>

                <h1 className='recentorderedbooks'>Ordered Books</h1>
                <div className='recenrorder'>

                  {order.books.map((book1) => (
                    <div className='mainproductdetails'>
                      <div key={book1.id} className='product_img_detail'>
                        <img src={book1.images[0] ?  (book1.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book1.images[0].public_id + '.jpg' : book1.images[0].url) : book} alt={book1.title} className='ordered-product-img' />
                        <div className='order-pro-names'>
                          <p className='proname'>{book1.title}</p>
                          <p className='authorname'>{book1.author}</p>
                        </div>

                      </div><br></br>

                    </div>

                  ))}

                </div>
              </div>

            ))}
          </div><br></br>
        </div>
      </div>
    </>
  );
};

export default Orders;

