
import React from "react";
import { useState, useEffect } from "react";
import { auth } from "../../firebase";
// import { auth } from "../../firebase2";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

import './CompleteRegistration.css'

import { useSelector,useDispatch } from "react-redux";
import {createOrUpdateUser} from "../../functions/auth"



const CompleteRegistration = ({history}) => {


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const {user} = useSelector((state) => ({...state}));

    let navigate = useNavigate();
    let dispatch = useDispatch();

    useEffect(() => {
        if(user && user.token){
          navigate('/');
        }
      },[user,navigate]);

    useEffect(() => {
        console.log(window.localStorage.getItem('emailForRegistration'));
        setEmail(window.localStorage.getItem('emailForRegistration'));
    },[])

    const handleSubmit = async(e) => {
        e.preventDefault();

        if(!email || !password){
            return toast.error("Email and Password is Required!");
        }

        if(password.length < 6){
            return toast.error("Password should be greater than 6 characters");
        }

        try{
            const result = await auth.signInWithEmailLink(
                email,
                window.location.href
            );
            // console.log("Result", result);

            if(result.user.emailVerified){
                window.localStorage.removeItem("emailForRegistration");

                let user = auth.currentUser
                await user.updatePassword(password);
                const idTokenResult = await user.getIdTokenResult();

                // console.log("User", user, "idTokenResult", idTokenResult);

                createOrUpdateUser(idTokenResult.token)
        .then((res)=> {
        dispatch({
          type: "LOGGED_IN_USER",
          payload:{
              name: res.data.name,
              email: res.data.email,
              token: idTokenResult.token,
              role: res.data.role,
              _id: res.data._id
          },

      });

    }).catch((err) =>{
      // toast.error(err.response.data.err)
      console.log(err)
    });  
    navigate('/');
            }            
        }catch(error){
            console.log(error);
            toast.error("Error: Could Not Complete Registration Process, Please try another options!!!");
        }

    };

    return(
    <>
      <div className="container">
        <div className="main_box_cr">
          <p className="heading">Complete Registration</p>
            <input
              className="email_cr"
              placeholder="Email"
              type="email"
              id="email"
              value={email}
              // disabled
            />
            <input
              className="password_cr"
              placeholder="Password"
              type="password"
              id="password"
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
            />
          <button 
            className="submit_cr"
            type="submit" 
            onClick={handleSubmit}
            >
            CompleteRegistration
          </button>
        </div>
      </div>
    </>
        
    );
}

export default CompleteRegistration;