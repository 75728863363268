import React from 'react'

const CategoryForm = ({handleSubmit, name, setName}) => {
  return (
    
    <>
    <form onSubmit={handleSubmit}>

        <label>Name</label>
        <input
            type='text'
            onChange={(e) => setName(e.target.value)}
            value={name}
            autoFocus
            required
        />

        <button>Save</button>
    </form>
    </>
  )
}

export default CategoryForm
