import './AboutBk.css'
import img from '../../asset//Images/AboutImg.png'
import img1 from '../../asset//Images/img1.png'
import img2 from '../../asset//Images/img2.png'
import img3 from '../../asset//Images/img3.png'
import img4 from '../../asset//Images/img4.png'
import 'bootstrap/dist/css/bootstrap.min.css';


function AboutBk() {
    return <>
<div className='about' id='about'>
        <div className="AboutBk_Heading">
            <h1> What WE OFFER </h1>
            <p>Book Kulture is a children’s library service that offers</p>
        </div>
        <div className="Aboutbk">
            <div className='AboutImg col-sm-4'><img className=" image" src={img} alt="img" /></div>
            <div className='AboutMain col-sm-8'>
            {/* <img className="image" src={img} alt="img" /> */}
                <div className="Details1">
                    <div className='Detail'>
                        <div className='icons icons1'><img className="icon icon1" src={img1} alt="img" /></div>
                        <div className='mainpara'>
                            <h1>Curated Just for Your Child</h1>
                            <p>Carefully curated, ensuring suitability for your child's age
                            </p>
                        </div>
                    </div>
                    <div className='Detail'>
                        <div className='icons'><img className="icon" src={img2} alt="img" /></div>
                        <div className='mainpara'>
                            <h1>Explore a World of Stories</h1>
                            <p>Diverse genres for all ages - classics, adventures, and more!
                            </p>
                        </div>
                    </div>
                </div>

                <div className="Details2">
                    <div className='Detail'><div className='icons'><img className="icon" src={img3} alt="img" /></div>
                        <div className='mainpara'>
                            <h1>Affordable Subscription Plans
                               </h1>
                            <p> Access books without breaking the bank
                            </p>
                        </div></div>
                    <div className='Detail'><div className='icons'><img className="icon" src={img4} alt="img" /></div>
                        <div className='mainpara '>
                            <h1>Educational Value</h1>
                            <p> Nurture young minds through reading, promoting literacy and creativity.</p>
                        </div></div>
                </div>

            </div>
        </div>


        </div>
    </>
}

export default AboutBk;