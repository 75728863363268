import "./Address.css"
import { Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currentSubscriber } from "../../../functions/subscriber";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from 'react-spinners';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';

function Address() {

    const [subscriberDetails, setSubscriberDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const { user } = useSelector((state) => ({ ...state }));
    const navigate = useNavigate();

    useEffect(() => {
        getSubscriberDetails();
    }, [user])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const getSubscriberDetails = () => {
        if (user) {
            if (user.role === 'subscriber') {
                currentSubscriber(user.token)
                    .then((res) => {
                        setSubscriberDetails(res.data.address);
                    })
                    .catch((err) => {
                        toast.error(err.response.data.err)
                        console.log(err);
                    })
            }
        } 
    }

    return <>

        {loading ? (
            <>
                <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '400px' }}>
                    <FadeLoader color={'red'} loading={loading} size={150} />
                </div>
            </>
        ) : (
            <>
                {user && user.role === 'viewer' ? (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '300px' }}>
                            <Link to='/'>CILCK HERE TO SUBSCRIBE!!!</Link>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="detailLayout">
                            <Typography.Title level={2} className="ty">
                                Address
                            </Typography.Title>

                            <div className="user-chnagedetails">
                                <div className="changehouse">
                                    <input
                                        className="house"
                                        type="text"

                                        value={subscriberDetails.house}


                                    />
                                    {/* <p>{subscriberDetails.address.house}</p> */}
                                </div>
                                <div className="changearea">
                                    <input
                                        className="area"
                                        type="text"

                                        value={subscriberDetails.area}


                                    />
                                </div>


                            </div>


                            <div className="change-citypin">
                                <div className="changcity">
                                    <input
                                        className="city"
                                        type="text"

                                        value={subscriberDetails.city}


                                    />
                                </div>
                                <div className="changepin">
                                    <input
                                        className="area"
                                        type="text"

                                        value={subscriberDetails.pincode}


                                    />
                                </div>


                            </div>

                            <div className="changelandmark">
                                <input
                                    className="land"
                                    type="text"

                                    value={subscriberDetails.landmark}


                                />

                            </div>
{/* 
                            <div className="save-cancle">
                                <button className="savechanges">Save</button>
                                <button className="cancelchanges">Cancel</button>
                            </div> */}



                        </div>
                    </>
                )}

            </>
        )}


    </>
}

export default Address;